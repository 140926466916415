import { BsLayoutSidebarReverse, BsViewStacked } from 'react-icons/bs'
import {
    Button,
    Card,
    Checkbox,
    Layout,
    Page,
    Select,
    Spinner,
    hslToRgb
} from '@shopify/polaris'
import React, { useCallback, useEffect, useState } from 'react'
import { apiGet, apiPost } from '../../../Api'

import Alerts from './Alerts'
import EventPageChart from './EventPageChart'
import { IoAirplane } from 'react-icons/io5'
import KvpTable from '../../../Components/KvpTable'
import LineChart from './LineChart'
import VividSection from './VividSection'
import _ from 'lodash'
import axios from 'axios'
import moment from 'moment'
import { useCookies } from 'react-cookie'
import { useFirebaseContext } from '../../../FirebaseContext'
import { useLocation } from 'react-router-dom'
/** @format */
import { useParams } from 'react-router-dom'

const SectionGrouper = ({
    eventID,
    selectedStrat,
    setSelectedStrat,
    setSectionGroupMapping,
    sectionGroupingStrats,
    strategies,
    selected
}) => {
    // console.log('SectionGrouper component', selectedStrat)
    const handleStratChange = useCallback(
        (value) => {
            const updateStrat = async () => {
                await apiPost('/sectionGroups/setStrategy', {
                    eventID,
                    strategy: value
                })
            }

            setSelectedStrat(value)
            updateStrat()
        },
        [eventID, setSelectedStrat]
    )

    useEffect(() => {
        const getMapping = async () => {
            let mapping = await apiGet('/sectionGroups/getSectionGroups', {
                eventID,
                strategy: selectedStrat
            })

            setSectionGroupMapping(mapping)
        }

        getMapping()
    }, [eventID, selectedStrat, setSectionGroupMapping])

    const sectionGroupsView = (
        <Select
            options={sectionGroupingStrats}
            onChange={handleStratChange}
            value={selectedStrat}
        ></Select>
    )

    return <div>{sectionGroupsView}</div>
}

const SectionGrouperCard = ({
    eventID,
    selectedStrat,
    sectionGroupingStrats,
    sectionGroupMapping,
    isGroupListingEnabled,

    setSelectedStrat,
    setSectionGroupMapping,
    setIsGroupListingEnabled
}) => {
    const sectionGroups = _.chain(sectionGroupMapping)
        .entries()
        .groupBy((x) => x[1])
        .mapValues((v) => v.map((x) => x[0]))
        .value()

    const sectionGrouper = (
        <Card title="Section Grouping" sectioned>
            <SectionGrouper
                eventID={eventID}
                selectedStrat={selectedStrat}
                setSelectedStrat={setSelectedStrat}
                setSectionGroupMapping={setSectionGroupMapping}
                sectionGroupingStrats={sectionGroupingStrats}
            />

            <div style={{ margin: '16px 0px' }}>
                <Button
                    onClick={() => {
                        setIsGroupListingEnabled(!isGroupListingEnabled)
                    }}
                >
                    Toggle Group Listing
                </Button>
            </div>

            {isGroupListingEnabled && (
                <div
                    style={{
                        maxHeight: 300,
                        overflow: 'auto',
                        fontSize: 12,
                        lineHeight: 1.2
                    }}
                >
                    {Object.keys(sectionGroups).map((g) => (
                        <p
                            style={{
                                border: '1px solid hsl(0, 0%, 80%',
                                borderRadius: 3,
                                margin: '8px 0px',
                                padding: 6,
                                background: 'hsl(0, 0%, 95%'
                            }}
                        >
                            <b style={{ marginRight: 6 }}>{g} </b>
                            {sectionGroups[g].map((v) => (
                                <span
                                    key={v}
                                    style={{ opacity: 0.6, marginRight: 6 }}
                                >
                                    {v}{' '}
                                </span>
                            ))}
                        </p>
                    ))}
                </div>
            )}
        </Card>
    )

    return sectionGrouper
}

export { SectionGrouperCard }
