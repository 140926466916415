import { Card, Checkbox } from '@shopify/polaris'
import { useEffect, useState } from 'react'

import ColumnChart from './ColumnChart'
import LineChart from './LineChart'
import _ from 'lodash'
import { apiGet } from '../../../Api'
import yaml from 'js-yaml'

/*
    https://maki.weturntix.com/ticketMaster/event/Z7r9jZ1AdjIfS
    vividId 4198197
    single fetch date

    https://maki.weturntix.com/ticketMaster/event/vvG1FZ9nhfwAmu
    single fetch date

    http://localhost:3000/ticketMaster/event/Z7r9jZ1A7Zv8Y
    https://www.vividseats.com//production/4330418
    2 fetch dates
*/

// Pass in a vividId if possible, otherwise a tmId and the vividId will be looked up
const VividSection = ({ tmId, eventData }) => {
    const [vividId, setVividId] = useState()
    const [raw, setRaw] = useState()
    const [showDump, setShowDump] = useState(false)
    const [kvp, setKvp] = useState([])
    const [ticketCountSeries, setTicketCountSeries] = useState([])
    const [minMaxPriceSeries, setMinMaxPriceSeries] = useState([])
    const [priceDistribution, setPriceDistribution] = useState([])
    const [sections, setSections] = useState([])
    const [inventoryByDate, setInventoryByDate] = useState([])
    const [isColorized, setIsColorized] = useState(false)

    useEffect(() => {
        const loadVividId = async () => {
            // console.log('loading vivid id for tmId', tmId)

            // TODO: gracefully handle the no-link case
            let ans = await apiGet('/event/tmIdToVividId', {
                tmId: tmId
            })

            if (ans.length === 0) {
                return
            }

            let { vivid_ids_csv } = ans[0]
            let vividIds = vivid_ids_csv.split(',')
            // Just take the first one for now
            let _vividId = vividIds[0]
            setVividId(_vividId)
        }

        if (!vividId) {
            loadVividId()
        }
    }, [vividId])

    useEffect(() => {
        const loadVividInfo = async () => {
            let vividData = await apiGet('/event/getVividEventInfo', {
                vividId: vividId
            })

            const { event, inventory_header, inventory_listing } = vividData

            let inventoryByDate = _.chain(inventory_listing)
                .groupBy((il) => il.fetched_at)
                .map((group, fetched_at) => [group, fetched_at])
                .sortBy((vk) => vk[1])
                .map(([v, k]) => v)
                .value()

            setInventoryByDate(inventoryByDate)

            const latestInventoryByDate =
                inventoryByDate[inventoryByDate.length - 1]

            let sections = _.chain(latestInventoryByDate)
                .map((il) => {
                    return il.verbose_section_name
                })
                .uniq()
                .sort()
                .map((n) => ({
                    name: n,
                    checked: true
                }))
                .value()

            setSections(sections)

            delete vividData.event[0].last_seen_json
        }

        if (vividId) {
            loadVividInfo()
        }
    }, [vividId])

    useEffect(() => {
        const latestInventoryByDate =
            inventoryByDate[inventoryByDate.length - 1]

        const _kvp = []

        const getTicketCount = (group, specOnly) => {
            return _.chain(group)
                .filter((il) => il.is_spec || !specOnly)
                .sumBy((il) => il.quantity)
                .value()
        }

        // "I think we want the sum of all spec tickets"
        let specCount = getTicketCount(latestInventoryByDate, true)
        _kvp.push(['Spec Count', specCount])

        // "We also want ticket_count over time. if we could have spec count and ticket_count on the same graph that would be nice"
        // There's a maximum of 5 fetched_at dates for vivid events
        // so it kind of doesn't make sense to make a chart just for that
        let ticketCountOverTime = _.map(inventoryByDate, (group) => {
            let allCount = getTicketCount(group, false)
            let specCount = getTicketCount(group, true)
            let when = group[0].fetched_at
            return { when, allCount, specCount }
        })

        ticketCountOverTime = _.sortBy(ticketCountOverTime, (t) => t.when)

        let ticketCountSeries = [
            {
                name: 'All Tickets',
                data: _.map(ticketCountOverTime, (t) => {
                    return [new Date(t.when).getTime(), t.allCount]
                })
            },
            {
                name: 'Spec Tickets',
                data: _.map(ticketCountOverTime, (t) => {
                    return [new Date(t.when).getTime(), t.specCount]
                })
            }
        ]

        setTicketCountSeries(ticketCountSeries)

        // we also want to track min_price and max_price over time.
        let minMaxPricesOverTime = _.map(inventoryByDate, (group) => {
            let minPrice = _.minBy(group, (il) => il.price_per).price_per
            let maxPrice = _.maxBy(group, (il) => il.price_per).price_per
            let when = group[0].fetched_at
            return { when, minPrice, maxPrice }
        })

        minMaxPricesOverTime = _.sortBy(minMaxPricesOverTime, (t) => t.when)

        let minMaxPriceSeries = [
            {
                name: 'Min Price',
                data: _.map(minMaxPricesOverTime, (t) => {
                    return [new Date(t.when).getTime(), t.minPrice]
                })
            },
            // {
            //     name: 'Max Price',
            //     data: _.map(minMaxPricesOverTime, (t) => {
            //         return [new Date(t.when).getTime(), t.maxPrice]
            //     })
            // }
        ]

        setMinMaxPriceSeries(minMaxPriceSeries)

        setKvp(_kvp)

        // "if we could also get a histogram of prices based on inventory_listing. maybe we can introduce a toggle feature where we can click the verbose_section_name (default is all sections)"
        // The verbose_section_name becomes a filter for the histogram data

        let prices = _.chain(latestInventoryByDate)
            .filter((il) => {
                return _.find(sections, (section) => {
                    return (
                        section.name === il.verbose_section_name &&
                        section.checked
                    )
                })
            })
            .map((il) => {
                return Array(il.quantity).fill(il.price_per)
            })
            .value()
            .flat(1)

        //setPriceDistribution(priceDistribution)
        let priceDistribution = _.chain(prices)
            .countBy()
            .entries()
            .map(([k, v]) => ({
                x: parseFloat(k),
                y: v
            }))
            .value()

        let colorized = _.chain(latestInventoryByDate)
            .filter((il) => {
                return _.find(sections, (section) => {
                    return (
                        section.name === il.verbose_section_name &&
                        section.checked
                    )
                })
            })
            .map((il) => {
                return Array(il.quantity)
                    .fill(il.price_per)
                    .map((c) => ({ k: il.verbose_section_name, v: c }))
            })
            .value()
            .flat(1)

        colorized = _.groupBy(colorized, (x) => x.k)

        colorized = _.mapValues(colorized, (x) =>
            _.chain(x)
                .groupBy((y) => y.v)
                .value()
        )

        colorized = _.entries(colorized).map(([k, v]) => ({
            name: k,
            data: _.entries(v).map(([k, v]) => ({
                x: parseFloat(k),
                y: v.length
            }))
        }))

        if (!isColorized) {
            setPriceDistribution([
                {
                    name: 'Ticket count',
                    data: priceDistribution,
                    color: 'hsl(220, 50%, 50%)'
                }
            ])
        } else {
            // Demo
            // setPriceDistribution([
            //    {
            //        name: 'Ticket count',
            //        data: [
            //            { x: 15, y: 2 },
            //            { x: 18, y: 3 },
            //            { x: 28, y: 5 }
            //        ]
            //    },
            //    {
            //        name: 'Ticket count 2',
            //        data: [
            //            { x: 15, y: 1 },
            //            { x: 21, y: 4 },
            //            { x: 28, y: 1 }
            //        ]
            //    },
            // ])

            setPriceDistribution(colorized)
        }
    }, [sections, isColorized, inventoryByDate])

    return (
        <div>
            <div>
                {kvp.map(([k, v]) => {
                    return (
                        <div key={k}>
                            <b>{k}</b>: {v}
                        </div>
                    )
                })}
            </div>

            <p>&nbsp;</p>

            <LineChart
                eventData={eventData}
                title="Ticket Count Over Time"
                chartData={ticketCountSeries}
            />

            <p>&nbsp;</p>

            <LineChart
                eventData={eventData}
                title="Min Prices Over Time"
                chartData={minMaxPriceSeries}
            />

            <p>&nbsp;</p>

            <div>
                <Checkbox
                    checked={isColorized}
                    onChange={() => {
                        setIsColorized(!isColorized)
                    }}
                    label="Colorize Sections"
                />
            </div>

            <ColumnChart
                eventData={eventData}
                title="Price Distribution"
                chartData={priceDistribution}
                yAxisLabel="Ticket Count"
                xAxisLabel="Price"
            />

            <p>&nbsp;</p>
            <div>
                {sections.map((section) => {
                    return (
                        <span
                            style={{
                                opacity: 0.84,

                                position: 'relative',
                                marginBottom: 8,

                                display: 'inline-block',
                                fontSize: 13,
                                margin: 0,
                                marginRight: 20
                            }}
                            key={section.name}
                            className="wtt-chart-label"
                        >
                            <Checkbox
                                checked={section.checked}
                                onChange={() => {
                                    section.checked = !section.checked
                                    setSections([...sections])
                                }}
                                label={section.name}
                            />
                        </span>
                    )
                })}
            </div>
            <div style={{ marginTop: 12 }}>
                <button
                    onClick={() => {
                        sections.forEach((section) => {
                            section.checked = true
                        })
                        setSections([...sections])
                    }}
                >
                    select all
                </button>
                <button
                    onClick={() => {
                        sections.forEach((section) => {
                            section.checked = false
                        })
                        setSections([...sections])
                    }}
                >
                    unselect all
                </button>
            </div>
            <p>&nbsp;</p>
            <p>&nbsp;</p>

            {false && (
                <div>
                    <button
                        onClick={() => {
                            setShowDump(!showDump)
                        }}
                    >
                        {showDump ? 'Hide' : 'Show'} Data Dump
                    </button>
                </div>
            )}

            {showDump && (
                <div
                    style={{
                        marginTop: 16,
                        opacity: 0.8,
                        maxHeight: 700,
                        overflow: 'scroll'
                    }}
                    className="mono"
                >
                    {raw}
                </div>
            )}
            <p>&nbsp;</p>
        </div>
    )
}

export default VividSection
