/* This is the alerts grid */
import { AgGridReact } from 'ag-grid-react'
import _ from 'lodash'
import { useFirebaseContext } from '../../../FirebaseContext'
import { Card, Page, hslToRgb, Button } from '@shopify/polaris'
import { useEffect, useState, useRef, useMemo, useCallback } from 'react'
import { apiGet, apiPost } from '../../../Api'
import { XAxis, YAxis, BarChart, Bar, Tooltip } from 'recharts'
import { PropagateLoader } from 'react-spinners'
import moment from 'moment'
import { Icon } from '@shopify/polaris'
import ms from 'ms'
import { ExternalMinor } from '@shopify/polaris-icons'
import { FaRegTrashAlt } from "react-icons/fa";

const AlertsPage = () => {
    const [debugValue, setDebugValue] = useState('x')
    const [rowData, setRowData] = useState()
    const { user } = useFirebaseContext()

    useEffect(() => {
        let priorResponse
        const keepAlertsUpdated = async () => {
            const firstRun = priorResponse === undefined
            let response = await apiGet('/alerts/getAllForUser', {
                user: user.email,
                //includeLive: !firstRun
                includeLive: false
            })

            console.log(response)
            let rows = response.map((r) => {
                r.expression = JSON.parse(r.content).expression
                r.event_id_link = r.event_id
                if (r.vivid_ids_csv) {
                    r.vivid_id = r.vivid_ids_csv.split(',')[0]
                }
                return r
            })

            if (JSON.stringify(response) !== priorResponse) {
                setRowData(response)
            }

            priorResponse = JSON.stringify(response)

            setTimeout(keepAlertsUpdated, firstRun ? 0 : ms('10 sec'))

        }

        keepAlertsUpdated()
    }, [])

    const defaultColDef = useMemo(() => {
        return {
            editable: false,
            sortable: true,
            filter: true,
            resizable: true
        }
    }, [])

    let colorIndex = 0
    let colors = [
        'hsl(0, 0%, 20%)',
        'hsl(0, 80%, 50%)',
        'hsl(220, 50%, 50%)',
        'hsl(130, 50%, 50%)',
        'hsl(30, 50%, 50%)',
        'hsl(280, 50%, 50%)',
        'hsl(330, 50%, 50%)',
        'hsl(170, 50%, 50%)'
    ]

    let usedColors = {}

    const [columnDefs, setColumnDefs] = useState([
        {
            field: 'xid',
            headerName: 'Actions',
            width: 100,

            cellRenderer: (v) => {
                return (
                    <span
                        style={{ color : v.data.aboutToDelete ? 'red' : '', padding: '4px 8px' }}
                        onClick={(x) => {
                            if (v.data.aboutToDelete) {
                                v.api.applyTransaction({
                                    remove: [{ id: v.data.id }]
                                })

                                apiPost('/alerts/deleteAlert', { id: v.data.id })
                            } else {
                                v.data.aboutToDelete = true
                                v.node.setData(v.data)
                            }
                        }}
                    >
                        <FaRegTrashAlt />
                    </span>
                )
            }
        },
        { field: 'id', headerName: 'ID', width: 60 },
        {
            field: 'event_name',
            headerName: 'Event Name',
            width: 350
        },
        {
            field: 'event_id_link',
            headerName: '',
            width: 42,

            cellRenderer: (v) => {
                return (
                    <a
                        href={`/ticketMaster/event/${v.value}`}
                        rel="noreferrer"
                        target="_blank"
                        style={{ position: 'relative', top: 5 }}
                    >
                        <Icon source={ExternalMinor} />
                    </a>
                )
            }
        },
        {
            field: 'tm_url',
            headerName: '',
            width: 42,

            cellRenderer: (v) => {
                return (
                    <a href={v.value} rel="noreferrer" target="_blank">
                        <img
                            src={'/images/tmLogo.svg'}
                            alt="Ticketmaster Logo"
                            style={{ height: '15px' }}
                        />
                    </a>
                )
            }
        },
        {
            field: 'vivid_id',
            headerName: '',
            width: 42,

            cellRenderer: (v) => {
                if (!v.value) {
                    return v.value
                }
                return (
                    <a
                        href={
                            'https://www.vividseats.com//production/' + v.value
                        }
                        rel="noreferrer"
                        target="_blank"
                    >
                        <img
                            src={
                                'https://companieslogo.com/img/orig/SEAT-7977b3fe.png?t=1643536854'
                            }
                            alt=""
                            style={{ height: '15px' }}
                        />
                    </a>
                )
            }
        },
        { field: 'venue_name', width: 250, headerName: 'Venue Name' },
        {
            field: 'expression',
            headerName: 'Expression',
            editable: true,
            width: 450,
            cellStyle: {
                fontFamily:
                    'source-code-pro, Menlo, Monaco, Consolas, Courier New, monospace',
                fontWeight: 300,
                fontSize: 12
            }
        },
        {
            field: 'status',
            headerName: 'Status',
            width: 140,
            onCellClicked: (e) => {
                console.log(e.value)
                if (e.data.status !== 'pending') {
                    e.data.status = 'pending'
                } else {
                    e.data.status = 'disabled'
                }

                e.node.updateData(e.data)
                handleCellValueChanged(e)
            },

            cellStyle: (params) => {
                if (params.value === 'triggered') {
                    return { background: 'hsl(0, 90%, 92%)' }
                }
                if (params.value === 'pending') {
                    return {}
                }
                if (params.value === 'disabled') {
                    return { color: 'hsl(0, 0%, 70%)' }
                }
                return null
            }
        },
        //{ field: 'live_count', width: 140, headerName: 'Live Inventory' },
        {
            field: 'alerts_group_id',
            headerName: 'Alerts Group',
            width: 150,
            cellStyle: (params) => {
                if (params.value === null) {
                    return {}
                }

                let color
                if (Object.keys(usedColors).includes(params.value)) {
                    color = usedColors[params.value]
                } else {
                    color = colors[colorIndex]
                    usedColors[params.value] = color
                    colorIndex = (colorIndex + 1) % colors.length
                    console.log(colorIndex)
                }

                return { color: color }
            }
        },
        {
            field: 'create_timestamp',
            width: 210,
            headerName: 'Create Timestamp',
            valueFormatter: (p) =>
                p.value && moment(p.value).format('YYYY-MM-DD HH:mm:ss')
        },
        {
            field: 'trigger_timestamp',
            width: 210,
            headerName: 'Trigger Timestamp',
            valueFormatter: (p) =>
                p.value && moment(p.value).format('YYYY-MM-DD HH:mm:ss')
        }
    ])

    const headerHeight = 150
    const [maxHeight, setMaxHeight] = useState(
        window.innerHeight - headerHeight
    )

    useEffect(() => {
        let intervalHandle = setInterval(() => {
            setMaxHeight(window.innerHeight - headerHeight)
        }, 1000)

        return () => {
            clearInterval(intervalHandle)
        }
    }, [])

    const _handleCellValueChanged = useCallback((e) => {
        const doUpdate = async () => {
            const alertId = e.data.id
            const expression = e.data.expression
            const status = e.data.status
            console.log('updating alert', alertId, 'to', expression)
            let response = await apiPost('/alerts/updateAlertExpression', {
                alertId,
                expression,
                status
            })
            console.log('update alert result', response)
        }

        doUpdate()
    }, [])

    // debounce is not critical because onCellChange doesn't trigger while the cell is being
    // changed. It only triggers when the user clicks out of the cell.
    const handleCellValueChanged = useCallback(
        _.debounce(_handleCellValueChanged, 100),
        []
    )

    return (
        <Page title="Alerts" fullWidth>
            <div
                style={{ height: maxHeight, width: '100%' }}
                className="ag-theme-alpine"
            >
                <AgGridReact
                    rowData={rowData}
                    columnDefs={columnDefs}
                    defaultColDef={defaultColDef}
                    rowSelection="none"
                    singleClickEdit={true}
                    onSelectionChanged={(e) => {
                        // console.log('selection changed', e)
                    }}
                    onCellValueChanged={(e) => {
                        handleCellValueChanged(e)
                    }}
                    getRowId={(p) => p.data.id}
                ></AgGridReact>
            </div>
        </Page>
    )
}

export default AlertsPage
