/** @format */

import { Page, Card, Button, Label } from "@shopify/polaris";
import { useState } from "react";
import { useFirebaseContext } from "../../../FirebaseContext";
import { CSVDownload } from "react-csv";

const ExportEventInventoryChange = () => {
	const { expressFunction } = useFirebaseContext();
	const [running, setRunning] = useState(false);
	const [data, setData] = useState([]);
	const exportEventInventoryChangeFunction = expressFunction(
		"exportEventInventoryChange"
	);

	const runExportEventInventoryChangeAgain = ({ offset }) => {
		return exportEventInventoryChangeFunction({ offset: 0 }).then(
			(database) => {
				return database;
			}
		);
	};

	const runExportEventInventoryChange = async () => {
		setRunning(true);
		let dataIn = [];
		let latestRSin = 0;
		let offset = 0;
		let keepRunning = true;
		await exportEventInventoryChangeFunction({ offset }).then((data) => {
			// console.log(data.data);
			dataIn = [...dataIn, ...data.data];
			// console.log("dataIn", data);
			latestRSin = data.data.length;
			offset = dataIn.length;
		});
		// console.log("dataIn'", offset, dataIn.length, latestRSin);
		// do {
		// 	await exportEventInventoryChangeFunction({ offset }).then((data) => {
		// 		dataIn = [...dataIn, ...data.data];
		// 		if (data.data.length === 0) {
		// 			keepRunning = false;
		// 		}
		// 		latestRSin = data.data.length;
		// 		offset = dataIn.length;
		// 		console.log("dataIn'", offset, dataIn.length, latestRSin);
		// 	});
		// } while (keepRunning); // less then 10000
		setData(dataIn);
	};

	return (
		<Card sectioned title="Export Event Inventory Change">
			<Card.Section>
				{data.length === 0 && (
					<Button
						onClick={runExportEventInventoryChange}
						disabled={running}
						loading={running}
					>
						Run
					</Button>
				)}
				{data.length !== 0 && (
					<>
						<Label status="info">Done</Label>
						<CSVDownload headers={Object.keys(data[0])} data={data} />
					</>
				)}
			</Card.Section>
		</Card>
	);
};

const BulkExportsPage = () => {
	return (
		<Page
			title="Bulk Exports"
			subtitle="This is for bulk exports of data."
			fullWidth
		>
			<ExportEventInventoryChange />
		</Page>
	);
};

export default BulkExportsPage;
