import { useState, useEffect, useMemo, useCallback } from 'react'
import { ReflexContainer, ReflexSplitter, ReflexElement } from 'react-reflex'
import { HiDesktopComputer } from "react-icons/hi";
import './react-reflex-override.css'
import './ActiveTrader.css'
import EventsGrid from './EventsGrid'
import yaml from 'js-yaml'

//const EventsGrid = ({}) => { return <div> event grid </div> }

const SingleEvent = ({ selectedEvent }) => {
    const yam =
        selectedEvent === undefined
            ? 'Please select an event above'
            : yaml.dump(selectedEvent)

    const b = (
        <div
            style={{
                background: 'hsl(220, 40%, 30%)',
                margin: '8px 0px',
                padding: '4px 12px',
                borderRadius: 3
            }}
        >
            button
        </div>
    )

    return (
        <ReflexContainer orientation="vertical">
            <ReflexElement className="panel" flex={0.1}>
                <ReflexContainer orientation="horizontal">
                    <ReflexElement className="panel" flex={0.5}>
                        {b}
                        {b}
                        {b}
                        {b}
                    </ReflexElement>
                    <ReflexSplitter />
                    <ReflexElement className="panel" flex={0.5}>
                        <div>some info</div>
                        <div>some info</div>
                        <div>some info</div>
                        <div>some info</div>
                        <div>some info</div>
                        <div>some info</div>
                    </ReflexElement>
                </ReflexContainer>
            </ReflexElement>

            <ReflexSplitter />

            <ReflexElement className="panel">
                <div
                    className="mono"
                    style={{ padding: 18, lineHeight: '150%' }}
                >
                    {yam}
                </div>
            </ReflexElement>

            <ReflexSplitter />

            <ReflexElement className="panel" flex={0.4}>
                <div>some info</div>
            </ReflexElement>
        </ReflexContainer>
    )
}

const ActiveTrader = ({}) => {
    const [selectedEvent, setSelectedEvent] = useState(undefined)

    const goBackToNormalApp = (
        <div
            className="subtle-btn"
            style={{
                fontSize: 20,
                position: 'fixed',
                right: 3,
                top: 3
            }}
            onClick={() => { 
                window.location.href = '/'
            }}
        >
            <HiDesktopComputer />
        </div>
    )


    return (
        <ReflexContainer
            orientation="horizontal"
            className="active-trader-container"
        >
            <ReflexElement className="left-pane" flex={0.3}>
                { goBackToNormalApp }
                <EventsGrid setSelectedEvent={setSelectedEvent} />
            </ReflexElement>

            <ReflexSplitter />

            <ReflexElement className="right-pane">
                <SingleEvent selectedEvent={selectedEvent} />
            </ReflexElement>
        </ReflexContainer>
    )
}

export default ActiveTrader
