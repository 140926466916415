import styles from "./style.module.css";

let sourceNameMap = {
    'vi': 'Vivid',
    'tm': 'Ticketmaster'
}

const Venue = ({venue, active, onClick}) => {

    let classes = [styles.venue]
    if (active) classes.push(styles.active)
    if (venue.source === 'vi') classes.push(styles.vi)
    if (venue.source === 'tm') classes.push(styles.tm)

    return <div className={classes.join(' ')} onClick={onClick}>
        <div className={styles.name}>
            {venue.name} 
        </div>
        <div>
            {venue.address}
        </div>
        <div>
            {venue.city} {venue.state} {venue.postal_code}
         <div>
            {sourceNameMap[venue.source]} {venue.id}
        </div>
       </div>
    </div>

}

export default Venue;