import { apiGet, apiPost } from '../../../Api'
import { useEffect, useState, useRef, useCallback, Select } from 'react'
import { TextField } from '@shopify/polaris'
import { FormLayout } from '@shopify/polaris'
import styles from './style.module.css'
import Venue from './Venue'
import VenueGroup from './VenueGroup'
import _ from 'lodash'

const MultiSelect = ({ value, options, onChange, selectStyle }) => {
    const handleChange = (e) => {
        var options = e.target.options
        var value = []
        for (var i = 0, l = options.length; i < l; i++) {
            if (options[i].selected) {
                value.push(options[i].value)
            }
        }
        onChange(value)
    }

    return (
        <select
            multiple="multiple"
            value={value}
            onChange={handleChange}
            style={selectStyle}
        >
            {options.map((option) => (
                <option
                    value={option.value ?? option}
                    key={option.value ?? option}
                >
                    {option.label ?? option}
                </option>
            ))}
        </select>
    )
}

const VividLinking = () => {
    const headerHeight = 70 
    const [notificationText, setNotificationText] = useState('')
    const [displayHelp, setDisplayHelp] = useState(false)
    const [freeSearchText, setFreeSearchText] = useState('')

    const notificationTimeout = useRef(null)


    const [zipToCityState, setZipToCityState] = useState([{}])

    const [postalCodes, setPostalCodes] = useState([])
    const [selectedPostalCode, setSelectedPostalCode] = useState(null)

    const [activeIds, setActiveIds] = useState([])
    const [activeGroupId, setActiveGroupId] = useState([])

    const [allVenues, setAllVenues] = useState([])
    const [allProcessedVenues, setAllProcessedVenues] = useState([])

    const [venues, setVenues] = useState([])
    const [processedVenues, setProcessedVenues] = useState([])

    const [maxHeight, setMaxHeight] = useState(
        window.innerHeight - headerHeight
    )

    //const bottomOfProcessedVenues = useRef(null)
    const notifyMessage = (message) => {
        setNotificationText(message)

        if (notificationTimeout.value !== null) {
            clearTimeout(notificationTimeout.value)
        }

        notificationTimeout.value = setTimeout(() => {
            setNotificationText('')
        }, 3000)

    }

    const venueToCityState = (venue) =>
        (venue.city + ' ' + venue.state)
            .replace('  ', ' ')
            .trimStart()
            .trimEnd()

    useEffect(() => {
        async function fetchData() {
            try {
                notifyMessage('Loading')
                const excludedZipCodes = ['00000', '00901']
                let receivedVenues = await apiGet('/linker/venues', {})
                receivedVenues = receivedVenues.filter(
                    (v) => !excludedZipCodes.includes(v.postal_code)
                )
                let _postalCodes = _.chain(receivedVenues)
                    .map((v) => v.postal_code)
                    //.map((c) => c.substring(0, postalCodeChars) + '...')
                    .uniq()
                    .sort()
                    .value()
                setPostalCodes(_postalCodes)

                // We can't do anything reliable directly with city/state
                // because the data is too dirty. Ok to display city/state though
                // to make zip codes easier to look at
                let _zipToCityState = _.chain(receivedVenues)
                    .groupBy((v) => v.postal_code)
                    .mapValues((vz) => {
                        let counts = _.countBy(vz, venueToCityState)
                        return _.chain(counts)
                            .toPairs()
                            .sortBy(
                                ([state, count]) =>
                                    (1e8 - count).toString() + state
                            )
                            .value()[0][0]
                    })
                    .value()

                setZipToCityState(_zipToCityState)

                let groups = await apiGet('/linker/groups')
                console.log('groups', groups)

                let _allVenues = [...receivedVenues]
                // vivid id's would come in as ints but we want all ids to stay as strings throughout
                _allVenues = _allVenues.map(v => ({ ...v, id: (v.id || '').toString() }))

                let _allProcessedVenues = []
                for (let group of groups) {
                    console.log(group)
                    let tm_ids = (group.tm_ids_csv || '').split(',')
                    let vi_ids = (group.vivid_ids_csv || '').split(',')
                    let ids = _.concat(tm_ids, vi_ids)
                    ids = ids.filter(id => id !== '')
                    console.log(ids)
                    
                    let venueGroup = _allVenues.filter((v) => ids.includes(v.id))
                    console.log(venueGroup.length)
                    _allVenues = _allVenues.filter((v) => !ids.includes(v.id))
                    _allProcessedVenues = [...(_allProcessedVenues || []), venueGroup]
                }

                setAllVenues(_allVenues)
                setAllProcessedVenues(_allProcessedVenues)
                notifyMessage('Ready')

            } catch (error) {
                console.error(error)
                notifyMessage('Load failed')
            }
        }
        fetchData()
    }, [])

    const maxRows = 1000

    const venueMatchesSearchText = useCallback(
        (v) => {
            if (!freeSearchText) return true
            let content =
                v.combo_cpscan || v.map((v) => v.combo_cpscan).join(',')
            return content.toLowerCase().includes(freeSearchText.toLowerCase())
        },
        [freeSearchText]
    )

    const doFilter = useCallback(
        (venues) => _.chain(venues).filter(venueMatchesSearchText).value(),
        [venueMatchesSearchText]
    )

    useEffect(() => {
        setVenues(doFilter(allVenues))
        setProcessedVenues(doFilter(allProcessedVenues))
    }, [allVenues, allProcessedVenues, freeSearchText, doFilter])

    // quick hack to set the panel height to full screen
    // don't want to override polaris css too much, so dynamically set it
    useEffect(() => {
        let intervalHandle = setInterval(() => {
            setMaxHeight(window.innerHeight - headerHeight)
        }, 2000)

        return () => {
            clearInterval(intervalHandle)
        }
    }, [])

    const handleSave = async () => {
        notifyMessage('Saving')
        let _groupsToSave = _.chain(allProcessedVenues)
            .map((vz) => {
                let get_ids = (source) =>
                    _.chain(vz)
                        .filter((v) => v.source === source)
                        .map((v) => v.id)
                        .sortBy()
                        .value()

                let tm_ids = get_ids('tm')
                let vi_ids = get_ids('vi')

                let prefixed_tm_ids = tm_ids.map((id) => 'tm_' + id)
                let prefixed_vi_ids = vi_ids.map((id) => 'vivid_' + id)

                // take the first vi_id if it exists, else first tm_id
                let id = _.chain(prefixed_vi_ids)
                    .concat(prefixed_tm_ids)
                    .value()[0]

                let tm_ids_csv = tm_ids.join(',')
                let vivid_ids_csv = vi_ids.join(',')

                let postal_code = _.chain(vz)
                    .map((v) => v.postal_code)
                    .sortBy()
                    .value()[0]
                let country = 'US'
                let is_approved = true

                return {
                    id,
                    tm_ids_csv,
                    vivid_ids_csv,
                    is_approved,
                    country,
                    postal_code
                }
            })
            .value()

        console.log(_groupsToSave)

        let ans = await apiPost('/linker/groups', {
            groups: _groupsToSave
        })
    
        //console.log('apiPost ans', ans)
        notifyMessage('Saved successfully')
    }

    // TODO: handle id clash between vendors by prefixing ids
    const makeGroup = useCallback((ids) => {
        ids = ids.filter(id => id !== '')
        console.log('making group', ids)
        let venueGroup = allVenues.filter((v) => ids.includes(v.id))
        let remainingAllVenues = allVenues.filter((v) => !ids.includes(v.id))
        if (venueGroup.length > 0) {
            setAllProcessedVenues([...(allProcessedVenues || []), venueGroup])
            setActiveIds([])
            setAllVenues(remainingAllVenues)
        }
    }, [allVenues, allProcessedVenues])

    const handleForward = () => {
        makeGroup(activeIds)

        // bottomOfProcessedVenues.current?.scrollIntoView({
        //     block: 'start',
        //     inline: 'center',
        //     behavior: 'smooth',
        //     alignToTop: true
        // })
    }

    const handleCancel = useCallback(() => {
        setActiveIds([])
    }, [])

    const handleDelete = () => {
        if (!activeGroupId) return
        if (activeGroupId === -1) return
        let venueGroup = allProcessedVenues.filter(
            (vz) => vz.map((v) => v.id).join(',') === activeGroupId
        )[0]
        let remainingVenueGroups = allProcessedVenues.filter(
            (vz) => vz.map((v) => v.id).join(',') !== activeGroupId
        )

        let newAllVenues = _.chain([...(venueGroup || []), ...(allVenues || [])])
            .sortBy('combo_cpan')
            .value()
        setAllVenues(newAllVenues)
        setAllProcessedVenues(remainingVenueGroups)
    }

    let keyMap = {
        f: handleForward,
        g: handleForward,
        s: handleSave,
        c: handleCancel,
        x: handleDelete
    }

    const handleKeyDown = (event) => {
        let handler = keyMap[event.key] ?? (() => {})
        handler()
    }

    const handlePostalCodeChanged = useCallback((value) => {
        setSelectedPostalCode(value[0])
    }, [])

    const handleFreeSearchTextChange = useCallback((value) => {
        setFreeSearchText(value)
    }, [])

    const matchZip = (venue, postalCode) =>
        !postalCode || venue.postal_code === postalCode

    let venueViews = _.chain(venues)
        .filter((v) => matchZip(v, selectedPostalCode))
        .orderBy('combo_cpan')
        .take(maxRows)
        .map((v) => {
            let onVenueClick = () => {
                if (activeIds.includes(v.id)) {
                    setActiveIds(activeIds.filter((id) => id !== v.id))
                } else {
                    setActiveIds([...activeIds, v.id])
                }
            }

            return (
                <Venue
                    key={v.id}
                    venue={v}
                    active={activeIds.includes(v.id)}
                    onClick={onVenueClick}
                />
            )
        })
        .value()

    let processedVenuesView = _.chain(processedVenues)
        .filter((vz) => matchZip(vz[0], selectedPostalCode))
        .filter((vz) => vz.length > 0)
        .orderBy((vz) => vz[0].combo_cpan)
        .take(maxRows)
        .map((vz) => {
            let groupId = vz.map((v) => v.id).join(',')
            let onClickVenueGroup = () => {
                if (activeGroupId === groupId) {
                    setActiveGroupId(-1)
                } else {
                    setActiveGroupId(groupId)
                }
            }

            return (
                <VenueGroup
                    venues={vz}
                    key={groupId}
                    active={activeGroupId === groupId}
                    onClick={onClickVenueGroup}
                />
            )
        })
        .value()

    return (
        <div
            style={{ outline: 'none' }}
            tabIndex={0}
            onKeyDown={handleKeyDown}
        >
            <div className={styles.shortcuts}>

                        <div style={{position: 'relative'}}>
                            <button
                                onClick={() => window.location.reload(true)}
                            >
                                Reset All
                            </button>
                            <button onClick={handleCancel}>
                                Clear Selection
                            </button>

                            <button onClick={handleForward}>
                                Compile Group
                            </button>
                            <button onClick={handleDelete}>Delete Group</button>

                            <button onClick={handleSave}>Save</button>

                            <button
                                onClick={() => setDisplayHelp(!displayHelp)}
                            >
                                Help
                            </button>
                            <span style={{position: 'absolute', right: 40, top: 6, fontSize: 12, fontWeight: 600, color: 'hsl(240, 0%, 50%)'}}>
                                {notificationText}
                            </span>
                        </div>



            </div>

            <div className={styles.flexcontainer} style={{ height: '100%' }}>
                <div
                    className={styles.flexchild}
                    style={{ maxHeight: maxHeight, flex: 0.7 }}
                >
                    <div
                        style={{
                            margin: 18,
                            position: 'relative',
                            height: '95%'
                        }}
                    >
                        {displayHelp && (
                            <div className={styles.prose}>
                                <h1>General Help</h1>
                                <p>
                                    In most cases you want to group venues that
                                    are in the same zip code.
                                </p>
                                <p>
                                    To do so, click on a location (city, state,
                                    zip) to view candidate venues in that
                                    location.
                                </p>
                                <p>
                                    To work across zipcodes or to look for a
                                    venue you have in mind, optionally clear the
                                    location filter, then type in the name of
                                    the venue you're interested, or its address.
                                </p>
                                <p>
                                    Avoid working on the full dataset without
                                    any filters because it's huge: try to always
                                    have either a location or a textual filter
                                    to keep the dataset manageable
                                </p>
                                <p>
                                    Click on a few random venues, then click the
                                    Clear Selection button to clear the selected
                                    venues.
                                </p>

                                <p>
                                    Click on a few venues that represent the
                                    same place, then click the Compile Group
                                    button. The group is compiled and moved to
                                    the right panel.
                                </p>
                                <p>
                                    To delete a grouping, click on it in the
                                    right panel then click the Delete Group
                                    button.
                                </p>
                                <p>
                                    Make sure to Save your work frequently, and
                                    optionally reload everything (Reset All) to
                                    make sure it gets loaded from the database
                                    as expected.
                                </p>
                                <p></p>
                                <h1>Keyboard Shortcuts</h1>
                                <p>F or G = Compile Group</p>
                                <p>C = Clear the selected venues</p>
                                <p>X = Delete a group</p>
                                <p>S = Save to the database</p>
                                <p>&nbsp;</p>
                                <button onClick={() => setDisplayHelp(false)}>
                                    Close Help
                                </button>
                            </div>
                        )}

                        {!displayHelp && (
                            <div>
                                <div>
                                    <TextField
                                        value={freeSearchText}
                                        onChange={handleFreeSearchTextChange}
                                        placeholder="Quick Filter"
                                        autoComplete="off"
                                    />
                                </div>
                                <p>&nbsp;</p>

                                <div>
                                    <MultiSelect
                                        multiple="multiple"
                                        options={postalCodes.map((pc) => ({
                                            value: pc,
                                            label: zipToCityState[pc] + ' ' + pc
                                        }))}
                                        value={[selectedPostalCode] || []}
                                        onChange={handlePostalCodeChanged}
                                        selectStyle={{
                                            height: maxHeight - 200
                                        }}
                                    />
                                </div>
                                <div>
                                    <button
                                        className="action minor"
                                        style={{
                                            position: 'absolute',
                                            right: 0
                                        }}
                                        onClick={() =>
                                            setSelectedPostalCode(null)
                                        }
                                    >
                                        clear location filter
                                    </button>
                                    <p>&nbsp;</p>
                                    <p>&nbsp;</p>
                                </div>
                            </div>
                        )}
                    </div>
                </div>

                <div
                    className={styles.flexchild}
                    style={{ maxHeight: maxHeight }}
                >
                    <div>{venueViews}</div>
                </div>

                <div
                    className={styles.flexchild}
                    style={{ outline: 'none', maxHeight: maxHeight }}
                >
                    <div>{processedVenuesView}</div>
                    {/*<div
                        ref={bottomOfProcessedVenues}
                        style={{ height: 300 }}
                    ></div>*/}
                </div>
            </div>
        </div>
    )
}

export default VividLinking
