/** @format */

import { Card, Page, EmptyState, Spinner } from '@shopify/polaris'
import { useEffect, useState } from 'react'
import { useFirebaseContext } from '../../../FirebaseContext'
import { fields, defaultSearch } from './fieldConsts'
import SearchSection from './SearchSection'
import ResultsTable from './ResultsTable'
import ResultsGrid from './ResultsGrid'
import { useParams } from 'react-router-dom'
import { MdGridOn } from 'react-icons/md'

const EventSearch = () => {
    const { automateID } = useParams()
    const { expressFunction } = useFirebaseContext()
    const ticketMasterEventSearch = expressFunction('ticketMasterEventSearch')
    const tm_getAutomate = expressFunction('tm_getAutomate')
    const [searchParams, setSearchParams] = useState(defaultSearch)
    const [searchRunning, setSearchRunning] = useState(false)
    const [events, setEvents] = useState([])
    const [automateObj, setAutomateObj] = useState({})
    const [useAgGrid, setUseAgGrid] = useState(true)

    useEffect(() => {
        console.log('useEffect', automateID)

        if (automateID) {
            tm_getAutomate({ automateID }).then((response) => {
                const { data = [] } = response
                const { searchParams, events, automate } = data
                if (searchParams) {
                    setSearchParams(searchParams)
                }
                if (events) {
                    setEvents(events)
                }
                setAutomateObj(automate)
            })
        }
    }, [])

    useEffect(() => {
        let canceled = false
        const runSearch = async () => {
            setSearchRunning(true)

            const searchParamsModified = searchParams.map((param) => {
                console.log(param)
                let { field, operator, orderBy, value, weight } = param
                const fieldObj = fields[field]
                // if (fieldObj.type === "percentage") { // no longer needed since we are using the percentage  as a float
                // 	value = value / 100;
                // }
                return {
                    field,
                    operator,
                    orderBy: orderBy ?? 'asc',
                    value,
                    weight
                }
            })

            ticketMasterEventSearch({ search: searchParamsModified }).then(
                (response) => {
                    if (canceled) {
                        return
                    }
                    const { data = [] } = response
                    setEvents(data)
                    setSearchRunning(false)
                }
            )
        }

        if (searchRunning) {
            runSearch()
        } else {
            canceled = true
        }

        return () => {
            canceled = true
        }
    }, [searchRunning])

    if (automateID && !automateObj.id) {
        return <Spinner />
    }

    const gridToggle = (
        <div
            className="subtle-btn"
            style={{
                position: 'absolute',
                top: 40,
                right: 8,
                fontSize: 19.5
            }}
        >
            <MdGridOn
                onClick={() => {
                    setUseAgGrid(!useAgGrid)
                }}
            />
        </div>
    )

    return (
        <Page title="TicketMaster Event Search" fullWidth>
            {gridToggle}
            <SearchSection
                searchParams={searchParams}
                setSearchParams={setSearchParams}
                searchRunning={searchRunning}
                setSearchRunning={setSearchRunning}
                automateObj={automateObj}
            />
            {events.length === 0 ? (
                <EmptyResultsSection />
            ) : useAgGrid ? (
                <ResultsGrid events={events} searchParams={searchParams} />
            ) : (
                <ResultsTable events={events} searchParams={searchParams} />
            )}
        </Page>
    )
}

const EmptyResultsSection = () => {
    return (
        <Card sectioned>
            <EmptyState
                title="No results"
                heading="No results"
                // action={{ content: "Upload files" }}
                image="https://cdn.shopify.com/s/files/1/0262/4071/2726/files/emptystate-files.png"
                fullWidth
            ></EmptyState>
        </Card>
    )
}

export default EventSearch
