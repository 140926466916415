/** @format */

import { Card, Checkbox, FormLayout, Select } from '@shopify/polaris'
import { useCallback, useEffect, useRef, useState } from 'react'

import Highcharts from 'highcharts/highstock'
import HighchartsReact from 'highcharts-react-official'
import _ from 'lodash'
import moment from 'moment'

function numberWithCommas(x) {
    return x.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',')
}

const loPassThreshold = 1_200

const EventPageChart = ({ eventData, title, chartData }) => {
    const [seriesData, setSeriesData] = useState([])
    const [targetSeries, setTargetSeries] = useState('by_zone')
    const [consolidated, setConsolidated] = useState(true)
    const [isMarkersOnly, setIsMarkersOnly] = useState(false)
    const [isNavigatorEnabled, setIsNavigatorEnabled] = useState(false)
    const [isLoPass, setIsLoPass] = useState(false)
    const chartComponentRef = useRef(null)

    const markerModifier = isMarkersOnly
        ? {
            marker: {
                enabled: true,
                symbol: 'circle',
                radius: 2.0
            },

            lineWidth: 0,
            states: {
                hover: {
                    // Don't "++" the linewidth on hover
                    lineWidthPlus: 0
                }
            }
        }
        : {
            marker: {
                enabled: false
            },

            lineWidth: 2,
            states: {
                hover: {
                    lineWidthPlus: 1
                }
            }
        }

    useEffect(() => {
        let seriesData = [
            {
                name: `Consolidated`,
                data: chartData.consolidated,
                visible: targetSeries === 'consolidated',
                color: '#ff0000',
                ...markerModifier
            }
        ]

        if (targetSeries === 'by_class')
            seriesData.push(
                {
                    name: `Standard`,
                    data: chartData.standard,
                    visible: targetSeries === 'by_class',
                    color: '#000000',
                    ...markerModifier
                },
                {
                    name: `Platinum`,
                    data: chartData.platinum,
                    visible: targetSeries === 'by_class',
                    color: '#0000ff',
                    ...markerModifier
                }
            )

        if (targetSeries === 'by_zone') {
            seriesData.push(
                ..._.toPairs(chartData.bySection).map(([k, v]) => {
                    return {
                        name: k,
                        data: v,
                        visible: targetSeries === 'by_zone',
                        ...markerModifier
                    }
                })
            )
        }

        // if (isLoPass) {
        //     seriesData = seriesData.filter(s => {
        //         if (!s.data) {
        //             return false
        //         }

        //         return (s.data[s.data.length - 1][1] < loPassThreshold)
        //     })
        // }

        setSeriesData(seriesData)
    }, [consolidated, isMarkersOnly, chartData, targetSeries, isLoPass])

    useEffect(() => {
        const chart = chartComponentRef.current.chart

        let left = chart.xAxis[0].getExtremes().dataMin

        if (eventData.header.public_sale_start_date_utc) {
            left = new Date(
                eventData.header.public_sale_start_date_utc
            ).getTime()
        }

        if (eventData.header.presale_start_date_utc) {
            left = new Date(eventData.header.presale_start_date_utc).getTime()
        }

        let right = moment(eventData.header.event_date_utc).toDate().getTime()

        function setExtremes() {
            chart.xAxis[0].setExtremes(left, right)
        }

        // Seems like the chart sometimes needs a bit of time to render before we can the extremes
        setExtremes()
        setTimeout(setExtremes, 1000)
        setTimeout(setExtremes, 3000)

    }, [seriesData, eventData])

    const salesDates = () => {
        if (!eventData.header.last_seen_json) return []

        const { sales } = JSON.parse(eventData.header.last_seen_json) // the json parse was added for starrocks db
        const salesDates = []
        if (sales.public) {
            salesDates.push(sales.public)
        }
        if (sales.presales !== null && typeof sales.presales === 'object') {
            // console.log("sales.presales", sales.presales);
            sales.presales.forEach((presale) => {
                salesDates.push(presale)
            })
        }
        return salesDates
    }
    const plotBands = salesDates().map((sale) => {
        // console.log("saleIndex", saleIndex["__typename"]);
        const saleName = `${sale['__typename']}${sale.name ? ' - ' + sale.name.toLocaleUpperCase() : ''
            }`
        // console.log("saleName", saleName);
        // const sale = eventData.header.last_seen_json.sales[saleIndex];
        // console.log("sale", saleIndex, sale);
        // const saleName = saleIndex.toLocaleUpperCase();
        const startDateTime = moment(sale.startDateTime)
        const endDateTime = moment(sale.endDateTime)

        const overlayColors = {
            PublicSaleDates: 'rgba(232, 232, 232, 0.25)',
            Presale: '#a0f2a8'
        }

        return {
            from: startDateTime,
            to: endDateTime,
            color: overlayColors[sale['__typename']],
            label: {
                text: saleName,
                align: 'center',
                style: {
                    color: '#666'
                }
            }
        }
    })

    let chartMin = null

    if (eventData.header.public_sale_start_date_utc) {
        chartMin = new Date(
            eventData.header.public_sale_start_date_utc
        ).getTime()
    }

    if (eventData.header.presale_start_date_utc) {
        chartMin = new Date(eventData.header.presale_start_date_utc).getTime()
    }

    //chartMin = new Date('2023-06-01').getTime()

    let chartMax = new Date(eventData.header.event_date_utc).getTime()

    const options = {
        title: {
            text: title
        },
        navigator: {
            enabled: isNavigatorEnabled
        },
        scrollbar: {
            enabled: false
        },
        rangeSelector: {
            buttons: [
                {
                    type: 'month',
                    count: 1,
                    text: '1m'
                },
                {
                    type: 'month',
                    count: 3,
                    text: '3m'
                },
                {
                    type: 'month',
                    count: 6,
                    text: '6m'
                },
                {
                    type: 'all',
                    text: 'All'
                }
            ]
        },
        legend: { enabled: targetSeries !== 'consolidated' },
        chart: {
            height: 650,
            //animation: { duration: 220 }
            animation: false
        },
        plotOptions: {
            series: {
                animation: false,
            },
        },
        xAxis: {
            type: 'datetime',
            ordinal: false,
            max: chartMax,
            min: chartMin,
            plotBands: plotBands
        },
        yAxis: {
            title: {
                text: 'Quantity'
            },
            min: 0,
            max: isLoPass ? loPassThreshold : null,
            gridLineColor: 'hsl(0, 0%, 90%)'
        },
        series: seriesData,
        accessibility: { enabled: false }
    }

    const checkboxSpacer = <span style={{ marginRight: 24 }} />


    const setZoomToFirstNDays = useCallback(
        (chartComponentRef, chartData, numDays) => {
            console.log('zoom')
            const chart = chartComponentRef.current.chart
            console.log('chartData', chartData['consolidated'])
            const firstPoint = chartData['consolidated'][0][0] // Get the x value of the first data point
            const tenDaysLater = firstPoint + numDays * 24 * 3600 * 1000
            chart.xAxis[0].setExtremes(firstPoint, tenDaysLater)
        }
    )



    return (
        <Card sectioned>
            <div style={{ position: 'relative', padding: '18px 12px' }}>
                <div
                    style={{
                        position: 'relative',
                        marginBottom: 18,
                        opacity: 0.88
                    }}
                    className="wtt-chart-label"
                >
                    <FormLayout>
                        <FormLayout.Group condensed>
                            {false && (
                                <Checkbox
                                    checked={consolidated}
                                    onChange={setConsolidated}
                                    label="consolidated"
                                />
                            )}
                            <div>
                                <Checkbox
                                    checked={isMarkersOnly}
                                    onChange={setIsMarkersOnly}
                                    label="dots"
                                />
                                {checkboxSpacer}
                                <Checkbox
                                    checked={isNavigatorEnabled}
                                    onChange={setIsNavigatorEnabled}
                                    label="navigator"
                                />

                                {checkboxSpacer}
                                <Checkbox
                                    checked={isLoPass}
                                    onChange={setIsLoPass}
                                    label="lo-pass"
                                />

                                <span
                                    className="chart-btn"
                                    onClick={() => {
                                        setZoomToFirstNDays(
                                            chartComponentRef,
                                            chartData,
                                            10
                                        )
                                    }}
                                >
                                    data start
                                </span>

                            </div>
                            <Select
                                options={[
                                    {
                                        label: 'Consolidated',
                                        value: 'consolidated'
                                    },
                                    { label: 'By Class', value: 'by_class' },
                                    { label: 'By Zone', value: 'by_zone' }
                                ]}
                                onChange={(v) => {
                                    setTargetSeries(v)
                                    if (v === 'consolidated') {
                                        setConsolidated(true)
                                    } else {
                                        setConsolidated(false)
                                    }
                                }}
                                value={targetSeries}
                            />
                        </FormLayout.Group>
                    </FormLayout>
                </div>
                <HighchartsReact
                    ref={chartComponentRef}
                    highcharts={Highcharts}
                    constructorType={'stockChart'}
                    options={options}
                />
            </div>
        </Card>
    )
}

export default EventPageChart
