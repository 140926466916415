import { BsLayoutSidebarReverse, BsViewStacked } from 'react-icons/bs'
import { Card, Checkbox, Layout, Page, Spinner } from '@shopify/polaris'
import React, { useCallback, useEffect, useState } from 'react'
import { useNavigate, useParams } from 'react-router-dom'

// import LineChart from './LineChart'
import _ from 'lodash'
import { apiGet } from '../../Api'
import axios from 'axios'
import moment from 'moment'
import { useCookies } from 'react-cookie'

const AxsSearchPage = () => {
    useEffect(() => {
        async function fetchData() {
            const response = await apiGet('/axs/allEvents')
            console.log('response was')
            console.log(response)
        }

        fetchData()
    }, [])

    return (
        <div style={{ padding: '2rem' }}>
            <h1>Not yet implemented</h1>
        </div>
    )
}

export { AxsSearchPage }
