/** @format */
import { Card, Checkbox } from '@shopify/polaris'
import React, { useCallback, useEffect, useState } from 'react'

import LineChart from './LineChart'
import _ from 'lodash'
import { dateOf } from '../EventPage/utils'
import moment from 'moment'

export const DynamicMinPriceChart = ({
    title,
    eventData,
    sectionGroupMapping,
    inventoryType
}) => {
    const [chartData, setChartData] = useState({})

    const chartComponentRef = React.useRef()

    const [isChartBreakdown, setIsChartBreakdown] = useState(true)
    const [isYAxisQuantity, setIsYAxisQuantity] = useState(false)

    const handlePriceLevels = useCallback(
        (
            sectionGroupMapping,
            isChartBreakdown,
            setChartData,
            isYAxisQuantity,
            eventData
        ) => {
            if (!eventData || !eventData.inventory) {
                return
            }

            const priceLevelsChartRawData = eventData.inventory
                .filter((v) => v.inventory_types === inventoryType)
                .map((v) => ({
                    fetched_at: v.fetched_at,
                    price: v.price_min,
                    section: v.section,
                    count: v.count
                }))

            let recordsByFetchedAt = _.groupBy(
                priceLevelsChartRawData,
                (x) => x.fetched_at
            )

            let fetchedAts = _.orderBy(Object.keys(recordsByFetchedAt), (x) =>
                new Date(x).getTime()
            )

            let distinctZones = _.uniqBy(Object.values(sectionGroupMapping))
            distinctZones = _.sortBy(distinctZones)

            let chartData = []
            let chartDataBreakdown = {}

            let chartBreakdownKeys = []

            for (let zone of distinctZones) {
                chartBreakdownKeys.push(zone)
                chartDataBreakdown[zone] = []
            }

            let dateOf = (x) => {
                return moment(x).startOf('day').format()
            }

            for (
                let fetchedAtIndex = 0;
                fetchedAtIndex < fetchedAts.length;
                fetchedAtIndex++
            ) {
                let fetchedAt = fetchedAts[fetchedAtIndex]

                // We need to drop intraday data points to avoid the chart getting sluggish
                // so we include a data point only if number of x values is < 50, or, if there's more
                // than 50, then just take the last data point of the day
                if (
                    fetchedAts.length < 50 ||
                    fetchedAtIndex === fetchedAts.length - 1 ||
                    dateOf(fetchedAts[fetchedAtIndex]) !==
                        dateOf(fetchedAts[fetchedAtIndex + 1])
                ) {
                    let recordsAtPriceLevel = recordsByFetchedAt[fetchedAt]
                    // console.log(recordsAtPriceLevel)
                    for (let record of recordsAtPriceLevel) {
                        record.zone = sectionGroupMapping[record.section] ?? '_'
                    }

                    let countsByPriceLevel = _.groupBy(
                        recordsAtPriceLevel,
                        (x) => x.price
                    )

                    let recordsByZone = _.groupBy(
                        recordsAtPriceLevel,
                        (x) => x.zone
                    )

                    //console.log(fetchedAt, countsByPriceLevel)

                    function getLowestPriceAndNonSingleQuantity(levelAndCount) {
                        const sortedLevelAndCount = _.orderBy(
                            levelAndCount,
                            (x) => x[0]
                        )

                        for (let [level, count] of sortedLevelAndCount) {
                            if (count > 1) {
                                return [level, count]
                            }
                        }

                        // If there aren't any > 1, then just return the only one
                        for (let [level, count] of sortedLevelAndCount) {
                            return [level, count]
                        }

                        // Per kelvin, don't default to zero
                        // Default 0
                        // return [0, 0]

                        return [null, null]
                    }

                    const levelAndCount = Object.keys(countsByPriceLevel).map(
                        (pl) => [
                            parseFloat(pl),
                            _.sumBy(countsByPriceLevel[pl], (x) => x.count)
                        ]
                    )

                    const [lowestPrice, nonSingleQuantity] =
                        getLowestPriceAndNonSingleQuantity(levelAndCount)

                    chartData.push({
                        x: new Date(fetchedAt).getTime(),
                        y: isYAxisQuantity ? nonSingleQuantity : lowestPrice,
                        additionalInfo: {
                            label: `${nonSingleQuantity} tickets at $${lowestPrice}`
                        }
                    })

                    for (let seriesKey of chartBreakdownKeys) {
                        const recordsForZone = recordsByZone[seriesKey]

                        const zoneCountsByPriceLevel = _.groupBy(
                            recordsForZone,
                            (x) => x.price
                        )

                        const zoneLevelAndCount = Object.keys(
                            zoneCountsByPriceLevel
                        ).map((pl) => [
                            parseFloat(pl),
                            _.sumBy(zoneCountsByPriceLevel[pl], (x) => x.count)
                        ])

                        const [zoneLowestPrice, zoneNonSingleQuantity] =
                            getLowestPriceAndNonSingleQuantity(
                                zoneLevelAndCount
                            )
                        chartDataBreakdown[seriesKey].push({
                            x: new Date(fetchedAt).getTime(),
                            y: isYAxisQuantity
                                ? zoneNonSingleQuantity
                                : zoneLowestPrice,
                            additionalInfo: {
                                label: `${zoneNonSingleQuantity} tickets at $${zoneLowestPrice}`
                            }
                        })
                    }
                }
            }

            const summaryChartData = [
                {
                    name: 'All',
                    step: true,
                    data: chartData
                }
            ]

            const byZoneChartData = Object.keys(chartDataBreakdown).map(
                (zone) => {
                    return {
                        name: zone,
                        step: true,
                        data: chartDataBreakdown[zone]
                    }
                }
            )

            if (isChartBreakdown) {
                setChartData(byZoneChartData)
            } else {
                setChartData(summaryChartData)
            }
        },
        []
    )

    useEffect(() => {
        handlePriceLevels(
            sectionGroupMapping,
            isChartBreakdown,
            setChartData,
            isYAxisQuantity,
            eventData
        )
    }, [
        handlePriceLevels,
        isChartBreakdown,
        isYAxisQuantity,
        sectionGroupMapping,
        eventData
    ])

    const applyToAllChartSeries = (func) => {
        if (
            !chartComponentRef ||
            !chartComponentRef.current ||
            !chartComponentRef.current.chart
        ) {
            return
        }

        let chart = chartComponentRef.current.chart
        chart.series.forEach((series, i) => {
            setTimeout(() => func(series), (i * 20) / chart.series.length)
        })
    }

    const resaleDynamicMinPriceChartUiOptions = (
        <div>
            <div style={{ marginBottom: 8 }}>
                <Checkbox
                    checked={isChartBreakdown}
                    onChange={() => {
                        setIsChartBreakdown(!isChartBreakdown)
                    }}
                    label="By Zone"
                />
                <span style={{ marginLeft: '24px' }}></span>
                <Checkbox
                    checked={isYAxisQuantity}
                    onChange={() => {
                        setIsYAxisQuantity(!isYAxisQuantity)
                    }}
                    label="Y=Quantity"
                />
            </div>
        </div>
    )

    return (
        <Card sectioned>
            <LineChart
                eventData={eventData}
                title={
                    isYAxisQuantity
                        ? 'Number of Resale Tickets at Min Price'
                        : 'Min Price of Resale Tickets'
                }
                chartData={chartData}
                chartComponentRef={chartComponentRef}
                showLegend={true}
                uiOptions={resaleDynamicMinPriceChartUiOptions}
                height={800}
            />
        </Card>
    )
}
