import './LiveEventPage.css'

import React, { useEffect, useState } from 'react'
import _, { set } from 'lodash'

/** @format */
import { Checkbox } from '@shopify/polaris'
import LineChart from './LineChart'
import { apiGet } from '../../../Api'

function getLastCountFromSummary(summary) {
    console.log('getLastCountFromSummary')
    console.log(summary)
    const singleSeries = summary[0].data
    if (singleSeries.length) {
        let lastRecord = singleSeries[singleSeries.length - 1]
        console.log('lastRecord', lastRecord)
        return lastRecord[1]
    } else {
        return 'No Data'
    }
}

function timeSeriesToText(series) {
    let r = ''

    function acc(...args) {
        r += args.join(' ')
    }

    const keys = Object.keys(series)

    for (let key of keys) {
        //acc('Section', key, '\n')
        const oneSeries = series[key]
        for (let pair of oneSeries) {
            acc(
                'Section',
                key,
                '  ',
                new Date(pair[0]).toISOString(),
                '  ',
                pair[1],
                '\n'
            )
        }
        acc('\n')
        acc('\n')
    }

    return r
}

const AdditionalInfo = ({
    eventID,
    liveChartDataSummary,
    liveChartDataBreakdown,
    sectionGroupMapping
}) => {
    const [isCollapsed, setIsCollapsed] = useState(true)
    const [lastCountFromDb, setLastCountFromDb] = useState('')
    const [fromDbText, setFromDbText] = useState('')
    const [fromCacheText, setFromCacheText] = useState('')

    useEffect(() => {
        async function run() {
            const dbRawInventory = await apiGet(
                '/event/getEventLiveInventory',
                {
                    eventId: eventID
                }
            )

            // COPY-PASTED FROM LIVE CHART COMPONENT, JUST TO GET THE TEXT SUMMARY WORKING
            // TODO: CONSIDER FACTORING UP FOR CODE REUSE IF THIS CODE IS USED LONG-TERM
            //! <BEGIN COPY PASTED>
            let liveDataSummary = []
            let liveDataBreakdown = {}
            let liveDataGroupedBreakdown = {}

            let results = dbRawInventory

            results = _.orderBy(results, 'fetched_at')

            let state = {}

            let getCount = () => {
                let totalCount = 0
                for (let key of Object.keys(state)) {
                    totalCount += state[key]
                }
                return totalCount
            }

            let getGroupCount = (group_id) => {
                let totalCount = 0
                for (let key of Object.keys(state)) {
                    let keyGroup = sectionGroupMapping[key] ?? '_'
                    if (keyGroup === group_id) {
                        totalCount += state[key]
                    }
                }
                return totalCount
            }

            for (let item of results) {
                let { section_id, fetched_at, count } = item

                let prevCount = state[section_id] || -1
                state[section_id] = count
                let isChanged = count !== prevCount

                if (isChanged) {
                    let newCount = getCount()
                    let now = Date.parse(fetched_at)
                    liveDataSummary.push([now, newCount])

                    if (liveDataBreakdown[section_id] === undefined) {
                        liveDataBreakdown[section_id] = []
                    }
                    liveDataBreakdown[section_id].push([now, state[section_id]])

                    let group_id = sectionGroupMapping[section_id] ?? '_'
                    let groupCount = getGroupCount(group_id)
                    if (liveDataGroupedBreakdown[group_id] === undefined) {
                        liveDataGroupedBreakdown[group_id] = []
                    }
                    liveDataGroupedBreakdown[group_id].push([now, groupCount])
                }
            }
            //! <END COPY PASTED>

            const liveDataGroupedBreakdownCache = _.fromPairs(
                liveChartDataBreakdown.map((r) => [r.name, r.data])
            )

            const liveDataGroupedBreakdownDb = liveDataGroupedBreakdown

            console.log(liveDataGroupedBreakdownCache)
            console.log(liveDataGroupedBreakdownDb)
            console.log(Object.keys(liveDataGroupedBreakdownCache))
            console.log(Object.keys(liveDataGroupedBreakdownDb))

            console.log('='.repeat(40))

            setFromDbText(timeSeriesToText(liveDataGroupedBreakdownDb))
            setFromCacheText(timeSeriesToText(liveDataGroupedBreakdownCache))

            const lastCount = liveDataSummary[liveDataSummary.length - 1][1]
            setLastCountFromDb(lastCount)
        }
        if (eventID) {
            setLastCountFromDb('Loading')
            run()
        }
    }, [eventID, liveChartDataBreakdown, sectionGroupMapping])

    if (!liveChartDataSummary || liveChartDataSummary.length === 0) {
        return ''
    }

    const lastCountFromCache = getLastCountFromSummary(liveChartDataSummary)

    return (
        <div
            className="clickable"
            style={{ position: 'relative', top: '-10px', left: '8px' }}
        >
            <span onClick={() => setIsCollapsed(!isCollapsed)}>
                Total Inventory. Cache:{lastCountFromCache}, DB:
                {lastCountFromDb}.
            </span>

            {!isCollapsed && (
                <div style={{ marginTop: 8 }}>
                    <div>
                        <table className="comparison">
                            <tr>
                                <td style={{ width: '50%' }}>
                                    <h2>From Db</h2>
                                </td>
                                <td style={{ width: '50%' }}>
                                    <h2>From Cache</h2>
                                </td>
                            </tr>
                        </table>
                    </div>
                    <div
                        style={{
                            maxHeight: 400,
                            overflowY: 'scroll'
                        }}
                    >
                        <table className="comparison">
                            <tr>
                                <td
                                    style={{
                                        width: '50%',
                                        verticalAlign: 'top'
                                    }}
                                >
                                    {fromDbText}
                                </td>
                                <td
                                    style={{
                                        width: '50%',
                                        verticalAlign: 'top'
                                    }}
                                >
                                    {fromCacheText}
                                </td>
                            </tr>
                        </table>
                    </div>
                </div>
            )}
        </div>
    )
}

const LiveChart = ({
    eventDataHeader,
    liveChartRawData,
    sectionGroupMapping
}) => {
    const [liveChartDataSummary, setLiveChartDataSummary] = useState([])
    const [liveChartDataBreakdown, setLiveChartDataBreakdown] = useState({})
    const [liveChartData, setLiveChartData] = useState({})
    const [isLiveChartBreakdown, setIsLiveChartBreakdown] = useState(true)
    const liveChartComponentRef = React.useRef()

    useEffect(() => {
        let liveDataSummary = []
        let liveDataBreakdown = {}
        let liveDataGroupedBreakdown = {}

        let results = liveChartRawData

        results = _.orderBy(results, 'fetched_at')

        let state = {}

        let getCount = () => {
            let totalCount = 0
            for (let key of Object.keys(state)) {
                totalCount += state[key]
            }
            return totalCount
        }

        let getGroupCount = (group_id) => {
            let totalCount = 0
            for (let key of Object.keys(state)) {
                let keyGroup = sectionGroupMapping[key] ?? '_'
                if (keyGroup === group_id) {
                    totalCount += state[key]
                }
            }
            return totalCount
        }

        for (let item of results) {
            let { section_id, fetched_at, count } = item

            let prevCount = state[section_id] || -1
            state[section_id] = count
            let isChanged = count !== prevCount

            if (isChanged) {
                let newCount = getCount()
                let now = Date.parse(fetched_at)
                liveDataSummary.push([now, newCount])

                if (liveDataBreakdown[section_id] === undefined) {
                    liveDataBreakdown[section_id] = []
                }
                liveDataBreakdown[section_id].push([now, state[section_id]])

                let group_id = sectionGroupMapping[section_id] ?? '_'
                let groupCount = getGroupCount(group_id)
                if (liveDataGroupedBreakdown[group_id] === undefined) {
                    liveDataGroupedBreakdown[group_id] = []
                }
                liveDataGroupedBreakdown[group_id].push([now, groupCount])
            }
        }

        let _liveChartDataSummary = [
            {
                name: 'All',
                step: true,
                data: liveDataSummary,
                lineWidth: 1.8,
                lineColor: 'hsl(212, 40%, 60%)'
            }
        ]

        let _liveChartDataBreakdown = Object.keys(liveDataGroupedBreakdown).map(
            (group_id) => {
                return {
                    name: group_id,
                    step: true,
                    data: liveDataGroupedBreakdown[group_id]
                }
            }
        )

        if (isLiveChartBreakdown === undefined) {
            setIsLiveChartBreakdown(false)
        }

        setLiveChartDataSummary(_liveChartDataSummary)
        setLiveChartDataBreakdown(_liveChartDataBreakdown)
    }, [isLiveChartBreakdown, liveChartRawData, sectionGroupMapping])

    useEffect(() => {
        setLiveChartData(
            isLiveChartBreakdown ? liveChartDataBreakdown : liveChartDataSummary
        )
    }, [isLiveChartBreakdown, liveChartDataBreakdown, liveChartDataSummary])

    const liveChartUiOptions = (
        <div>
            <Checkbox
                checked={isLiveChartBreakdown}
                onChange={() => {
                    setIsLiveChartBreakdown(!isLiveChartBreakdown)
                }}
                label="By Zone"
            />
            <span style={{ marginLeft: 28 }}></span>
        </div>
    )

    const additionalInfoContent = AdditionalInfo({
        eventID: eventDataHeader.id,
        liveChartDataSummary,
        liveChartDataBreakdown,
        sectionGroupMapping: sectionGroupMapping
    })
    const additionalInfo = (
        <div className="mono heavy-gray">{additionalInfoContent}</div>
    )

    return (
        <div>
            <LineChart
                eventDataHeader={eventDataHeader}
                title={'Live Inventory Data'}
                chartData={liveChartData}
                showLegend={isLiveChartBreakdown}
                uiOptions={liveChartUiOptions}
                chartComponentRef={liveChartComponentRef}
                additionalInfo={additionalInfo}
            />
        </div>
    )
}

export default LiveChart
