/** @format */

import _ from "lodash";
import {
  Card,
  Checkbox,
  Form,
  FormLayout,
  Select,
  TextField,
} from "@shopify/polaris";
import { useEffect, useCallback, useState } from "react";
import { useSearchParams } from "react-router-dom";
import { useFirebaseContext } from "../../../FirebaseContext";

const AutomateDrawer = ({
  automateDrawerStatus,
  setAutomateDrawerStatus,
  searchParams,
  setSearchParams,
}) => {
  const { expressFunction, user } = useFirebaseContext();
  const updateFunction = expressFunction("tm_createUpdateAutomate");
  const [automateID, setAutomateID] = useState("");
  const [automateName, setAutomateName] = useState("");
  const [publicBoolen, setPublicBoolen] = useState(false);
  const [isRealtime, setIsRealtime] = useState(false);
  const [automateRecipient, setAutomateRecipient] = useState("");
  const [saving, setSaving] = useState(false);
  const [bookmarkNames, setBookmarkNames] = useState([]);
  const [bookmarkDetails, setBookmarkDetails] = useState([]);
  const [selectedBookmarkName, setSelectedBookmarkName] = useState("");

  const [urlParams, setUrlParams] = useSearchParams();

  const allowedToSave = automateName.length > 0 && !saving;

  const onSave = async () => {
    setSaving(true);
    let params = {
      action: "save",
      ownerEmail: user.email,
      automateName: automateName,
      query: searchParams,
      automateRecipient: automateRecipient,
      is_public: publicBoolen,
      is_realtime: isRealtime,
    };
    let result = await updateFunction(params);

    await updateBookmarks(result);
    setSelectedBookmarkName(automateName);

    setSaving(false);
  };

  const clearFields = () => {
    setAutomateName("");
    setPublicBoolen(false);
    setIsRealtime(false);
    setAutomateRecipient("");
  };

  const onDelete = useCallback(async () => {
    let params = {
      action: "delete",
      ownerEmail: user.email,
      is_public: publicBoolen,
      automateName: automateName,
    };
    let result = await updateFunction(params);
    clearFields();
    updateBookmarks(result);
  });

  const onBookmarkChanged = useCallback((value) => {
    setSelectedBookmarkName(value);
  }, []);

  useEffect(() => {
    let bookmark = bookmarkDetails.filter(
      (b) => b.filter_name === selectedBookmarkName
    )[0];
    if (bookmark) {
      setAutomateID(bookmark.id);
      setAutomateName(bookmark.filter_name);
      setPublicBoolen(bookmark.is_public);
      setIsRealtime(bookmark.is_realtime);
      setAutomateRecipient(bookmark.recipients);
      setSearchParams(JSON.parse(bookmark.content));
    } else {
      clearFields();
    }
  }, [selectedBookmarkName]);

  const updateBookmarks = async (result) => {
    if (result.data) {
      console.debug("result.data", result.data)
      let names = [""].concat(result.data.map((r) => r.filter_name));
      names = _.uniq(names);
      setBookmarkNames(names);
      setBookmarkDetails(result.data);
    }
  };

  const loadBookmarks = async () => {
    let result = await updateFunction({
      ownerEmail: user.email,
    });
    await updateBookmarks(result);

    if (urlParams.get("bookmark")) {
      setSelectedBookmarkName(urlParams.get("bookmark"));
    }
  };

  useEffect(() => {
    loadBookmarks();
  }, []);

  useEffect(
    (sp) => {
      if (automateName) {
        setUrlParams({ bookmark: automateName });
      }
    },
    [automateName]
  );

  return (
    <div style={{ display: automateDrawerStatus ? "block" : "none" }}>
      <Card
        title="Bookmarks"
        sectioned
        actions={[
          {
            content: "X",
            onAction: () => setAutomateDrawerStatus(false),
          },
        ]}
        primaryFooterAction={{
          content: automateID ? "Update" : "Save",
          onAction: onSave,
          disabled: !allowedToSave,
        }}
        secondaryFooterActions={[
          {
            content: "Delete Bookmark",
            onAction: onDelete,
            disabled: !selectedBookmarkName,
          },
          //{ content: "Close", onAction: () => setAutomateDrawerStatus(false), },
        ]}
        secondaryFooterActionsDisclosureText="More actions"
      >
        <Form onSubmit={onSave}>
          <FormLayout>
            <Select
              label=""
              options={bookmarkNames}
              value={selectedBookmarkName}
              onChange={onBookmarkChanged}
            />

            <h2 style={{ marginTop: "2em" }}>Bookmark Details</h2>

            <TextField
              label="Name"
              value={automateName}
              onChange={setAutomateName}
            />

            <FormLayout.Group condensed>
              <Checkbox
                label="Public"
                checked={publicBoolen}
                onChange={() => setPublicBoolen(!publicBoolen)}
              />
              <Checkbox
                label="Realtime"
                checked={isRealtime}
                onChange={() => setIsRealtime(!isRealtime)}
              />
            </FormLayout.Group>

            <TextField
              label="Recipients"
              value={automateRecipient}
              onChange={setAutomateRecipient}
              helpText="Slack users to notify, separated by commas. Leave empty if you don't want to monitor this filter."
            />
          </FormLayout>
        </Form>
      </Card>
    </div>
  );
};

export default AutomateDrawer;
