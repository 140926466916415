/** @format */
import { Button } from '@shopify/polaris'
import { useFirebaseContext } from '../../../FirebaseContext'
import React, { useState } from 'react'
import _ from 'lodash'

import './EventPage.css'

export const ForceInventoryFetch = ({ eventData }) => {
    const eventID = eventData.header.id

    const { expressFunction } = useFirebaseContext()
    const tm_updateEvent = expressFunction('tm_updateEvent')

    const [updating, setUpdating] = useState(false)

    const handleUpdate = () => {
        setUpdating(true)
        tm_updateEvent({ eventID, forceInventoryFetch: true })
            .then((res) => {
                if (res.data.error) {
                } else {
                    // reload page
                    window.location.reload()
                }
            })
            .catch((err) => {
                console.log('err', err)
                setUpdating(false)
            })
    }
    return (
        <div style={{ margin: '14px 0px' }}>
            <Button
                onClick={() => {
                    handleUpdate()
                }}
            >
                Force Inventory Fetch
            </Button>
        </div>
    )
}
