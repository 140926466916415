import 'ag-grid-community/styles/ag-grid.css'
import 'ag-grid-community/styles/ag-theme-alpine.css'
import './ResultsGrid.css'

import { Button, Frame, Modal, TextContainer } from '@shopify/polaris'
import { apiGet, apiPost } from '../../../Api'
import { useCallback, useEffect, useMemo, useState } from 'react'

import { AgGridReact } from 'ag-grid-react'
import Alerts from '../EventPage/Alerts'
import { ExternalMinor } from '@shopify/polaris-icons'
import { Icon } from '@shopify/polaris'
import { IoAirplane } from 'react-icons/io5'
import { TextField } from '@shopify/polaris'
import moment from 'moment'
import { useFirebaseContext } from '../../../FirebaseContext'

const { fields } = require('./fieldConsts')

const ResultsGrid = ({ events }) => {
    const defaultColDef = useMemo(() => {
        return {
            editable: true,
            sortable: true,
            filter: true,
            resizable: true
        }
    }, [])

    let computeColumnDefs = useCallback(() => {
        let _columnDefs = [
            {
                field: 'tm_event_url',
                headerName: '',
                width: 36,
                cellRenderer: (v) => {
                    return (
                        <a href={v.value} rel="noreferrer" target="_blank">
                            <img
                                src={'/images/tmLogo.svg'}
                                alt="Ticketmaster Logo"
                                style={{ height: '15px' }}
                            />
                        </a>
                    )
                }
            },
            {
                field: 'tm_event_id',
                headerName: '',
                width: 36,
                cellRenderer: (v) => {
                    return (
                        <a
                            href={`/ticketMaster/event/${v.value}`}
                            rel="noreferrer"
                            target="_blank"
                            style={{ position: 'relative', top: 5 }}
                        >
                            <Icon source={ExternalMinor} />
                        </a>
                    )
                }
            },
            {
                field: 'tm_event_id',
                headerName: '',
                width: 36,
                cellRenderer: (v) => {
                    return (
                        <a
                            href={`/ticketMaster/eventLive/${v.value}`}
                            rel="noreferrer"
                            target="_blank"
                            style={{
                                position: 'relative',
                                top: 8,
                                color: 'hsl(0, 0%, 10%)'
                            }}
                        >
                            <Icon source={IoAirplane} />
                        </a>
                    )
                }
            }
        ]

        if (events && events[0]) {
            Object.keys(events[0]).forEach((key) => {
                let fieldName = key
                if (
                    fields[key] &&
                    fields[key].description !== 'Event Date UTC Relative'
                ) {
                    let colDef = {
                        field: key,
                        headerName: fields[key].description,

                        cellRenderer: (x) => {
                            if (
                                fieldName === 'tm_event_event_date_utc' ||
                                fieldName === 'tm_presale_start_date_utc' ||
                                fieldName === 'tm_public_sale_start_date_utc'
                            ) {
                                return (
                                    x.value &&
                                    moment(x.value).format('YYYY-MM-DD hh:mm a')
                                )
                            } else if (fieldName === 'tm_segment_name') {
                                return (
                                    <a
                                        href={`/ticketMaster/segment/${x.data['tm_segment_id']}`}
                                        rel="noreferrer"
                                        target="_blank"
                                    >
                                        <div style={{ display: 'flex' }}>
                                            {x.value}
                                        </div>
                                    </a>
                                )
                            } else if (fieldName === 'tm_venue_name') {
                                return (
                                    <a
                                        href={`/ticketMaster/venue/${x.data['tm_venue_id']}`}
                                        rel="noreferrer"
                                        target="_blank"
                                    >
                                        <div style={{ display: 'flex' }}>
                                            {x.value}
                                        </div>
                                    </a>
                                )
                            } else if (fieldName === 'isPresalePeriod') {
                                return x.value ? 'Yes' : 'No'
                            } else {
                                //if (i < 2 && isNaN(parseFloat(x.value))) {
                                //return parseFloat(x.value).toFixed(4)
                                return x.value
                            }
                        }
                    }
                    if (fields[key].description === 'Event Name') {
                        colDef.flex = 1
                        colDef.minWidth = 100
                        colDef.maxWidth = 800
                    }
                    _columnDefs.push(colDef)
                }
            })

            return _columnDefs
        }
    }, [events])

    const [columnDefs, setColumnDefs] = useState(computeColumnDefs())

    /*
    var gridOptions = {
        onGridSizeChanged: () => {
            gridOptions.api.sizeColumnsToFit()
        }
    }
    */

    useEffect(() => {
        setColumnDefs(computeColumnDefs())
    }, [events, computeColumnDefs])

    const [isModalOpen, setIsModalOpen] = useState(false)
    const [selectedEventIds, setSelectedEventIds] = useState([])

    const handleClickCreateAlert = () => {
        setAlertExpression('')
        setIsModalOpen(true)
    }

    const { user } = useFirebaseContext()

    const [alertExpression, setAlertExpression] = useState('')

    const saveAlert = () => {
        const doSave = async () => {
            console.log('save alert')
            console.log(selectedEventIds)
            console.log(alertExpression)
            const randomGroupId = (Math.random() + 1).toString(36).substring(2)

            let alerts = selectedEventIds.map((eventID) => ({
                event_id: eventID,
                status: 'pending',
                user: user.email,
                content: { expression: alertExpression },
                alerts_group_id: randomGroupId
            }))

            let createdAlerts = await apiPost('/alerts/createAlerts', {
                alerts: alerts
            })

            console.log('created alerts', createdAlerts)
        }

        doSave()
    }

    return (
        <div>
            <div
                style={{ marginTop: 24, height: 700, width: '100%' }}
                className="ag-theme-alpine"
            >
                <AgGridReact
                    rowData={events}
                    columnDefs={columnDefs}
                    defaultColDef={defaultColDef}
                    rowSelection="multiple"
                    onSelectionChanged={(e) => {
                        console.log('selection changed', e)
                        console.log(e.api)
                        console.log(e.api.getSelectedRows())
                        const _selectedEventIds = e.api
                            .getSelectedRows()
                            .map((e) => e.tm_event_id)
                        setSelectedEventIds(_selectedEventIds)
                    }}
                ></AgGridReact>
            </div>
            <div
                style={{
                    display: 'flex',
                    flexDirection: 'row-reverse'
                }}
            >
                <span
                    className="fakeActionButton"
                    style={{ marginTop: 12, marginBottom: 24, marginLeft: 12 }}
                    onClick={handleClickCreateAlert}
                >
                    New Alert
                </span>
            </div>

            <Modal
                open={isModalOpen}
                onClose={() => {
                    setIsModalOpen(false)
                }}
                title="Create a new alert"
                primaryAction={{
                    content: 'OK',
                    onAction: () => {
                        setIsModalOpen(false)
                        saveAlert()
                    }
                }}
            >
                <Modal.Section>
                    <div>
                        {selectedEventIds.length > 0 ? (
                            <div
                                style={{ position: 'relative', marginTop: 10 }}
                            >
                                <TextField
                                    value={alertExpression}
                                    onChange={(v) => setAlertExpression(v)}
                                />
                            </div>
                        ) : (
                            'Please select at least one event to create an alert for.'
                        )}
                    </div>
                </Modal.Section>
            </Modal>
        </div>
    )
}

export default ResultsGrid
