/** @format */
import { Select, Spinner } from '@shopify/polaris'
import { useFirebaseContext } from '../../../FirebaseContext'
import React, { useState } from 'react'
import _ from 'lodash'
import ms from 'ms'

export const FetchIntervalField = ({ eventData }) => {
    const eventID = eventData.header.id

    const { expressFunction } = useFirebaseContext()
    const tm_updateEvent = expressFunction('tm_updateEvent')

    const [fetchIntervalValue, setFetchIntervalValue] = useState(
        eventData.header.inventory_fetch_interval
    )
    const [serverResponse, setServerResponse] = useState('')

    const [updating, setUpdating] = useState(false)

    const handleError = (err) => {
        setServerResponse(err)
        setTimeout(() => {
            setServerResponse('')
        }, ms('5s'))
    }

    const handleUpdate = (value) => {
        // console.log("handleUpdate");
        setFetchIntervalValue(value)
        setUpdating(true)
        tm_updateEvent({ eventID, fetchInterval: value })
            .then((res) => {
                if (res.data.error) {
                    handleError(res.data.error)
                } else {
                    window.location.reload()
                }
            })
            .catch((err) => {
                handleError('Could not reach server...')
                console.log('err', err)
                setUpdating(false)
            })
    }

    const options = [
        { value: 300, label: '5 Minutes' },
        { value: 900, label: '15 Minutes' },
        { value: 3600, label: '1 Hour' },
        { value: 7200, label: '2 Hours' },
        { value: 10800, label: '3 Hours' },
        { value: 21600, label: '6 Hours' },
        { value: 43200, label: '12 Hours' },
        { value: 86400, label: '24 Hours' },
        { value: 1209600, label: '24 Hours' }
    ]

    if (updating) {
        return <Spinner />
    }

    return (
        <>
            {serverResponse && <p style={{ color: 'red' }}>{serverResponse}</p>}
            <Select
                label="Fetch Interval"
                labelInline
                options={options}
                value={fetchIntervalValue}
                onChange={(value) => {
                    handleUpdate(value)
                }}
                style={{ width: '10px' }}
            />
        </>
    )
}
