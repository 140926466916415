/** @format */

import "./App.css";
import "@shopify/polaris/build/esm/styles.css";
import enTranslations from "@shopify/polaris/locales/en.json";
import { AppProvider } from "@shopify/polaris";
import { useFirebaseContext } from "./FirebaseContext";
import { LoginPage } from "./Pages/Login";
import React from "react";
import { MoonLoader } from "react-spinners";

//const MainApp = React.lazy(() => import("./MainApp"));
import MainApp from "./MainApp"

/**
 * index >> App >> MainApp >> AppFrame
 * App is responsible for authentication
 */
function App() {
  const { user } = useFirebaseContext();

  if (user.checked === false) {
    return (
      <AppProvider i18n={enTranslations}>
        <div className="centered-spinner">
          <MoonLoader
            size={60}
            speedMultiplier={0.5}
            color="hsl(220, 50%, 70%)"
          />
        </div>
      </AppProvider>
    );
  } else if (user.checked === true && user.email === undefined) {
    return (
      <AppProvider i18n={enTranslations}>
        <LoginPage />
      </AppProvider>
    );
  }

  return <MainApp />;
}

export default App;
