import 'ag-grid-community/styles/ag-grid.css'
import 'ag-grid-community/styles/ag-theme-alpine.css'

import { BsLayoutSidebarReverse, BsViewStacked } from 'react-icons/bs'
import { Card, Checkbox, Layout, Page, Spinner } from '@shopify/polaris'
import React, { useCallback, useEffect, useMemo, useState } from 'react'
import { Stack, TextField } from '@shopify/polaris'
import { useNavigate, useParams } from 'react-router-dom'

import { AgGridReact } from 'ag-grid-react'
// import LineChart from './LineChart'
import _ from 'lodash'
import { apiGet } from '../../Api'
import axios from 'axios'
import moment from 'moment'
import { useCookies } from 'react-cookie'

const AxsVenueBrowser = () => {
    const [isLoading, setIsLoading] = useState(true)
    const [rowData, setRowData] = useState([])
    const [filteredRowData, setFilteredRowData] = useState([])
    const [quickFilterText, setQuickFilterText] = useState('')
    const [selectedEvent, setSelectedEvent] = useState(null)

    useEffect(() => {
        async function fetchData() {
            const response = await apiGet('/axs/allVenues')
            console.log('response was')
            // console.log(response)
            setRowData(response)
            setIsLoading(false)
        }

        fetchData()
    }, [])

    useEffect(() => {
        const filteredRows = rowData.filter((row) =>
            JSON.stringify(row)
                .toLowerCase()
                .includes(quickFilterText.toLowerCase())
        )
        setFilteredRowData(filteredRows)
    }, [rowData, quickFilterText])

    //const rowData = [
    //    {
    //        id: 2274,
    //        title: 'Gothic Theatre',
    //        address: '3263 S Broadway',
    //        city: 'Englewood',
    //        state: 'CO',
    //        postalCode: '80113',
    //        countryCode: 'US',
    //        active: 1,
    //        createdUTC: '2024-10-03T07:26:02.000Z',
    //        lastSeenUTC: '2024-10-03T07:26:02.000Z',
    //        url: 'https://www.axs.com/venues/2274/gothic-theatre-englewood-tickets',
    //        timezone: 'America/Denver'
    //    }
    //]

    const columnDefs = [
        {
            headerName: 'ID',
            field: 'id',
            sortable: true,
            filter: true,
            width: 150
        },
        {
            headerName: 'Title',
            field: 'title',
            sortable: true,
            filter: true,
            width: 300
        },
        {
            headerName: 'Address',
            field: 'address',
            sortable: true,
            filter: true
        },
        { headerName: 'City', field: 'city', sortable: true, filter: true },
        { headerName: 'State', field: 'state', sortable: true, filter: true },
        {
            headerName: 'Postal Code',
            field: 'postalCode',
            sortable: true,
            filter: true
        },
        {
            headerName: 'Country Code',
            field: 'countryCode',
            sortable: true,
            filter: true
        },
        { headerName: 'Active', field: 'active', sortable: true, filter: true },
        {
            headerName: 'Created UTC',
            field: 'createdUTC',
            sortable: true,
            filter: true
        },
        {
            headerName: 'Last Seen UTC',
            field: 'lastSeenUTC',
            sortable: true,
            filter: true
        },
        {
            headerName: 'URL',
            field: 'url',
            cellRenderer: (params) => (
                <a
                    href={params.value}
                    target="_blank"
                    rel="noopener noreferrer"
                >
                    Link
                </a>
            ),
            sortable: true,
            filter: true
        },
        {
            headerName: 'Timezone',
            field: 'timezone',
            sortable: true,
            filter: true
        }
    ]

    return (
        <div style={{ height: '100%', width: '100%', padding: 8 }}>
            <div style={{ marginBottom: 4, padding: 4 }}>
                <Stack alignment="center">
                    <span>AXS Venues</span>

                    <Stack.Item fill>
                        <TextField
                            labelHidden
                            aliign="right"
                            value={quickFilterText}
                            onChange={(t) => {
                                setQuickFilterText(t)
                            }}
                            placeholder="Quick Search"
                            autoComplete="off"
                        />
                    </Stack.Item>
                    <span
                        style={{
                            opacity: 0.3,
                            marginRight: 60,
                            marginLeft: 40
                        }}
                    >
                        Click on columns for more precise filtering and sorting
                    </span>
                </Stack>
            </div>
            {isLoading && <Spinner size="large" color="teal" />}
            {!isLoading && (
                <div
                    className="ag-theme-alpine"
                    style={{ height: '90%', width: '100%' }}
                >
                    <AgGridReact
                        rowData={filteredRowData}
                        columnDefs={columnDefs}
                        defaultColDef={{
                            resizable: true
                        }}
                        rowSelection="single"
                        onSelectionChanged={(e) => {
                            const rows = e.api.getSelectedRows()
                            if (rows.length === 1) {
                                setSelectedEvent(rows[0])
                            } else {
                                setSelectedEvent(null)
                            }
                        }}
                    />
                </div>
            )}
        </div>
    )
}

export { AxsVenueBrowser }
