/** @format */

import { Card, DataTable, Icon, Link } from '@shopify/polaris'
import {
    ExternalMinor,
    StarOutlineMinor,
    StarFilledMinor
} from '@shopify/polaris-icons'
import moment from 'moment'
import { useEffect, useState } from 'react'
import './eventSearch.css'
const { fields } = require('./fieldConsts')

const StarObject = () => {
    const [starred, setStarred] = useState(false)

    return (
        <div
            onMouseOver={() => {
                setStarred(true)
            }}
            onMouseOut={() => {
                setStarred(false)
            }}
        >
            <Icon
                source={starred ? StarFilledMinor : StarOutlineMinor}
                color="base"
            />
        </div>
    )
}

const ResultsTable = ({ events, searchParams }) => {
    // use polaris table component
    // https://polaris.shopify.com/components/lists-and-tables/table
    // https://polaris.shopify.com/components/lists-and-tables/resource-list
    // https://polaris.shopify.com/components/lists-and-tables/data-table

    const requiredOrderDescription = ['', 'Event Name', 'Event Date UTC']
    const requiredOrderFieldName = ['tm_event_name', 'tm_event_event_date_utc']

    const [headers, setHeaders] = useState([])
    const [rows, setRows] = useState([])

    useEffect(() => {
        let newHeaders = [...requiredOrderDescription]
        let newOrderFieldName = [...requiredOrderFieldName]
        if (events && events[0]) {
            Object.keys(events[0]).forEach((key) => {
                if (
                    fields[key] &&
                    !newHeaders.includes(fields[key].description) &&
                    fields[key].description !== 'Event Date UTC Relative'
                ) {
                    newHeaders.push(fields[key].description)
                    newOrderFieldName.push(key)
                }
            })
        }
        setHeaders(newHeaders)

        let newRows = []
        events.forEach((event) => {
            // console.log(event);
            const starEvent = <StarObject />
            let newRow = [starEvent]
            newOrderFieldName.forEach((fieldName) => {
                const fieldObj = fields[fieldName]
                let returnValue
                // console.log(fieldName);
                if (fieldName === 'tm_event_name') {
                    const internalLink = `/ticketMaster/event/${event.tm_event_id}`
                    returnValue = (
                        <div
                            style={{
                                display: 'flex',
                                margin: '0px',
                                padding: '0px'
                            }}
                        >
                            <Link url={internalLink} external>
                                {event[fieldName]}
                            </Link>
                            <div style={{ paddingLeft: '10px' }}>
                                <Link url={internalLink} external>
                                    <Icon source={ExternalMinor} />
                                </Link>
                            </div>
                            <div style={{ paddingLeft: '10px' }}>
                                <Link url={event.tm_event_url} external>
                                    <img
                                        src={'/images/tmLogo.svg'}
                                        style={{ height: '15px' }}
                                    />
                                </Link>
                            </div>
                            {/* <div style={{ paddingLeft: "10px" }}>
								<Link url={event.tm_event_url} external>
									<img
										src={"/images/vividLogo.png"}
										style={{ height: "15px" }}
									/>
								</Link>
							</div>
							<div style={{ paddingLeft: "10px" }}>
								<Link url={event.tm_event_url} external>
									<img
										src={"/images/favicon.SH.ico"}
										style={{ height: "15px" }}
									/>
								</Link>
							</div> */}
                        </div>
                    )
                } else if (
                    fieldName === 'tm_event_event_date_utc' ||
                    fieldName === 'tm_presale_start_date_utc' ||
                    fieldName === 'tm_public_sale_start_date_utc'
                ) {
                    returnValue = event[fieldName] && moment(event[fieldName]).format(
                        'YYYY-MM-DD hh:mm a'
                    )
                } else if (fieldName === 'tm_segment_name') {
                    returnValue = (
                        <Link
                            url={`/ticketMaster/segment/${event['tm_segment_id']}`}
                            external
                        >
                            <div style={{ display: 'flex' }}>
                                {event[fieldName]}
                            </div>
                        </Link>
                    )
                } else if (fieldName === 'tm_genre_name') {
                    returnValue = (
                        <Link
                            url={`/ticketMaster/genre/${event['tm_genre_id']}`}
                            external
                        >
                            <div style={{ display: 'flex', textAlign: 'left' }}>
                                {event[fieldName]}
                            </div>
                        </Link>
                    )
                } else if (fieldName === 'tm_venue_name') {
                    returnValue = (
                        <Link
                            url={`/ticketMaster/venue/${event['tm_venue_id']}`}
                            external
                        >
                            <div style={{ display: 'flex' }}>
                                {event[fieldName]}
                            </div>
                        </Link>
                    )
                } else if (fieldName === 'isPresalePeriod') {
                    returnValue = event[fieldName] ? 'Yes' : 'No'
                } else {
                    returnValue = event[fieldName]
                }

                newRow.push(returnValue)
            })
            newRows.push(newRow)
        })
        setRows(newRows)
    }, [events])
    return (
        <Card sectioned>
            <DataTable
                columnContentTypes={['text']}
                headings={headers}
                rows={rows}
            />
        </Card>
    )
}

export default ResultsTable
