/** @format */

import {
	IndexTable,
	Card,
	useIndexResourceState,
	Text,
	Tag,
} from "@shopify/polaris";
import { useState } from "react";

function IndexTableComp({ tickets }) {
	const [ticketArray, setTicketArray] = useState(tickets);
	const [sortArrayIndex, setSortArrayIndex] = useState(0);
	const [sortArrayDirection, setSortArrayDirection] = useState("asc");

	const customers = [
		{
			id: "3411",
			url: "customers/341",
			name: "Mae Jemison",
			location: "Decatur, USA",
			orders: 20,
			amountSpent: "$2,400",
		},
		{
			id: "2561",
			url: "customers/256",
			name: "Ellen Ochoa",
			location: "Los Angeles, USA",
			orders: 30,
			amountSpent: "$140",
		},
	];
	const resourceName = {
		singular: "ticket",
		plural: "tickets",
	};

	const { selectedResources, allResourcesSelected, handleSelectionChange } =
		useIndexResourceState(tickets);

	const rowMarkup = ticketArray.map(
		(
			{
				available_quantity,
				id,
				section,
				row,
				wholesale_price,
				retail_price,
				format,
			},
			index
		) => (
			<IndexTable.Row
				id={id}
				key={id}
				selected={selectedResources.includes(id)}
				position={index}
			>
				<IndexTable.Cell>{available_quantity}</IndexTable.Cell>
				<IndexTable.Cell>{section}</IndexTable.Cell>
				<IndexTable.Cell>{row}</IndexTable.Cell>
				<IndexTable.Cell>{"Seats"}</IndexTable.Cell>
				<IndexTable.Cell>{wholesale_price}</IndexTable.Cell>
				<IndexTable.Cell>{retail_price}</IndexTable.Cell>
				<IndexTable.Cell>{format}</IndexTable.Cell>
			</IndexTable.Row>
		)
	);

	const sortByColumn = (index) => {
		const fieldToSortBy = "row";
		let direction = "asc";
		if (sortArrayIndex === index) {
			direction = sortArrayDirection === "asc" ? "desc" : "asc";
		}
		console.log("direction", direction);
		setSortArrayIndex(index);
		setSortArrayDirection(direction);
		setTicketArray(
			ticketArray.sort((a, b) => {
				if (a[fieldToSortBy] > b[fieldToSortBy]) {
					return 1;
				}
				if (a[fieldToSortBy] < b[fieldToSortBy]) {
					return -1;
				}
				return 0;
			})
		);
	};

	return (
		<Card>
			<IndexTable
				resourceName={resourceName}
				itemCount={tickets.length}
				selectedItemsCount={
					allResourcesSelected ? "All" : selectedResources.length
				}
				onSelectionChange={handleSelectionChange}
				headings={[
					{ title: "Qty" },
					{ title: "Sec" },
					{ title: "Row" },
					{ title: "Seats" },
					{ title: "Wholesale" },
					{ title: "Retail" },
					{ title: "Ticket Type" },
				]}
				sortable={[
					{
						columnContent: "Qty",
						sortValue: "available_quantity",
					},
					{
						columnContent: "Sec",
						sortValue: "section",
					},
					{
						columnContent: "Row",
						sortValue: "row",
					},
					{
						columnContent: "Seats",
						sortValue: "seats",
					},
					{
						columnContent: "Wholesale",
						sortValue: "wholesale_price",
					},
					{
						columnContent: "Retail",

						sortValue: "retail_price",
					},
					{
						columnContent: "Ticket Type",
						sortValue: "format",
					},
				]}
				// defaultSortDirection="descending"
				// sortDirection="descending"
				// sortColumnIndex={0}
				onSort={sortByColumn}
				// sortToggleLabels={{
				// 	inactive: "Sort",
				// 	active: "Sorted",
				// 	accessibilityHint: "Sort by",
				// }}
			>
				{rowMarkup}
			</IndexTable>
		</Card>
	);
}

const InventoryTable = ({ inventory, selected, setSelected }) => {
	console.log("inventory");
	// show indexed table from polasris

	return <IndexTableComp tickets={inventory.ticket_groups} />;
};

export default InventoryTable;
