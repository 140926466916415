/** @format */

import axios from "axios";
import { createContext, useEffect, useState, useContext } from "react";

function usePersistedState(key, defaultValue) {
  const [state, setState] = useState(
    () => JSON.parse(localStorage.getItem(key)) || defaultValue
  );
  useEffect(() => {
    localStorage.setItem(key, JSON.stringify(state));
  }, [key, state]);
  return [state, setState];
}

export const FirebaseContext = createContext();
export const useFirebaseContext = () => useContext(FirebaseContext);

export const FirebaseProvider = ({ children }) => {
  const [user, setUser] = useState({ checked: false });
  const [authToken, setAuthToken] = usePersistedState("authToken", "");
  const [permissions, setPermissions] = useState({});

  const devOrigin = "http://127.0.0.1:3000";
  const devEndpoint = "http://127.0.0.1:7001/api";
  const realEndpoint = `${window.location.origin}/api`;

  const endPoint =
    window.location.origin === devOrigin ? devEndpoint : realEndpoint;

  // console.log("endPoint", endPoint);

  const expressFunction = (name) => {
    return (payload) => {
      return axios.post(
        endPoint,
        {
          functionName: name,
          args: payload,
        },
        {
          headers: {
            Authorization: `Bearer ${authToken}`,
          },
        }
      );
    };
  };

  useEffect(() => {
    if (user.checked === false) {
      axios
        .post(
          `${endPoint}/auth/check`,
          {},
          {
            origin: window.location.origin,
            headers: {
              Authorization: `Bearer ${authToken}`,
            },
          }
        )
        .then((res) => {
          const { success } = res.data;
          if (success) {
            setUser({ checked: true, ...res.data });
            // alert(authToken);
            document.cookie = `${"_accessToken"}=${authToken}; path=/`;
          } else {
            setUser({ checked: true, ...res.data });
          }
        });
    } else {
      document.cookie = `${"_accessToken"}=; expires=Thu, 01 Jan 1970 00:00:00 UTC; path=/;`;
    }
  }, [authToken]);

  return (
    <FirebaseContext.Provider
      value={{
        user,
        permissions,
        expressFunction,
        signIn: ({ email, password }) => {
          return axios
            .post(`${endPoint}/auth/login`, { email, password })
            .then(({ data }) => {
              // console.log("data", data);
              const { token } = data;
              setAuthToken(token);
              // reload the page
              window.location.href = "/";
            });
        },
        logOut: () => {
          setAuthToken("");
          // reload the page
          window.location.href = "/";
        },
        register: ({ email, password, name }) => {
          return axios
            .post(`${endPoint}/auth/register`, { email, password, name })
            .then(({ data }) => {
              console.log("data", data);
            });
        },
        resetLogin: () => { },
      }}
    >
      {children}
    </FirebaseContext.Provider>
  );
};
