import { Card, Layout, Page } from '@shopify/polaris'
import React, { useCallback, useEffect, useState } from 'react'

import Alerts from '../EventPage/Alerts'
import { DetailsCard } from '../EventPage/DetailsCard'
import { DynamicMinPriceChart } from './DynamicMinPrice'
import EventPageChart from '../EventPage/EventPageChart'
import { FaTrainSubway } from 'react-icons/fa6'
import { FaWrench } from 'react-icons/fa'
import LiveChart from './LiveChart'
import LivenessMonitor from './LivenessMonitor'
import ObjectViewer from './ObjectViewer'
import { PriceLevelsChart } from './PriceLevels'
import { PrimaryStatsCard } from '../EventPage/PrimaryStatsCard'
import { SectionGrouperCard } from '../EventPage/SharedComponents'
import VividSection from '../EventPage/VividSection'
import _ from 'lodash'
import { apiGet } from '../../../Api'
import moment from 'moment'
import { processOgChartData } from './ChartDataProcessor'
import { useLocation } from 'react-router-dom'
/** @format */
import { useParams } from 'react-router-dom'

const LiveEventPage = () => {
    const { eventID } = useParams()
    const [eventData, setEventData] = useState(null)
    const [eventDataHeader, setEventDataHeader] = useState({})
    const [eventDataVenue, setEventDataVenue] = useState({})
    const [inventoryData, setInventoryData] = useState([])

    const [isDebug, setIsDebug] = useState(false)

    const location = useLocation()

    const [liveChartRawData, setLiveChartRawData] = useState()

    // Section Grouping
    const [selectedStrat, setSelectedStrat] = useState()
    const [sectionGroupMapping, setSectionGroupMapping] = useState({})
    const [isGroupListingEnabled, setIsGroupListingEnabled] = useState(false)
    const [sectionGroupingStrats, setSectionGroupingStrats] = useState([])

    // OG Chart Data
    const [ogChartData, setOgChartData] = useState({})

    const isReady =
        Object.keys(eventDataHeader).length &&
        Object.keys(eventDataVenue).length &&
        //Object.keys(eventDataInventory).length && // some events dont have this
        //liveChartRawData && // some events dont have live data
        selectedStrat &&
        sectionGroupMapping

    console.log('edh', !!Object.keys(eventDataHeader).length)

    console.log('edv', !!Object.keys(eventDataVenue).length)

    console.log('selstrat', !!selectedStrat)

    console.log('sgm', !!sectionGroupMapping)

    console.log('xxxxxxxxxxxx')

    const isLoading = !isReady

    useEffect(() => {
        setEventData({
            header: eventDataHeader,
            venue: eventDataVenue,
            inventory: inventoryData
        })
    }, [eventDataHeader, inventoryData, eventDataVenue])

    useEffect(() => {
        // INITIALIZE
        async function load() {
            let [getStrategiesResult, getStrategyResult] = await Promise.all([
                apiGet('/sectionGroups/getStrategies'),
                apiGet('/sectionGroups/getStrategy', { eventID })
            ])

            let strats = getStrategiesResult.map((r) => ({
                label: r,
                value: r
            }))
            setSectionGroupingStrats(strats)

            // Default strategy is set by the server
            // if (getStrategyResult === '') { getStrategyResult = strats[0].label }

            setSelectedStrat(getStrategyResult)
        }

        load()
    }, [eventID])

    const setup = useCallback(
        async (endpoint, setter) => {
            console.log('debug setter requested', endpoint)
            let data = await apiGet('/fastEvent/' + endpoint, {
                eventID
            })

            console.log('debug setter received', endpoint, data)

            setter(data)
        },
        [eventID]
    )

    useEffect(() => {
        setup('getEventLiveInventory', setLiveChartRawData)
        setup('getEventDataHeader', setEventDataHeader)
        setup('getEventDataVenue', setEventDataVenue)
        setup('getEventDataInventory', setInventoryData)
    }, [eventID, setup])

    useEffect(() => {
        const _ogChartData = processOgChartData(
            inventoryData,
            sectionGroupMapping
        )
        setOgChartData(_ogChartData)
    }, [inventoryData, sectionGroupMapping])

    const layoutControlView = (
        <div
            style={{
                position: 'absolute',
                top: 0,
                left: 0,
                padding: 8,
                paddingLeft: 20,
                width: '100%'
            }}
        >
            <a
                href={eventDataHeader.event_url_link}
                rel="noreferrer"
                target="_blank"
            >
                <div className={'layoutButton'} style={{ marginLeft: 4 }}>
                    <img
                        src={'/images/tmLogo.svg'}
                        alt="Ticketmaster Logo"
                        style={{ height: '15px', opacity: 0.8 }}
                    />
                </div>
            </a>

            <a
                href={location.pathname.replace('/eventLive/', '/eventTrain/')}
                rel="noreferrer"
                target="_blank"
            >
                <div
                    className={'layoutButton'}
                    style={{ marginLeft: 4, color: 'hsl(220, 0%, 25%)' }}
                >
                    <FaTrainSubway />
                </div>
            </a>

            <div
                className={'layoutButton'}
                onClick={() => {
                    setIsDebug(!isDebug)
                }}
                style={{
                    marginLeft: 4,
                    background: isDebug ? 'hsl(0, 0%, 84%)' : 'none'
                }}
            >
                <FaWrench />
            </div>

            <span style={{ marginLeft: '28px', opacity: 0.25 }}>
                {eventDataHeader.tm_id}
            </span>
        </div>
    )
    const date = moment(eventDataHeader.event_date_utc).format('YYYY-MM-DD')
    const pageTitle = `Fast Event: ${eventDataHeader.name || ''} / ${
        eventDataVenue.name || ''
    } / ${date}`

    const primaryChart = isReady && (
        <EventPageChart
            eventData={eventData}
            title={'Primary Sales Data'}
            chartData={ogChartData.primary}
        />
    )

    const resaleChart = isReady && (
        <EventPageChart
            eventData={eventData}
            title={'Resale Sales Data'}
            chartData={ogChartData.resale}
        />
    )

    const liveChart = isReady && (
        <LiveChart
            eventDataHeader={eventDataHeader}
            liveChartRawData={liveChartRawData}
            sectionGroupMapping={sectionGroupMapping}
        />
    )

    const primaryPriceLevelChart = PriceLevelsChart({
        title: 'Primary Price Levels',
        eventData: eventData,
        sectionGroupMapping: sectionGroupMapping,
        inventoryType: 'primary'
    })

    const resalePriceLevelChart = PriceLevelsChart({
        title: 'Resale Price Levels',
        eventData: eventData,
        sectionGroupMapping: sectionGroupMapping,
        inventoryType: 'resale'
    })

    const dynamicMinPriceChart = DynamicMinPriceChart({
        title: 'Primary Price Levels',
        eventData: eventData,
        sectionGroupMapping: sectionGroupMapping,
        inventoryType: 'resale'
    })

    const vividLinkedDataCard = (
        <Card sectioned title="Vivid Events">
            <VividSection tmId={eventID} eventData={eventData} />
        </Card>
    )

    const primaryStatsCard = eventData && (
        <PrimaryStatsCard eventID={eventID} eventData={eventData} />
    )

    const mainContent = isLoading ? (
        <div>Loading</div>
    ) : (
        <div style={{ margin: '-2px' }}>
            {layoutControlView}
            {primaryChart}
            {resaleChart}
            {liveChart}
            {primaryPriceLevelChart}
            {resalePriceLevelChart}
            {dynamicMinPriceChart}
            {vividLinkedDataCard}

            <hr style={{ margin: '20px 0px' }} />
        </div>
    )

    const debugViewContent = (
        <>
            <ObjectViewer name="eventData" data={eventData} />
            <ObjectViewer name="liveChartRawData" data={liveChartRawData} />
            <ObjectViewer name="ogChartData" data={ogChartData} />
        </>
    )

    const debugView = isDebug ? (
        <Layout>
            <Layout.Section secondary>
                <LivenessMonitor eventID={eventID} />
            </Layout.Section>
            <Layout.Section>
                <Card title="Debug">
                    <div
                        style={{
                            border: '1px solid hsl(0, 0%, 70%)',
                            padding: 2,
                            margin: 12,
                            marginBottom: 24,
                            borderRadius: 4
                        }}
                    >
                        {debugViewContent}
                    </div>
                </Card>
            </Layout.Section>
        </Layout>
    ) : (
        ''
    )

    const sectionGrouper = SectionGrouperCard({
        eventID,
        selectedStrat,
        setSelectedStrat,
        setSectionGroupMapping,
        sectionGroupingStrats,
        isGroupListingEnabled,
        setIsGroupListingEnabled,
        sectionGroupMapping
    })

    const detailsCard = <DetailsCard eventData={eventData} />

    const alertsCard = (
        <Card title="Alerts" sectioned>
            <Alerts eventID={eventID} zoningStrategy={selectedStrat} />
        </Card>
    )

    const firstRow = (
        <Layout>
            <Layout.Section oneThird>{detailsCard}</Layout.Section>

            <Layout.Section oneThird>{alertsCard}</Layout.Section>

            <Layout.Section oneThird>
                {primaryStatsCard}
                {sectionGrouper}
            </Layout.Section>
        </Layout>
    )

    return (
        <div style={{ marginTop: 22 }}>
            <Page title={pageTitle} fullWidth>
                {debugView}
                <Layout>
                    {layoutControlView}

                    <Layout.Section>{firstRow}</Layout.Section>
                    <Layout.Section>{mainContent}</Layout.Section>
                </Layout>
            </Page>
        </div>
    )
}
export default LiveEventPage
