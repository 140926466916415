import { useParams } from 'react-router-dom'
import { useFirebaseContext } from '../../../FirebaseContext'
import {
    Button,
    Card,
    Checkbox,
    Layout,
    Page,
    Select,
    Spinner,
    hslToRgb
} from '@shopify/polaris'
//import { useFirebaseContext } from '../../../FirebaseContext'
import { FaTrainSubway } from 'react-icons/fa6'
import React, { useEffect, useState, useCallback } from 'react'
import { useLocation } from 'react-router-dom'
import moment from 'moment'
import { apiGet, apiPost } from '../../../Api'
import { JSONTree } from 'react-json-tree'

const ObjectViewer = ({ name, data }) => {
    name = name || 'Object'
    const [isExpanded, setIsExpanded] = useState(true)

    return (
        <div
            style={{
                border: '1px solid hsl(0, 0%, 70%)',
                padding: '2px 12px',
                margin: 6,
                borderRadius: 4,
                maxHeight: 400,
                overflowY: 'scroll'
            }}
        >
            <div
                className="clickable"
                onClick={() => {
                    //setIsExpanded(!isExpanded)
                }}
            >
                {isExpanded ? (
                    <JSONTree
                        data={data}
                        theme="bright"
                        keyPath={[name]}
                        invertTheme={true}
                        shouldExpandNodeInitially={() => false}
                    />
                ) : (
                    ''
                )}
            </div>
        </div>
    )
}

export default ObjectViewer
