/*
    Sample IDs
    TM: vvG1GZ9t9eDS6B ==> 03005E95C2EF31FA 
    VI: 4311802


    https://www.ticketmaster.com/event/Z7r9jZ1Ad0pZZ
    https://www.vividseats.com/darlingside-tickets-northampton-academy-of-music-theatre-12-15-2023--concerts-rock/production/4422382

    tm Z7r9jZ1Ad0pZZ
    vi 4422382

*/
import { apiGet, apiPost } from '../../../Api'
import _ from 'lodash'
import moment from 'moment'
import {
    Card,
    Page,
    EmptyState,
    Spinner,
    Form,
    FormLayout,
    TextField
} from '@shopify/polaris'
import { useEffect, useState } from 'react'
import styles from './style.module.css'

const EventView = ({ id, name, url, source }) => {
    return (
        <div className={styles.rectangled}>
            <div>ID: {id}</div>
            <div>Name: {name}</div>
            <div>URL: {url}</div>
        </div>
    )
}

const LinkView = ({
    id,
    venue_id,
    tm_ids_csv,
    vivid_ids_csv,
    handleVerifyEvents
}) => {
    let source = venue_id === 'custom' ? 'Custom Linker' : 'Standard Linker'
    //return JSON.stringify(lk) + (

    const handleDeleteLink = async (id) => {
        await apiPost('/linker/deleteLinkEvents', { ids: [id] })
        handleVerifyEvents()
    }

    return (
        <div key={id} className={styles.rectangled}>
            <div>Ticketmaster ID: {tm_ids_csv}</div>
            <div>Vivid ID: {vivid_ids_csv}</div>
            <div>Source: {source}</div>
            <div style={{ marginTop: 12 }}>
                <button
                    className={styles.big}
                    onClick={() => handleDeleteLink(id)}
                >
                    Delete Link
                </button>
            </div>
        </div>
    )
}

const CustomLinker = () => {
    let [ticketmasterId, setTicketmasterId] = useState('Z7r9jZ1Ad0pZZ')
    let [vividId, setVividId] = useState('4422382')

    let [tmEvents, setTmEvents] = useState([])
    let [vividEvents, setVividEvents] = useState([])
    let [links, setLinks] = useState([])

    let [allCustomLinks, setAllCustomLinks] = useState([])

    let downloadAllCustomLinks = async () => {
        let allCustomLinks = await apiGet('/linker/getAllCustomLinks')
        setAllCustomLinks(allCustomLinks)
    }

    useEffect(() => {
        downloadAllCustomLinks()
    }, [])

    const handleVerifyEvents = () => {
        let doHandle = async () => {
            let verifyResult = await apiGet('/linker/verifyCustomLink', {
                ticketmasterId,
                vividId
            })

            console.log('response', verifyResult)
            let { tmEvents, vividEvents, links } = verifyResult
            setTmEvents(tmEvents)
            setVividEvents(vividEvents)
            setLinks(links)
        }

        doHandle()
        downloadAllCustomLinks()
    }

    const handleCreateLink = async () => {

        // Event linker deterministically generates link id as
        // let id = _.chain(eventGroup).map((g) => g.source + '_' + g.id)
        //                  .sortBy().value().join(',').substring(0, 254)
        // So, manually this would be:
        let id = 'tm_' + ticketmasterId + ',vi_' + vividId

        let venue_id = 'custom'
        let tm_ids_csv = ticketmasterId
        let vivid_ids_csv = vividId
        await apiPost('/linker/createLinkManyEvents', [
            {
                id,
                venue_id,
                tm_ids_csv,
                vivid_ids_csv
            }
        ])
        handleVerifyEvents()
    }

    let tmEventsView =
        tmEvents.length === 0 ? (
            <h3>There are no Ticketmaster events associated with this ID</h3>
        ) : (
            tmEvents.map((e) => {
                return <EventView key={e.id} {...e} />
            })
        )

    let vividEventsView =
        vividEvents.length === 0 ? (
            <h3>There are no Vivid events associated with this ID</h3>
        ) : (
            vividEvents.map((e) => {
                return <EventView key={e.id} {...e} />
            })
        )

    let linksView =
        links.length === 0 ? (
            <h3>There are no links associated with any of these events</h3>
        ) : (
            links.map((lk) => (
                <LinkView
                    key={lk.id}
                    handleVerifyEvents={handleVerifyEvents}
                    {...lk}
                />
            ))
        )

    const allCustomLinksView = allCustomLinks.map((lk) => (
        <LinkView key={lk.id} handleVerifyEvents={handleVerifyEvents} {...lk} />
    ))

    return (
        <Page title="Custom Linker" fullWidth className={styles.design1}>
            <p>Use this tool to force link any 2 events, by ID</p>
            <p>&nbsp;</p>
            <p>
                Make sure to verify and optionally delete any existing links
                associated with those events here first
            </p>
            <p>&nbsp;</p>

            <Card
                sectioned
                primaryFooterAction={{
                    content: 'Create Link',
                    onAction: () => {
                        handleCreateLink()
                    }
                }}
                secondaryFooterActions={[
                    {
                        content: 'Verify',
                        onAction: () => {
                            handleVerifyEvents()
                        }
                    }
                ]}
            >
                <FormLayout>
                    <TextField
                        label="Ticketmaster ID"
                        value={ticketmasterId}
                        onChange={(value) => {
                            setTicketmasterId(value)
                        }}
                        autoComplete="off"
                    />

                    <TextField
                        label="Vivid ID"
                        value={vividId}
                        onChange={(value) => {
                            setVividId(value)
                        }}
                        autoComplete="off"
                    />
                </FormLayout>
            </Card>

            <Card sectioned>
                <Card.Section>
                    <div>
                        <h1>Ticketmaster Event</h1>
                        {tmEventsView}
                    </div>
                </Card.Section>
                <Card.Section>
                    <div>
                        <h1>Vivid Event</h1>
                        {vividEventsView}
                    </div>
                </Card.Section>
                <Card.Section>
                    <div>
                        <h1>Event Link</h1>
                        {linksView}
                    </div>
                </Card.Section>
            </Card>
            <hr style={{ marginTop: 24, marginBottom: 24 }} />
            <Card sectioned>
                <Card.Section>
                    <h1>Dump All Custom Links</h1>
                    {allCustomLinksView}
                </Card.Section>
            </Card>
            <p>&nbsp;</p>
            <p>&nbsp;</p>
        </Page>
    )
}

export default CustomLinker
