/** @format */

import {
    Card,
    Form,
    FormLayout,
    Icon,
    Button,
    Select,
    TextField,
    Spinner
} from '@shopify/polaris'
import { fields, operatorLabels } from './fieldConsts'
import {
    ChevronUpMinor,
    ChevronDownMinor,
    DeleteMinor
} from '@shopify/polaris-icons'
import { useState } from 'react'
import AutomateDrawer from './AutomateDrawer'

const SearchSection = ({
    searchParams,
    setSearchParams,
    searchRunning,
    setSearchRunning,
    automateObj
}) => {
    const [automateDrawerStatus, setAutomateDrawerStatus] = useState(false)

    const runSearch  = () => {
        setSearchRunning(!searchRunning)
    }
    
    return (
        <>
            {searchRunning ? <Spinner /> : ''}
            <Card
                sectioned
                primaryFooterAction={{
                    content: searchRunning ? 'Cancel' : 'Search',
                    onAction: runSearch,
                    //disabled: searchRunning
                }}
                secondaryFooterActions={[
                    {
                        content: 'Bookmarks',
                        onAction: () =>
                            setAutomateDrawerStatus(!automateDrawerStatus)
                    }
                ]}
                secondaryFooterActionsDisclosureText="More actions"
            >
                <Form onSubmit={runSearch}>
                    <FormLayout>
                        {Object.keys(searchParams).map((index) => (
                            <FieldGroup
                                // index={parseInt(index)} ?
                                index={index}
                                key={index}
                                searchParams={searchParams}
                                setSearchParams={setSearchParams}
                            />
                        ))}
                    </FormLayout>
                </Form>

                <FieldGroupAdd
                    searchParams={searchParams}
                    setSearchParams={setSearchParams}
                />
            </Card>
            <AutomateDrawer
                automateDrawerStatus={automateDrawerStatus}
                searchParams={searchParams}
                setSearchParams={setSearchParams}
                setAutomateDrawerStatus={setAutomateDrawerStatus}
            />
        </>
    )
}

const FieldGroupAdd = ({ searchParams, setSearchParams }) => {
    const options = Object.keys(fields)
        .map((v) => {
            return {
                label: fields[v].description,
                value: v
            }
        })
        .filter((v) => {
            // remove if the field is already in searchParams
            return !searchParams.some((v2) => v2.field === v.value)
        })
    if (options.length === 0) {
        return null
    }

    options.unshift({
        label: 'Add a field',
        value: '',
        disabled: true
    })

    const addField = (v) => {
        console.log('add field', v)
        setSearchParams([
            ...searchParams,
            {
                field: v,
                operator: fields[v].operators[0],
                value: '',
                defaultOrderBy: fields[v].defaultOrderBy
            }
        ])
    }

    const addAllFields = () => {
        let _searchParams = Object.keys(fields).map(v => {
            return {
                field: v,
                operator: fields[v].operators[0],
                value: '',
                defaultOrderBy: fields[v].defaultOrderBy
            }
        })

        setSearchParams(_searchParams)
    }

    return (
        <div style={{ display: 'flex', alignItems: 'center', marginTop: 28, marginLeft: 12 }}>
            <Select options={options} onChange={addField} />
            <span style={{ marginLeft: 12 }}>
            <Button onClick={addAllFields} >Add all fields</Button>
            </span>
        </div>
    )
}

const FieldGroupSelectOpperator = ({
    index,
    searchParams,
    setSearchParams
}) => {
    const field = searchParams[index].field

    const fieldDetails = fields[field]
    const { operators } = fieldDetails

    return (
        <Select
            options={operators.map((v) => {
                return {
                    label: operatorLabels[v].name,
                    value: v
                }
            })}
            style={{ width: '100px' }}
            value={searchParams[index].operator}
            onChange={(v) => {
                index = parseInt(index)
                setSearchParams([
                    ...searchParams.slice(0, index),
                    {
                        ...searchParams[index],
                        operator: v
                    },
                    ...searchParams.slice(index + 1)
                ])
            }}
        />
    )
}

const FieldGroupValue = ({ index, searchParams, setSearchParams }) => {
    const field = searchParams[index].field
    const value = searchParams[index].value

    const fieldDetails = fields[field]
    const { type } = fieldDetails

    const updateValue = (v) => {
        const newSearchParams = [...searchParams]
        newSearchParams[index].value = v
        setSearchParams(newSearchParams)
    }

    if (type === 'string') {
        return <TextField type="text" value={value} onChange={updateValue} />
    } else if (type === 'number') {
        return <TextField type="number" value={value} onChange={updateValue} />
    } else if (type === 'dateRelative') {
        const options = fieldDetails.options
        return <Select options={options} value={value} onChange={updateValue} />
    } else if (type === 'timestamp') {
        return <TextField type="date" value={value} onChange={updateValue} />
    } else if (type === 'boolean') {
        return (
            <Select
                options={[
                    { label: 'True', value: 'true' },
                    { label: 'False', value: 'false' }
                ]}
                value={value}
                onChange={updateValue}
            />
        )
    } else if (type === 'percentage') {
        return (
            <TextField
                type="number"
                value={value}
                // value={parseInt(value * 100)}
                onChange={(e) => {
                    updateValue(e)
                    return
                }}
                suffix={'%'}
            />
        )
    }
}

const FieldGroupActions = ({ index, searchParams, setSearchParams }) => {
    // move up in the searchParams array
    const moveUp = () => {
        index = parseInt(index)
        if (index === 0) {
            return
        } else {
            const newSearchParams = [...searchParams]
            const temp = newSearchParams[index - 1]
            newSearchParams[index - 1] = newSearchParams[index]
            newSearchParams[index] = temp
            setSearchParams(newSearchParams)
        }
    }

    const moveDown = () => {
        index = parseInt(index)
        if (index === searchParams.length - 1) {
            return
        } else {
            const newSearchParams = [...searchParams]
            const temp = newSearchParams[index + 1]
            newSearchParams[index + 1] = newSearchParams[index]
            newSearchParams[index] = temp
            setSearchParams(newSearchParams)
        }
    }

    // remove from the searchParams array
    const remove = () => {
        if (searchParams.length === 1) {
            return
        } else {
            const newSearchParams = [...searchParams]
            newSearchParams.splice(index, 1)
            setSearchParams(newSearchParams)
        }
    }

    return (
        <div
            style={{
                display: 'flex',
                margin: [0, 0, 0, 0],
                padding: [0, 0, 0, 0],
                width: '100px'
            }}
        >
            <div
                onClick={moveUp}
                style={{ visibility: index === '0' ? 'hidden' : 'visible' }}
            >
                <Icon source={ChevronUpMinor} color={'base'} />
            </div>
            <div
                onClick={moveDown}
                style={{
                    visibility:
                        index === `${searchParams.length - 1}`
                            ? 'hidden'
                            : 'visible'
                }}
            >
                <Icon source={ChevronDownMinor} color="base" />
            </div>
            <div
                onClick={remove}
                style={{
                    visibility: searchParams.length === 1 ? 'hidden' : 'visible'
                }}
            >
                <Icon source={DeleteMinor} color="base" />
            </div>
        </div>
    )
}

const FieldGroupOrderBy = ({ index, searchParams, setSearchParams }) => {
    const field = searchParams[index].field
    const value = searchParams[index].orderBy || fields[field].defaultOrderBy

    const fieldDetails = fields[field]
    const { type } = fieldDetails

    const updateValue = (v) => {
        index = parseInt(index)
        setSearchParams([
            ...searchParams.slice(0, index),
            {
                ...searchParams[index],
                orderBy: v
            },
            ...searchParams.slice(index + 1)
        ])
    }
    return (
        <>
            <Select
                options={[
                    { label: 'Ascending', value: 'asc' },
                    { label: 'Descending', value: 'desc' }
                ]}
                value={value}
                onChange={updateValue}
            />
        </>
    )
}

const FieldGroup = ({ index, searchParams, setSearchParams }) => {
    const field = searchParams[index].field
    // console.log(field);
    const fieldDetails = fields[field]
    const { description } = fieldDetails

    return (
        <div>
            <FormLayout.Group condensed style={{ background: 'green' }}>
                <p style={{ fontWeight: 'bold' }}>{description}</p>
                <FieldGroupSelectOpperator
                    index={index}
                    searchParams={searchParams}
                    setSearchParams={setSearchParams}
                />

                <FieldGroupValue
                    index={index}
                    searchParams={searchParams}
                    setSearchParams={setSearchParams}
                />
                <FieldGroupOrderBy
                    index={index}
                    searchParams={searchParams}
                    setSearchParams={setSearchParams}
                />
                <FieldGroupActions
                    index={index}
                    searchParams={searchParams}
                    setSearchParams={setSearchParams}
                />
            </FormLayout.Group>
        </div>
    )
}

export default SearchSection
