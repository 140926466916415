/** @format */

const defaultSearch = [
    {
        field: 'tm_event_eventDateRelative',
        weight: 1,
        operator: 'gte',
        value: 'today',
        orderBy: 'asc'
    },
    {
        field: 'tm_event_name',
        weight: 0,
        operator: 'like',
        value: '',
        orderBy: 'asc'
    }
]

const operatorLabels = {
    like: { name: 'Contains', sql: 'LIKE' },
    equals: { name: 'Equals', sql: '=' },
    gte: { name: 'Greater Than or Equal To', sql: '>=' },
    lte: { name: 'Less Than or Equal To', sql: '<=' },
    gt: { name: 'Greater Than', sql: '>' },
    lt: { name: 'Less Than', sql: '<' }
}

const materializedViewsPeriods = {
    // "3hour": { name: "3 Hour", intervalInHours: 3 },
    // "6hour": { name: "6 Hour", intervalInHours: 6 },
    '12hour': { name: 'TMv1 - 12 Hour', intervalInHours: 12 },
    '1day': { name: 'TMv1 - 1 Day', intervalInHours: 24 },
    '3day': { name: 'TMv1 - 3 Day', intervalInHours: 24 * 3 },
    '7day': { name: 'TMv1 - 7 Day', intervalInHours: 24 * 7 },
    '14day': { name: 'TMv1 - 14 Day', intervalInHours: 24 * 14 },
    '30day': { name: 'TMv1 - 30 Day', intervalInHours: 24 * 30 },
    '60day': { name: 'TMv1 - 60 Day', intervalInHours: 24 * 60 }
}

const socketMaterializedViewsPeriods = {
    '1hour': { name: '1 Hour', intervalInHours: 1 },
    '2hour': { name: '2 Hour', intervalInHours: 2 },
    '3hour': { name: '3 Hour', intervalInHours: 3 },
    '6hour': { name: '6 Hour', intervalInHours: 6 },
    '12hour': { name: '12 Hour', intervalInHours: 12 },
    '1day': { name: '1 Day', intervalInHours: 24 },
    '3day': { name: '3 Day', intervalInHours: 24 * 3 },
    '7day': { name: '7 Day', intervalInHours: 24 * 7 },
    '14day': { name: '14 Day', intervalInHours: 24 * 14 },
    '30day': { name: '30 Day', intervalInHours: 24 * 30 }
}

const materializedViewsFields = {
    latest_primary_count: { name: 'Latest Primary Count' },
    period_primary_count: { name: 'Period Primary Count' },
    change_in_primary_count: { name: 'Change in Primary Count' },
    percentage_change_in_primary_count: {
        name: 'Percentage Change in Primary Count',
        type: 'percentage'
    },
    latest_primary_price_min: { name: 'Latest Primary Min Price' },
    period_primary_price_min: { name: 'Period Primary Min Price' },
    change_in_primary_price_min: { name: 'Change in Primary Min Price' },
    percentage_change_in_primary_price_min: {
        name: 'Percentage Change in Primary Min Price',
        type: 'percentage'
    },
    latest_primary_price_max: { name: 'Latest Primary Max Price' },
    period_primary_price_max: { name: 'Period Primary Max Price' },
    change_in_primary_price_max: { name: 'Change in Primary Max Price' },
    percentage_change_in_primary_price_max: {
        name: 'Percentage Change in Primary Max Price',
        type: 'percentage'
    },
    latest_resale_count: { name: 'Latest Resale Count' },
    period_resale_count: { name: 'Period Resale Count' },
    change_in_resale_count: { name: 'Change in Resale Count' },
    percentage_change_in_resale_count: {
        name: 'Percentage Change in Resale Count',
        type: 'percentage'
    },
    latest_resale_price_min: { name: 'Latest Resale Min Price' },
    period_resale_price_min: { name: 'Period Resale Min Price' },
    change_in_resale_price_min: { name: 'Change in Resale Min Price' },
    percentage_change_in_resale_price_min: {
        name: 'Percentage Change in Resale Min Price',
        type: 'percentage'
    },
    latest_resale_price_max: { name: 'Latest Resale Max Price' },
    period_resale_price_max: { name: 'Period Resale Max Price' },
    percentage_change_in_resale_price_max: {
        name: 'Percentage Change in Resale Max Price',
        type: 'percentage'
    },
    latest_primary_weighted_avg_price: {
        name: 'Latest Primary Weighted Avg Price'
    },
    period_primary_weighted_avg_price: {
        name: 'Period Primary Weighted Avg Price'
    },
    change_in_primary_weighted_avg_price: {
        name: 'Change in Primary Weighted Avg Price'
    },
    percentage_change_in_primary_weighted_avg_price: {
        name: 'Percentage Change in Primary Weighted Avg Price',
        type: 'percentage'
    },
    latest_resale_weighted_avg_price: {
        name: 'Latest Resale Weighted Avg Price'
    },
    period_resale_weighted_avg_price: {
        name: 'Period Resale Weighted Avg Price'
    },
    change_in_resale_weighted_avg_price: {
        name: 'Change in Resale Weighted Avg Price'
    },
    percentage_change_in_resale_weighted_avg_price: {
        name: 'Percentage Change in Resale Weighted Avg Price'
    },
    demand_intensity: {
        name: 'Demand Intensity'
    },
    primary_rate: {
        name: 'Primary Rate'
    }

    /// primary_interval_count: {
    /// 	name: "Primary Interval Count",
    /// },
    /// primary_calcs_change_count: {
    /// 	name: "Primary Change Count",
    /// },
    /// primary_calcs_change_percentage: {
    /// 	name: "Primary Change Percentage",
    /// },
    /// primary_calcs_latest_minprice: {
    /// 	name: "Primary Latest Min Price",
    /// },
    /// primary_calcs_interval_minprice: {
    /// 	name: "Primary Interval Min Price",
    /// },
    /// primary_calcs_change_minprice: {
    /// 	name: "Primary Change Min Price",
    /// },
    /// primary_calcs_change_minprice_percentage: {
    /// 	name: "Primary Change Min Price Percentage",
    /// 	type: "percentage",
    /// },
    /// primary_calcs_latest_maxprice: {
    /// 	name: "Primary Latest Max Price",
    /// },
    /// primary_calcs_interval_maxprice: {
    /// 	name: "Primary Interval Max Price",
    /// },
    /// primary_calcs_change_maxprice: {
    /// 	name: "Primary Change Max Price",
    /// },
    /// primary_calcs_change_maxprice_percentage: {
    /// 	name: "Primary Change Max Price Percentage",
    /// 	type: "percentage",
    /// },
    /// resale_calcs_latest_count: {
    /// 	name: "Resale Latest Count",
    /// },
    /// resale_calcs_interval_count: {
    /// 	name: "Resale Interval Count",
    /// },
    /// resale_calcs_change_count: {
    /// 	name: "Resale Change Count",
    /// },
    /// resale_calcs_change_percentage: {
    /// 	name: "Resale Change Percentage",
    /// },
    /// resale_calcs_latest_minprice: {
    /// 	name: "Resale Latest Min Price",
    /// },
    /// resale_calcs_interval_minprice: {
    /// 	name: "Resale Interval Min Price",
    /// },
    /// resale_calcs_change_minprice: {
    /// 	name: "Resale Change Min Price",
    /// },
    /// resale_calcs_change_minprice_percentage: {
    /// 	name: "Resale Change Min Price Percentage",
    /// 	type: "percentage",
    /// },
    /// resale_calcs_latest_maxprice: {
    /// 	name: "Resale Latest Max Price",
    /// },
    /// resale_calcs_interval_maxprice: {
    /// 	name: "Resale Interval Max Price",
    /// },
    /// resale_calcs_change_maxprice: {
    /// 	name: "Resale Change Max Price",
    /// },
    /// resale_calcs_change_maxprice_percentage: {
    /// 	name: "Resale Change Max Price Percentage",
    /// 	type: "percentage",
    /// },
}

const socketMaterializedViewsFields = {
    latest_count: { name: 'Latest Count' },
    period_count: { name: 'Period Count' },
    change_in_count: { name: 'Change in Count' },
    percent_change_in_count: {
        name: 'Percentage Change in Count',
        type: 'percentage'
    }
}

// TODO Vince -- merge the two sections of materializedViews into a shared function if the replica below works.
const materializedViews = {}
Object.keys(materializedViewsPeriods).forEach((period) => {
    Object.keys(materializedViewsFields).forEach((field) => {
        const periodInterval = String(
            materializedViewsPeriods[period].intervalInHours
        ).padStart(4, '0')
        const tableName = `tm_inventory_02_compare_${periodInterval}_view`
        const fieldName = `${tableName}.${field}`
        const fieldKey = `${tableName}_${field}`
        const fieldDescription = `${materializedViewsPeriods[period].name} ${materializedViewsFields[field].name}`
        const fieldType = materializedViewsFields[field].type || 'number'
        const fieldOperators = ['gte', 'lte', 'gt', 'lt']
        const fieldDefaultOrderBy = 'asc'
        materializedViews[fieldKey] = {
            fieldName,
            table: tableName,
            type: fieldType,
            description: fieldDescription,
            operators: fieldOperators,
            defaultOrderBy: fieldDefaultOrderBy,
            tableJoin: {
                on: [`${tableName}.tm_event_id`, 'tm_event.id']
            }
        }
    })
})

const socketMaterializedViews = {}
Object.keys(socketMaterializedViewsPeriods).forEach((period) => {
    Object.keys(socketMaterializedViewsFields).forEach((field) => {
        const periodInterval = String(
            socketMaterializedViewsPeriods[period].intervalInHours
        ).padStart(4, '0')
        const tableName = `tm_socket_inventory_01_compare_${periodInterval}_view`
        const fieldName = `${tableName}.${field}`
        const fieldKey = `${tableName}_${field}`
        const fieldDescription = `${socketMaterializedViewsPeriods[period].name} ${socketMaterializedViewsFields[field].name}`
        const fieldType = socketMaterializedViewsFields[field].type || 'number'
        const fieldOperators = ['gte', 'lte', 'gt', 'lt']
        const fieldDefaultOrderBy = 'asc'
        socketMaterializedViews[fieldKey] = {
            fieldName,
            table: tableName,
            type: fieldType,
            description: fieldDescription,
            operators: fieldOperators,
            defaultOrderBy: fieldDefaultOrderBy,
            tableJoin: {
                on: [`${tableName}.tm_event_id`, 'tm_event.id']
            }
        }
    })
})

const socketMaterializedViewsPeriods2 = {
    '2hour': { name: '2 Hour', intervalInHours: 2 },
    '3hour': { name: '3 Hour', intervalInHours: 3 },
    '3hour': { name: '4 Hour', intervalInHours: 4 },
    '6hour': { name: '6 Hour', intervalInHours: 6 },
    '6hour': { name: '8 Hour', intervalInHours: 8 },
    '12hour': { name: '12 Hour', intervalInHours: 12 },
    '12hour': { name: '18 Hour', intervalInHours: 18 },
    '1day': { name: '1 Day', intervalInHours: 24 }
}

const socketMaterializedViewsFields2 = {
    latest_count: { name: 'Latest Count' },
    period_count: { name: 'Period Count' },
    count_change: { name: 'Change in Count' },
    percent_change: {
        name: 'Percentage Change in Count',
        type: 'percentage'
    }
}
const socketMaterializedViews2 = {}
Object.keys(socketMaterializedViewsPeriods2).forEach((period) => {
    Object.keys(socketMaterializedViewsFields2).forEach((field) => {
        const periodInterval = String(
            socketMaterializedViewsPeriods2[period].intervalInHours
        ).padStart(4, '0')
        const tableName = `tm_socket_inventory_02_compare_${periodInterval}_mv`
        const fieldName = `${tableName}.${field}`
        const fieldKey = `${tableName}_${field}`
        const fieldDescription = `TMv2 - ${socketMaterializedViewsPeriods2[period].name} ${socketMaterializedViewsFields2[field].name}`
        const fieldType = socketMaterializedViewsFields2[field].type || 'number'
        const fieldOperators = ['gte', 'lte', 'gt', 'lt']
        const fieldDefaultOrderBy = 'asc'
        socketMaterializedViews2[fieldKey] = {
            fieldName,
            table: tableName,
            type: fieldType,
            description: fieldDescription,
            operators: fieldOperators,
            defaultOrderBy: fieldDefaultOrderBy,
            tableJoin: {
                on: [`${tableName}.tm_event_id`, 'tm_event.id']
            }
        }
    })
})

const fields = {
    tm_event_name: {
        fieldName: 'tm_event.name',
        table: 'tm_event',
        type: 'string',
        description: 'Event Name',
        operators: ['like', 'equals'],
        defaultOrderBy: 'asc'
    },
    tm_event_event_date_utc: {
        fieldName: 'tm_event.event_date_utc',
        table: 'tm_event',
        type: 'timestamp',
        description: 'Event Date UTC',
        operators: ['gte', 'lte', 'gt', 'lt'],
        defaultOrderBy: 'asc'
    },
    tm_event_eventDateRelative: {
        fieldName: 'tm_event.event_date_utc',
        type: 'dateRelative',
        description: 'Event Date UTC Relative',
        operators: ['gte', 'lte', 'gt', 'lt'],
        options: [
            {
                label: 'Today',
                value: 'today',
                intervalvalue: 0
            },
            {
                label: 'Tomorrow',
                value: 'tomorrow',
                intervalvalue: 24 * 1
            },
            {
                label: '+ 7 Days',
                value: '7_Days',
                intervalvalue: 24 * 7
            },
            {
                label: '+ 14 Days',
                value: '14_Days',
                intervalvalue: 24 * 14
            }
        ],
    },
    tm_presale_start_date_utc: {
        fieldName: 'tm_event.presale_start_date_utc',
        table: 'tm_event',
        type: 'timestamp',
        description: 'Presale Start Date UTC',
        operators: ['gte', 'lte', 'gt', 'lt'],
        defaultOrderBy: 'asc'
    },
    tm_public_sale_start_date_utc: {
        fieldName: 'tm_event.public_sale_start_date_utc',
        table: 'tm_event',
        type: 'timestamp',
        description: 'Public Sale Start Date UTC',
        operators: ['gte', 'lte', 'gt', 'lt'],
        defaultOrderBy: 'asc'
    },
    tm_venue_name: {
        fieldName: 'tm_venue.name',
        table: 'tm_venue',
        type: 'string',
        description: 'Venue Name',
        operators: ['like', 'equals'],
        defaultOrderBy: 'asc',
        metaFields: ['tm_venue.id as tm_venue_id'],
        tableJoin: { on: ['tm_venue.id', 'tm_event.venue_id'] }
    },
    tm_venue_country: {
        fieldName: 'tm_venue.country',
        table: 'tm_venue',
        type: 'string',
        description: 'Venue Country',
        operators: ['like', 'equals'],
        defaultOrderBy: 'asc',
        metaFields: ['tm_venue.id as tm_venue_id'],
        tableJoin: { on: ['tm_venue.id', 'tm_event.venue_id'] }
    },
    /// tm_genre_name: {
    /// 	fieldName: "tm_genre.name",
    /// 	table: "tm_genre",
    /// 	type: "string",
    /// 	description: "Genre Name - Not Working", // broken since starrocks
    /// 	operators: ["like", "equals"],
    /// 	defaultOrderBy: "asc",
    /// 	metaFields: ["tm_genre.id as tm_genre_id"],
    /// 	tableJoin: { on: ["tm_genre.id", "tm_event.genre_id"] },
    /// },
    /// tm_segment_name: {
    /// 	fieldName: "tm_segment.name",
    /// 	table: "tm_segment",
    /// 	type: "string",
    /// 	description: "Segment Name - Not Working", // broken since starrocks
    /// 	operators: ["like", "equals"],
    /// 	defaultOrderBy: "asc",
    /// 	metaFields: ["tm_segment.id as tm_segment_id"],
    /// 	tableJoin: { on: ["tm_segment.id", "tm_event.tm_segment_id"] },
    /// },
    ...socketMaterializedViews,
    ...socketMaterializedViews2,
    ...materializedViews,
    isPresalePeriod: {
        fieldName: 'tm_event.is_presale_period',
        table: 'tm_event',
        type: 'boolean',
        description: 'Is Presale Period',
        operators: ['equals'],
        defaultOrderBy: 'asc'
    }
}

// NOTE: DO NOT MODIFY THE NEXT LINE AT ALL! IT IS USED BY eventSearch.js
export { fields, operatorLabels, defaultSearch }