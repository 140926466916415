import 'ag-grid-community/styles/ag-grid.css'
import 'ag-grid-community/styles/ag-theme-alpine.css'

import { BsLayoutSidebarReverse, BsViewStacked } from 'react-icons/bs'
import { Card, Checkbox, Layout, Page, Spinner } from '@shopify/polaris'
import React, { useCallback, useEffect, useMemo, useState } from 'react'
import { Stack, TextField } from '@shopify/polaris'
import { useNavigate, useParams } from 'react-router-dom'

import { AgGridReact } from 'ag-grid-react'
import LineChart from './LineChart'
import _ from 'lodash'
import { apiGet } from '../../Api'
import axios from 'axios'
import moment from 'moment'
import { useCookies } from 'react-cookie'

/*
 [{
    "eventID": "602843",
    "fetched_at": "2024-11-07T21:02:28.547Z",
    "sectionLabel": "Booths",
    "sectionID": "3",
    "isGeneralAdmission": 0,
    "amount": 12,
    "price": 16300
}]
*/
function assembleInventoryData(eventHistory) {
    const groupedByDate = eventHistory.reduce((acc, entry) => {
        const timestamp = new Date(entry.fetched_at).getTime()
        if (!acc[timestamp]) {
            acc[timestamp] = []
        }
        acc[timestamp].push(entry)
        return acc
    }, {})

    const sortedDates = Object.keys(groupedByDate)
        .map(Number)
        .sort((a, b) => a - b)

    const sectionDataMap = {}
    const allSeriesData = []

    sortedDates.forEach((timestamp) => {
        const entries = groupedByDate[timestamp]
        const sectionAmounts = {}
        let totalForAllSeries = 0

        entries.forEach((entry) => {
            const { sectionLabel, amount } = entry

            if (!sectionAmounts[sectionLabel]) {
                sectionAmounts[sectionLabel] = 0
            }
            sectionAmounts[sectionLabel] += amount
        })

        Object.keys(sectionAmounts).forEach((sectionLabel) => {
            if (!sectionDataMap[sectionLabel]) {
                sectionDataMap[sectionLabel] = []
            }

            sectionDataMap[sectionLabel].push({
                x: timestamp,
                y: sectionAmounts[sectionLabel],
                additionalInfo: {
                    label: `Section: ${sectionLabel}, Total Amount: ${sectionAmounts[sectionLabel]}`
                }
            })

            totalForAllSeries += sectionAmounts[sectionLabel]
        })

        allSeriesData.push({
            x: timestamp,
            y: totalForAllSeries,
            additionalInfo: {
                label: `Total Amount for all sections: ${totalForAllSeries}`
            }
        })
    })

    const sectionSeriesData = Object.keys(sectionDataMap).map(
        (sectionLabel) => ({
            name: sectionLabel,
            data: sectionDataMap[sectionLabel],
            tooltip: {
                valueDecimals: 2
            }
        })
    )

    sectionSeriesData.push({
        name: 'All',
        data: allSeriesData,
        tooltip: {
            valueDecimals: 2
        }
    })

    return sectionSeriesData
}

const AxsEventViewer = () => {
    const { eventID } = useParams()
    const [eventData, setEventData] = useState(null)
    const [eventHistory, setEventHistory] = useState(null)
    const [isLoading, setIsLoading] = useState(true)

    const [inventoryData, setInventoryData] = useState([])

    useEffect(() => {
        async function fetchData() {
            const response = await apiGet('/axs/event', { eventID })
            console.log('event data:', response)
            if (response) {
                if (response.meta) {
                    delete response.meta.lastSeenJSON
                }
            }
            setEventData(response.meta)
            setEventHistory(response.historical)

            const _inventoryData = assembleInventoryData(response.historical)
            setInventoryData(_inventoryData)

            setIsLoading(false)
        }

        fetchData()
    }, [eventID])

    const d = eventData

    if (isLoading) {
        return <Spinner />
    }

    const detailsCard = (
        <Card title="Event Details" sectioned>
            <div>Event ID: {d.id}</div>
            <div>Event Date: {d.doorDateTimeUTC}</div>
            <div>Presale Start: {d.presaleDateTimeUTC}</div>
            <div>Presale End: {d.presaleEndDateTimeUTC}</div>
            <div>OnSale Start: {d.onsaleDateTimeUTC}</div>
            <div>
                <a
                    href={d.ticketing_ticketURL}
                    target="_blank"
                    rel="noreferrer"
                >
                    Ticketing Link
                </a>
            </div>
        </Card>
    )

    const chartCard = (
        <Card sectioned>
            <div>
                <LineChart
                    title="Inventory by Section"
                    chartData={inventoryData}
                    showLegend={true}
                />
            </div>
            <div className="mono">{JSON.stringify(eventHistory, null, 2)}</div>
        </Card>
    )

    return !eventID ? (
        <div>Please provide an event id</div>
    ) : (
        <Page title={'AXS Event: ' + d?.eventTitle} fullWidth>
            {detailsCard}
            {chartCard}

            {false && (
                <Card title="Data Dump" sectioned>
                    <div className="mono">
                        {JSON.stringify(eventData, null, 2)}
                    </div>
                </Card>
            )}
        </Page>
    )
}

export { AxsEventViewer }
