/** @format */
import { Card, Checkbox } from '@shopify/polaris'
import React, { useCallback, useEffect, useState } from 'react'

import LineChart from './LineChart'
import _ from 'lodash'
import { dateOf } from '../EventPage/utils'

export const PriceLevelsChart = ({
    title,
    eventData,
    sectionGroupMapping,
    inventoryType
}) => {
    const isReady = eventData && eventData.header

    const chartRef = React.useRef()
    const [chartData, setChartData] = useState({})
    const [isBreakdown, setIsBreakdown] = useState(true)

    const [zones, setZones] = useState([])

    const handlePriceLevels = useCallback(
        (
            sectionGroupMapping,
            isPriceLevelsChartBreakdown,
            setPriceLevelsChartData,
            eventData
        ) => {
            if (!eventData || !eventData.inventory) {
                return
            }

            const priceLevelsChartRawData = eventData.inventory
                .filter((v) => v.inventory_types === inventoryType)
                .map((v) => ({
                    fetched_at: v.fetched_at,
                    price: v.price_min,
                    section: v.section,
                    count: v.count
                }))

            let recordsByFetchedAt = _.groupBy(
                priceLevelsChartRawData,
                (x) => x.fetched_at
            )

            let fetchedAts = _.orderBy(Object.keys(recordsByFetchedAt), (x) =>
                new Date(x).getTime()
            )

            let distinctZones = _.uniqBy(Object.values(sectionGroupMapping))
            distinctZones = _.sortBy(distinctZones)
            setZones(distinctZones)

            let distinctPriceLevels = _.chain(priceLevelsChartRawData)
                .map((x) => x.price)
                .uniqBy()
                .orderBy((x) => parseFloat(x))
                .value()

            let chartData = {}
            let chartDataBreakdown = {}

            let chartBreakdownKeys = []

            for (let priceLevel of distinctPriceLevels) {
                chartData[priceLevel] = []
                for (let zone of distinctZones) {
                    let key = zone + '_' + priceLevel
                    chartBreakdownKeys.push(key)
                    chartDataBreakdown[key] = []
                }
            }

            for (
                let fetchedAtIndex = 0;
                fetchedAtIndex < fetchedAts.length;
                fetchedAtIndex++
            ) {
                let fetchedAt = fetchedAts[fetchedAtIndex]

                // We need to drop intraday data points to avoid the chart getting sluggish
                // so we include a data point only if number of x values is < 50, or, if there's more
                // than 50, then just take the last data point of the day
                if (
                    fetchedAts.length < 50 ||
                    fetchedAtIndex === fetchedAts.length - 1 ||
                    dateOf(fetchedAts[fetchedAtIndex]) !==
                        dateOf(fetchedAts[fetchedAtIndex + 1])
                ) {
                    let recordsAtPriceLevel = recordsByFetchedAt[fetchedAt]
                    for (let record of recordsAtPriceLevel) {
                        record.zone = sectionGroupMapping[record.section] ?? '_'
                    }

                    let countsByPriceLevel = _.groupBy(
                        recordsAtPriceLevel,
                        (x) => x.price
                    )

                    let countsByPriceLevelAndZone = _.groupBy(
                        recordsAtPriceLevel,
                        (x) => x.zone + '_' + x.price
                    )

                    for (let seriesKey of distinctPriceLevels) {
                        let counts = countsByPriceLevel[seriesKey]
                        let count =
                            (counts && _.sumBy(counts, (x) => x.count)) || 0
                        // look up or default to zero
                        chartData[seriesKey].push([
                            new Date(fetchedAt).getTime(),
                            count
                        ])
                    }

                    for (let seriesKey of chartBreakdownKeys) {
                        let counts = countsByPriceLevelAndZone[seriesKey]
                        let count =
                            (counts && _.sumBy(counts, (x) => x.count)) || 0
                        chartDataBreakdown[seriesKey].push([
                            new Date(fetchedAt).getTime(),
                            count
                        ])
                    }
                }
            }

            let _priceLevelsChartData = distinctPriceLevels.map(
                (priceLevel, i) => {
                    let prettyPriceLevel = parseFloat(priceLevel).toFixed(0)

                    //console.log(prettyPriceLevel)

                    return {
                        name: prettyPriceLevel,
                        step: false,
                        data: chartData[priceLevel]
                    }
                }
            )

            let _priceLevelsByZoneChartData = chartBreakdownKeys.map(
                (seriesKey, i) => {
                    return {
                        name: seriesKey,
                        step: false,
                        data: chartDataBreakdown[seriesKey],
                        visible: true
                    }
                }
            )

            let colorizeSeries = (series) => {
                for (let i = 0; i < series.length; i++) {
                    let iPercent = i / series.length
                    let hueLo = 110
                    let hueHi = 370
                    let hue = hueLo + (hueHi - hueLo) * iPercent
                    let color = 'hsl(' + Math.floor(hue) + ', 54%, 46%)'
                    series[i].color = color
                }
            }

            _priceLevelsByZoneChartData = _priceLevelsByZoneChartData.filter(
                (cd) => _.some(cd.data.map((x) => x[1] !== 0))
            )

            let backPriceOutOfLabel = (x) => {
                let tokens = x.split('_')
                let price = tokens[tokens.length - 1]
                return parseFloat(price)
            }

            _priceLevelsChartData = _.sortBy(_priceLevelsChartData, (x) =>
                parseFloat(x.name)
            )

            _priceLevelsByZoneChartData = _.sortBy(
                _priceLevelsByZoneChartData,
                (x) => backPriceOutOfLabel(x.name)
            )

            colorizeSeries(_priceLevelsChartData)
            colorizeSeries(_priceLevelsByZoneChartData)

            if (isPriceLevelsChartBreakdown) {
                setPriceLevelsChartData(_priceLevelsByZoneChartData)
            } else {
                setPriceLevelsChartData(_priceLevelsChartData)
            }
        },
        []
    )

    useEffect(() => {
        handlePriceLevels(
            sectionGroupMapping,
            isBreakdown,
            setChartData,
            eventData
        )
    }, [
        setChartData,
        sectionGroupMapping,
        isBreakdown,
        handlePriceLevels,
        eventData
    ])

    const applyToChartSeries = (func) => {
        if (!chartRef || !chartRef.current || !chartRef.current.chart) {
            return
        }

        let chart = chartRef.current.chart
        chart.series.forEach((series, i) => {
            setTimeout(() => func(series), (i * 20) / chart.series.length)
        })
    }

    const makeZoneEnableDisableButtons = (z, i) => (
        <span key={i}>
            <button
                onClick={() => {
                    applyToChartSeries((series) => {
                        if (series.name.startsWith(z + '_')) {
                            series.setVisible(true)
                        }
                    })
                }}
            >
                Select all {z}
            </button>

            <button
                onClick={() => {
                    applyToChartSeries((series) => {
                        if (series.name.startsWith(z + '_')) {
                            series.setVisible(false)
                        }
                    })
                }}
            >
                Deselect all {z}
            </button>
        </span>
    )

    const uiOptions = (
        <div>
            <div style={{ marginBottom: 8 }}>
                <Checkbox
                    checked={isBreakdown}
                    onChange={() => {
                        setIsBreakdown(!isBreakdown)
                    }}
                    label="By Zone"
                />
            </div>

            <div>
                <button
                    onClick={() => {
                        applyToChartSeries((series) => {
                            series.setVisible(true)
                        })
                    }}
                >
                    Select all
                </button>

                <button
                    onClick={() => {
                        applyToChartSeries((series) => {
                            series.setVisible(false)
                        })
                    }}
                >
                    Deselect all
                </button>

                <div>
                    {isBreakdown && zones.map(makeZoneEnableDisableButtons)}
                </div>
            </div>
        </div>
    )
    if (!isReady) {
        return ''
    }

    return (
        <Card sectioned>
            <LineChart
                eventDataHeader={eventData.header}
                title={title}
                chartData={chartData}
                chartComponentRef={chartRef}
                showLegend={true}
                uiOptions={uiOptions}
                height={800}
            />
        </Card>
    )
}
