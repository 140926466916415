/** @format */

import { Button, Card, Form, Page, Tabs, TextField } from "@shopify/polaris";
import { useCallback, useState } from "react";
import { useFirebaseContext } from "../../FirebaseContext";

export const LoginPage = () => {
	const { signIn, logOut, register, resetLogin } = useFirebaseContext();
	const [selected, setSelected] = useState(0);

	const handleTabChange = useCallback(
		(selectedTabIndex) => setSelected(selectedTabIndex),
		[]
	);

	const [email, setEmail] = useState("");
	const [password, setPassword] = useState("");
	const [name, setName] = useState("");
	const [submitted, setSubmitted] = useState(false);

	const handleSubmit = (e) => {
		setSubmitted(true);
		// suppress default form submission
		e.preventDefault();

		// alert(selected);

		// if email is not valid format
		if (!email.includes("@")) {
			alert("Please enter a valid email address");
			return;
		}

		// if password is not valid format
		if (password.length < 6) {
			alert("Please enter a valid password");
			return;
		}

		switch (selected) {
			case 0:
				signIn({ email, password }).then(() => {
					setSubmitted(false);
				});
				break;
			case 1:
				register({ email, password, name }).then(() => {
					setSubmitted(false);
				});
				break;
			default:
				break;
		}
	};

	const signInForm = (
		<Form onSubmit={handleSubmit}>
			{/* <Form.Layout> */}
			<TextField
				label="Email"
				type="email"
				value={email}
				onChange={(e) => {
					setEmail(e);
				}}
			/>
			<TextField
				label="Password"
				type="password"
				value={password}
				onChange={(e) => {
					setPassword(e);
				}}
			/>
			<Button disabled={submitted} submit>
				Login
			</Button>
		</Form>
	);
	const registerForm = (
		<Form onSubmit={handleSubmit}>
			{/* <Form.Layout> */}
			<TextField
				label="Email"
				type="email"
				value={email}
				onChange={(e) => {
					setEmail(e);
				}}
			/>
			<TextField
				label="Name"
				type="text"
				value={name}
				onChange={(e) => {
					setName(e);
				}}
			/>
			<TextField
				label="Password"
				type="password"
				value={password}
				onChange={(e) => {
					setPassword(e);
				}}
			/>
			<Button disabled={submitted} submit>
				Register
			</Button>
		</Form>
	);

	const tabs = [
		{
			id: "login",
			title: "Login",
			content: "Login",
			div: signInForm,
			// panelID: "all-customers-content-1",
		},
		{
			id: "register",
			title: "Register",
			content: "Register",
			div: registerForm,
			// panelID: "accepts-marketing-content-1",
		},
	];

	return (
		<Page title="Login">
			<Card>
				<Tabs tabs={tabs} selected={selected} onSelect={handleTabChange}>
					<Card.Section title={tabs[selected].title}>
						{tabs[selected].div}
					</Card.Section>
				</Tabs>
			</Card>
		</Page>
	);
};
