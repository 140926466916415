import React, { useEffect, useState } from 'react'

import { Card } from '@shopify/polaris'
import { apiGet } from '../../../Api'
import ms from 'ms'

function pluralize(doubleCount) {
    if (doubleCount >= 1.0 && doubleCount < 2.0) return ''
    return 's'
}
function msToTime(ms) {
    let seconds = Math.floor(ms / 1000).toFixed(0)
    let minutes = (ms / (1000 * 60)).toFixed(0)
    let hours = (ms / (1000 * 60 * 60)).toFixed(1)
    let days = (ms / (1000 * 60 * 60 * 24)).toFixed(1)
    if (seconds < 60) return seconds + ' second' + pluralize(seconds)
    if (minutes < 60) return minutes + ' minute' + pluralize(minutes)
    if (hours < 24) return hours + ' hour' + pluralize(hours)
    return days + ' day' + pluralize(days)
}

function getAge(timestamp) {
    return msToTime(new Date() - new Date(timestamp)) + ' ago'
}

function useLoop(action, dependencies, delay) {
    useEffect(() => {
        let cancelled = false
        async function run() {
            await action()
            await new Promise((resolve) => setTimeout(resolve, delay))
            if (!cancelled) run()
        }
        run()
        return () => {
            cancelled = true
        }
    }, dependencies)
}

function useAge(timestamp) {
    const [ageString, setAgeString] = useState('Loading')
    useLoop(
        () => {
            if (timestamp) {
                setAgeString(getAge(timestamp))
            } else {
                setAgeString('none')
            }
        },
        [setAgeString, timestamp],
        1000
    )

    return ageString
}

const LivenessMonitor = ({ eventID }) => {
    const [json, setJson] = useState('')

    const [liveInventoryTotal, setLiveInventoryTotal] = useState(null)
    const [socketStatus, setSocketStatus] = useState('Loading')
    const [pageUpdateTimestamp, setPageUpdateTimestamp] = useState(null)
    const [inventoryTimestamp, setInventoryTimestamp] = useState(null)
    const [systemRestartWarning, setSystemRestartWarning] = useState(null)

    const dataFetchDelay = 2000

    useLoop(
        async () => {
            try {
                const latestInventoryReceived = await apiGet(
                    '/fastEvent/getEventLiveInventoryLatest',
                    { eventID },
                    { timeout: 5000 }
                )

                const statusReceived = await apiGet(
                    '/fastEvent/getEventLiveStatus',
                    {
                        eventID
                    },
                    { timeout: 5000 }
                )

                setPageUpdateTimestamp(new Date().toISOString())

                // TODO defaults if latestInventory is null

                if (latestInventoryReceived) {
                    setJson(
                        JSON.stringify(
                            {
                                latestInventory: latestInventoryReceived,
                                status: statusReceived
                            },
                            null,
                            2
                        )
                    )

                    setInventoryTimestamp(latestInventoryReceived.when)
                    const liveInventoryTotal =
                        latestInventoryReceived.inventoryValue.inventory
                            .map((x) => parseInt(x[1]))
                            .reduce((a, b) => a + b, 0)

                    setLiveInventoryTotal(liveInventoryTotal)
                }

                let _socketStatus = 'unknown'
                if (statusReceived) {
                    _socketStatus = statusReceived.socketStatus
                    const statusAge = new Date() - new Date(statusReceived.when)
                    // console.log('status age', statusAge)
                    // depends on agent update frequency
                    if (statusAge > 30_000) {
                        _socketStatus = 'absent'
                    }
                }
                setSocketStatus(_socketStatus)

                const systemStartupTimestamp = new Date(
                    statusReceived.lastLiveSystemStartup
                )
                const systemStartupAge = new Date() - systemStartupTimestamp

                if (systemStartupAge < ms('5m')) {
                    setSystemRestartWarning(
                        <div>
                            <span>🟠{dotspace}</span> System is booting up:{' '}
                            {getAge(systemStartupTimestamp)}
                        </div>
                    )
                } else {
                    setSystemRestartWarning(null)
                }
            } catch (e) {
                console.error(e)
                setSocketStatus('unknown')
            }
        },
        [eventID],
        dataFetchDelay
    )

    const inventoryAgeString = useAge(inventoryTimestamp)

    const dotspace = <span style={{ marginRight: 2 }}>&nbsp;</span>
    const pageConnectedView =
        new Date() - new Date(pageUpdateTimestamp) < 8_000 ? (
            <span>🟢{dotspace} Page is connected</span>
        ) : (
            <span>🟠{dotspace} Page is disconnected</span>
        )

    const agentConnectedView =
        socketStatus === 'online' ? (
            <span>🟢{dotspace} Agent is connected</span>
        ) : (
            <span>🟠{dotspace} Agent is disconnected</span>
        )

    const systemIsRebootingView = JSON.stringify(socketStatus)

    return (
        <Card title="Live Monitor" sectioned>
            <div>
                {systemRestartWarning}
                <div>{pageConnectedView}</div>
                <div>
                    {agentConnectedView}{' '}
                    <span style={{ opacity: 0.2, marginLeft: 8 }}>
                        ({socketStatus})
                    </span>
                </div>
                <div>Real-time inventory total: {liveInventoryTotal}</div>
                <div>Latest inventory tick: {inventoryAgeString}</div>

                {/*<div
                style={{
                    maxHeight: 200,
                    overflow: 'scroll',
                    fontSize: 13,
                    lineHeight: 1.5
                }}
            >
                <pre>{json}</pre>
            </div>*/}
            </div>
        </Card>
    )
}

export default LivenessMonitor
