import './EventPage.css'

import { BsLayoutSidebarReverse, BsViewStacked } from 'react-icons/bs'
import { Card, Checkbox, Layout, Page, Spinner } from '@shopify/polaris'
import React, { useCallback, useEffect, useState } from 'react'
import { useNavigate, useParams } from 'react-router-dom'

import Alerts from './Alerts'
import { DetailsCard } from './DetailsCard'
import EventPageChart from './EventPageChart'
import { IoAirplane } from 'react-icons/io5'
import { JitVividPanel } from './JitVividCard'
import LineChart from './LineChart'
import { PrimaryStatsCard } from './PrimaryStatsCard'
import { SectionGrouperCard } from './SharedComponents'
import VividSection from './VividSection'
import _ from 'lodash'
import { apiGet } from '../../../Api'
import axios from 'axios'
import { consolidateArrays } from '../EventPage/utils'
import { dateOf } from './utils'
import moment from 'moment'
import { useCookies } from 'react-cookie'
import { useFirebaseContext } from '../../../FirebaseContext'
import { useLocation } from 'react-router-dom'

const loPassThreshold = 1_200

const EventPage = () => {
    const [cookies, setCookie, removeCookie] = useCookies(['cookie-name'])

    const { eventID } = useParams()

    const navigate = useNavigate()

    useEffect(() => {
        if (eventID === 'random') {
            const defaultEventID = _.sample([
                'vv1kbZbak7G7U-mW',
                'vv17GZbkGkiZVvQl',
                '1A8Zk7eGkeMEdAR',
                'vv1kvOvO0wGA50tgY',
                'vvG10ZbGCbEod4',
                '17AOvOG6u6ZQNG_'
            ])
            navigate(`/ticketMaster/event/${defaultEventID}`, {
                replace: true
            })
        }
    }, [eventID, navigate])

    const [vividData, setVividData] = useState({})

    const { expressFunction } = useFirebaseContext()
    const tm_getEvent = expressFunction('tm_getEvent')

    const location = useLocation()

    const [isBadEventId, setIsBadEventId] = useState(false)
    const [eventData, setEventData] = useState({})
    const [ogChartData, setOgChartData] = useState({})
    const [isLiveChartBusy, setIsLiveChartBusy] = useState(true)
    const [liveChartRawData, setLiveChartRawData] = useState()
    const [liveChartData, setLiveChartData] = useState({})
    const [liveChartDataSummary, setLiveChartDataSummary] = useState({})
    const [liveChartDataBreakdown, setLiveChartDataBreakdown] = useState({})
    const [isLiveChartBreakdown, setIsLiveChartBreakdown] = useState(true)

    const [primaryPriceLevelsChartRawData, setPrimaryPriceLevelsChartRawData] =
        useState()
    const [primaryPriceLevelsChartData, setPrimaryPriceLevelsChartData] =
        useState({})
    const primaryPriceLevelsChartComponentRef = React.useRef()
    const [
        isPrimaryPriceLevelsChartBreakdown,
        setIsPrimaryPriceLevelsChartBreakdown
    ] = useState(true)
    const [isPrimaryPriceLevelsLoPass, setIsPrimaryPriceLevelsLoPass] =
        useState(false)

    const [resalePriceLevelsChartRawData, setResalePriceLevelsChartRawData] =
        useState()
    const [resalePriceLevelsChartData, setResalePriceLevelsChartData] =
        useState({})
    const resalePriceLevelsChartComponentRef = React.useRef()
    const [
        isResalePriceLevelsChartBreakdown,
        setIsResalePriceLevelsChartBreakdown
    ] = useState(true)
    const [isResalePriceLevelsLoPass, setIsResalePriceLevelsLoPass] =
        useState(false)

    const [
        isResaleDynamicMinPriceChartBreakdown,
        setIsResaleDynamicMinPriceChartBreakdown
    ] = useState(true)
    const [
        isResaleDynamicMinPriceYAxisQuantity,
        setIsResaleDynamicMinPriceYAxisQuantity
    ] = useState(false)

    const [resaleDynamicMinPriceChartData, setResaleDynamicMinPriceChartData] =
        useState({})

    const resaleDynamicMinPriceChartComponentRef = React.useRef()

    const liveChartComponentRef = React.useRef()
    const [zones, setZones] = useState([])
    const [isLoPass, setIsLoPass] = useState(false)

    const [primaryStatsData, setPrimaryStatsData] = useState([])

    // Section Grouping
    const [selectedStrat, setSelectedStrat] = useState()
    const [sectionGroupMapping, setSectionGroupMapping] = useState({})
    const [isGroupListingEnabled, setIsGroupListingEnabled] = useState(false)
    const [sectionGroupingStrats, setSectionGroupingStrats] = useState([])

    const [activeLayoutIndex, setActiveLayoutIndex] = useState(
        cookies['activeLayoutIndex'] ?? 0
    )

    const vividSearch = async ({ eventName, eventDate }) => {
        const search = eventName.split(/[-:/()\\_]/)
        const searchDate = moment(eventDate).format('YYYY-MM-DD')

        const vividSearchURL = `https://www.vividseats.com/hermes/api/v1/productions?page=1&pageSize=100&query=${search[0]}&startDate=${searchDate}&endDate=${searchDate}`
        let res = await axios.get(vividSearchURL)
        return res.data.items[0]
    }

    useEffect(() => {
        setCookie('activeLayoutIndex', activeLayoutIndex)
    }, [activeLayoutIndex, setCookie])

    useEffect(() => {
        let canceled = false
        const getData = async () => {
            const startTime = new Date()

            setIsLiveChartBusy(true)

            let [
                eventDataResponse,
                strategies,
                selectedStrategy,
                primarySegmentPricingResults,
                results,
                resaleSegmentPricingResults
            ] = await Promise.all([
                tm_getEvent({ eventID }),
                apiGet('/sectionGroups/getStrategies'),

                apiGet('/sectionGroups/getStrategy', {
                    eventID
                }),

                apiGet('/event/getInventorySegmentPricingHistory', {
                    eventId: eventID,
                    inventoryType: 'primary'
                }),

                apiGet('/event/getEventLiveInventory', {
                    eventId: eventID
                }),

                apiGet('/event/getInventorySegmentPricingHistory', {
                    eventId: eventID,
                    inventoryType: 'resale'
                })
            ])

            const endTime = new Date()
            console.log('getData took', endTime - startTime, 'ms')

            setLiveChartRawData(results)
            setIsLiveChartBusy(false)

            let strats = strategies.map((r) => ({ label: r, value: r }))
            setSectionGroupingStrats(strats)

            // Default strategy is set by the server
            // if (selectedStrategy === '') { selectedStrategy = strats[0].label }
            setSelectedStrat(selectedStrategy)

            let eventData = eventDataResponse.data

            const { inventory_data } = eventDataResponse.data

            let vividResults

            try {
                vividResults = await vividSearch({
                    eventName: eventDataResponse.data.header.name,
                    eventDate: eventDataResponse.data.header.event_date_utc
                })
            } catch {}

            const _ogChartData = {}

            const primaryStandard = {}
            const primaryPlatinum = {}
            const primaryConsolidatedBySection = {}

            const resaleStandard = {}
            const resalePlatinum = {}
            const resaleConsolidatedBySection = {}

            // console.log('debug inventory_data', inventory_data)

            inventory_data.forEach((inventory) => {
                const {
                    fetched_at,
                    is_official_platinum,
                    inventory_types,
                    section
                } = inventory
                const count = parseInt(inventory.count)

                let sectionGroup = sectionGroupMapping[section] ?? '_'

                if (inventory_types === 'primary') {
                    if (
                        primaryConsolidatedBySection[sectionGroup] === undefined
                    ) {
                        primaryConsolidatedBySection[sectionGroup] = {}
                    }
                    primaryConsolidatedBySection[sectionGroup][fetched_at] =
                        (primaryConsolidatedBySection[sectionGroup][
                            fetched_at
                        ] || 0) + count

                    if (is_official_platinum) {
                        primaryPlatinum[fetched_at] =
                            (primaryPlatinum[fetched_at] || 0) + count
                    } else {
                        primaryStandard[fetched_at] =
                            (primaryStandard[fetched_at] || 0) + count
                    }
                } else if (inventory_types === 'resale') {
                    if (
                        resaleConsolidatedBySection[sectionGroup] === undefined
                    ) {
                        resaleConsolidatedBySection[sectionGroup] = {}
                    }

                    resaleConsolidatedBySection[sectionGroup][fetched_at] =
                        (resaleConsolidatedBySection[sectionGroup][
                            fetched_at
                        ] || 0) + count

                    if (is_official_platinum) {
                        resalePlatinum[fetched_at] =
                            (resalePlatinum[fetched_at] || 0) + count
                    } else {
                        resaleStandard[fetched_at] =
                            (resaleStandard[fetched_at] || 0) + count
                    }
                }
            })

            const convertToArray = (value) => {
                const newArray = []
                Object.keys(value).forEach((key) => {
                    newArray.push([moment(key).valueOf(), value[key]])
                })
                return newArray
            }

            _ogChartData.primary = {
                standard: convertToArray(primaryStandard),
                platinum: convertToArray(primaryPlatinum),
                bySection: _.mapValues(
                    primaryConsolidatedBySection,
                    convertToArray
                )
            }
            _ogChartData.primary.consolidated = consolidateArrays(
                _ogChartData.primary.standard,
                _ogChartData.primary.platinum
            ).sort((a, b) => a[0] - b[0])

            _ogChartData.resale = {
                standard: convertToArray(resaleStandard),
                platinum: convertToArray(resalePlatinum),
                bySection: _.mapValues(
                    resaleConsolidatedBySection,
                    convertToArray
                )
            }

            _ogChartData.resale.consolidated = consolidateArrays(
                _ogChartData.resale.standard,
                _ogChartData.resale.platinum
            ).sort((a, b) => a[0] - b[0])

            if (!canceled) {
                setEventData(eventDataResponse.data)
                if (!eventDataResponse.data.header) {
                    setIsBadEventId(true)
                }
                setOgChartData(_ogChartData)
                setPrimaryPriceLevelsChartRawData(primarySegmentPricingResults)
                setResalePriceLevelsChartRawData(resaleSegmentPricingResults)
                setVividData(vividResults)
            }
        }

        getData()

        return () => {
            canceled = true
        }
    }, [eventID, sectionGroupMapping])

    useEffect(() => {
        let liveDataSummary = []
        let liveDataBreakdown = {}
        let liveDataGroupedBreakdown = {}

        //console.log('debug liveChartRawData', liveChartRawData)

        let results = liveChartRawData

        results = _.orderBy(results, 'fetched_at')

        let state = {}

        let getCount = () => {
            let totalCount = 0
            for (let key of Object.keys(state)) {
                totalCount += state[key]
            }
            return totalCount
        }

        let getGroupCount = (group_id) => {
            let totalCount = 0
            for (let key of Object.keys(state)) {
                let keyGroup = sectionGroupMapping[key] ?? '_'
                if (keyGroup === group_id) {
                    totalCount += state[key]
                }
            }
            return totalCount
        }

        for (let item of results) {
            let { section_id, fetched_at, count } = item

            let prevCount = state[section_id] || -1
            state[section_id] = count
            let isChanged = count !== prevCount

            if (isChanged) {
                let newCount = getCount()
                let now = Date.parse(fetched_at)
                liveDataSummary.push([now, newCount])

                if (liveDataBreakdown[section_id] === undefined) {
                    liveDataBreakdown[section_id] = []
                }
                liveDataBreakdown[section_id].push([now, state[section_id]])

                let group_id = sectionGroupMapping[section_id] ?? '_'
                let groupCount = getGroupCount(group_id)
                if (liveDataGroupedBreakdown[group_id] === undefined) {
                    liveDataGroupedBreakdown[group_id] = []
                }
                liveDataGroupedBreakdown[group_id].push([now, groupCount])
            }
        }

        let _liveChartDataSummary = [
            {
                name: 'All',
                step: true,
                data: liveDataSummary
            }
        ]

        let _liveChartDataBreakdown = Object.keys(liveDataGroupedBreakdown).map(
            (group_id) => {
                return {
                    name: group_id,
                    step: true,
                    data: liveDataGroupedBreakdown[group_id]
                }
            }
        )

        if (isLiveChartBreakdown === undefined) {
            setIsLiveChartBreakdown(false)
        }
        setLiveChartDataSummary(_liveChartDataSummary)
        setLiveChartDataBreakdown(_liveChartDataBreakdown)
    }, [liveChartRawData, sectionGroupMapping])

    useEffect(() => {
        let dataToChart = isLiveChartBreakdown
            ? liveChartDataBreakdown
            : liveChartDataSummary

        // if (isLoPass) {
        //     dataToChart = dataToChart.filter((s) => {
        //         if (!s.data) {
        //             return false
        //         }

        //         return s.data[s.data.length - 1][1] < loPassThreshold
        //     })
        // }

        setLiveChartData(dataToChart)
    }, [
        isLiveChartBreakdown,
        liveChartDataBreakdown,
        liveChartDataSummary,
        isLoPass
    ])

    const setZoomToFirstNDays = useCallback(
        (chartComponentRef, chartData, numDays) => {
            const chart = chartComponentRef.current.chart
            // TODO cycle through all the series and find the min of the mins
            const firstPoint = chartData[0].data[0][0] // Get the x value of the first data point
            const tenDaysLater = firstPoint + numDays * 24 * 3600 * 1000
            chart.xAxis[0].setExtremes(firstPoint, tenDaysLater)
        }
    )

    const handlePriceLevels = useCallback(
        (
            priceLevelsChartRawData,
            sectionGroupMapping,
            isPriceLevelsChartBreakdown,
            setPriceLevelsChartData,
            isPriceLevelsLoPass
        ) => {
            let recordsByFetchedAt = _.groupBy(
                priceLevelsChartRawData,
                (x) => x.fetched_at
            )

            let fetchedAts = _.orderBy(Object.keys(recordsByFetchedAt), (x) =>
                new Date(x).getTime()
            )

            let distinctZones = _.uniqBy(Object.values(sectionGroupMapping))
            distinctZones = _.sortBy(distinctZones)

            // NOTE this is being called twice, which is not ideal, but it's okay for now
            setZones(distinctZones)

            let distinctPriceLevels = _.chain(priceLevelsChartRawData)
                .map((x) => x.price)
                .uniqBy()
                .orderBy((x) => parseFloat(x))
                .value()

            let chartData = {}
            let chartDataBreakdown = {}

            let chartBreakdownKeys = []

            for (let priceLevel of distinctPriceLevels) {
                chartData[priceLevel] = []
                for (let zone of distinctZones) {
                    let key = zone + '_' + priceLevel
                    chartBreakdownKeys.push(key)
                    chartDataBreakdown[key] = []
                }
            }

            for (
                let fetchedAtIndex = 0;
                fetchedAtIndex < fetchedAts.length;
                fetchedAtIndex++
            ) {
                let fetchedAt = fetchedAts[fetchedAtIndex]

                // We need to drop intraday data points to avoid the chart getting sluggish
                // so we include a data point only if number of x values is < 50, or, if there's more
                // than 50, then just take the last data point of the day
                if (
                    fetchedAts.length < 50 ||
                    fetchedAtIndex === fetchedAts.length - 1 ||
                    dateOf(fetchedAts[fetchedAtIndex]) !==
                        dateOf(fetchedAts[fetchedAtIndex + 1])
                ) {
                    let recordsAtPriceLevel = recordsByFetchedAt[fetchedAt]
                    for (let record of recordsAtPriceLevel) {
                        record.zone = sectionGroupMapping[record.section] ?? '_'
                    }

                    let countsByPriceLevel = _.groupBy(
                        recordsAtPriceLevel,
                        (x) => x.price
                    )

                    let countsByPriceLevelAndZone = _.groupBy(
                        recordsAtPriceLevel,
                        (x) => x.zone + '_' + x.price
                    )

                    for (let seriesKey of distinctPriceLevels) {
                        let counts = countsByPriceLevel[seriesKey]
                        let count =
                            (counts && _.sumBy(counts, (x) => x.count)) || 0
                        // look up or default to zero
                        chartData[seriesKey].push([
                            new Date(fetchedAt).getTime(),
                            count
                        ])
                    }

                    for (let seriesKey of chartBreakdownKeys) {
                        let counts = countsByPriceLevelAndZone[seriesKey]
                        let count =
                            (counts && _.sumBy(counts, (x) => x.count)) || 0
                        chartDataBreakdown[seriesKey].push([
                            new Date(fetchedAt).getTime(),
                            count
                        ])
                    }
                }
            }

            let _priceLevelsChartData = distinctPriceLevels.map(
                (priceLevel, i) => {
                    let prettyPriceLevel = parseFloat(priceLevel).toFixed(0)

                    //console.log(prettyPriceLevel)

                    return {
                        name: prettyPriceLevel,
                        step: false,
                        data: chartData[priceLevel]
                    }
                }
            )

            let _priceLevelsByZoneChartData = chartBreakdownKeys.map(
                (seriesKey, i) => {
                    return {
                        name: seriesKey,
                        step: false,
                        data: chartDataBreakdown[seriesKey],
                        visible: true
                    }
                }
            )

            let colorizeSeries = (series) => {
                for (let i = 0; i < series.length; i++) {
                    let iPercent = i / series.length
                    let hueLo = 110
                    let hueHi = 370
                    let hue = hueLo + (hueHi - hueLo) * iPercent
                    let color = 'hsl(' + Math.floor(hue) + ', 54%, 46%)'
                    series[i].color = color
                }
            }

            _priceLevelsByZoneChartData = _priceLevelsByZoneChartData.filter(
                (cd) => _.some(cd.data.map((x) => x[1] !== 0))
            )

            let backPriceOutOfLabel = (x) => {
                let tokens = x.split('_')
                let price = tokens[tokens.length - 1]
                return parseFloat(price)
            }

            _priceLevelsChartData = _.sortBy(_priceLevelsChartData, (x) =>
                parseFloat(x.name)
            )

            _priceLevelsByZoneChartData = _.sortBy(
                _priceLevelsByZoneChartData,
                (x) => backPriceOutOfLabel(x.name)
            )

            colorizeSeries(_priceLevelsChartData)
            colorizeSeries(_priceLevelsByZoneChartData)

            //if (isPriceLevelsChartBreakdown) {
            //    setPriceLevelsChartData(_priceLevelsByZoneChartData)
            //} else {
            //    setPriceLevelsChartData(_priceLevelsChartData)
            //}

            let seriesData
            if (isPriceLevelsChartBreakdown) {
                seriesData = _priceLevelsByZoneChartData
            } else {
                seriesData = _priceLevelsChartData
            }

            // if (isPriceLevelsLoPass) {
            //     seriesData = seriesData.filter((s) => {
            //         if (!s.data) {
            //             return false
            //         }
            //         return s.data[s.data.length - 1][1] < loPassThreshold
            //     })
            // }

            setPriceLevelsChartData(seriesData)
        },
        []
    )

    const handlePriceLevelsForDynamicMinChart = useCallback(
        (
            priceLevelsChartRawData,
            sectionGroupMapping,
            isDynamicMinPriceChartBreakdown,
            setDynamicMinPriceChartData,
            isDynamicMinPriceYAxisQuantity
        ) => {
            //console.log('handling price levels for dynamic min chart')

            let recordsByFetchedAt = _.groupBy(
                priceLevelsChartRawData,
                (x) => x.fetched_at
            )

            let fetchedAts = _.orderBy(Object.keys(recordsByFetchedAt), (x) =>
                new Date(x).getTime()
            )

            let distinctZones = _.uniqBy(Object.values(sectionGroupMapping))
            distinctZones = _.sortBy(distinctZones)

            let chartData = []
            let chartDataBreakdown = {}

            let chartBreakdownKeys = []

            for (let zone of distinctZones) {
                chartBreakdownKeys.push(zone)
                chartDataBreakdown[zone] = []
            }

            let dateOf = (x) => {
                return moment(x).startOf('day').format()
            }

            for (
                let fetchedAtIndex = 0;
                fetchedAtIndex < fetchedAts.length;
                fetchedAtIndex++
            ) {
                let fetchedAt = fetchedAts[fetchedAtIndex]

                // We need to drop intraday data points to avoid the chart getting sluggish
                // so we include a data point only if number of x values is < 50, or, if there's more
                // than 50, then just take the last data point of the day
                if (
                    fetchedAts.length < 50 ||
                    fetchedAtIndex === fetchedAts.length - 1 ||
                    dateOf(fetchedAts[fetchedAtIndex]) !==
                        dateOf(fetchedAts[fetchedAtIndex + 1])
                ) {
                    let recordsAtPriceLevel = recordsByFetchedAt[fetchedAt]
                    //console.log(recordsAtPriceLevel)
                    for (let record of recordsAtPriceLevel) {
                        record.zone = sectionGroupMapping[record.section] ?? '_'
                    }

                    let countsByPriceLevel = _.groupBy(
                        recordsAtPriceLevel,
                        (x) => x.price
                    )

                    let recordsByZone = _.groupBy(
                        recordsAtPriceLevel,
                        (x) => x.zone
                    )

                    //console.log(fetchedAt, countsByPriceLevel)

                    function getLowestPriceAndNonSingleQuantity(levelAndCount) {
                        const sortedLevelAndCount = _.orderBy(
                            levelAndCount,
                            (x) => x[0]
                        )

                        for (let [level, count] of sortedLevelAndCount) {
                            if (count > 1) {
                                return [level, count]
                            }
                        }

                        // If there aren't any > 1, then just return the only one
                        for (let [level, count] of sortedLevelAndCount) {
                            return [level, count]
                        }

                        // Default 0
                        return [0, 0]
                    }

                    const levelAndCount = Object.keys(countsByPriceLevel).map(
                        (pl) => [
                            parseFloat(pl),
                            _.sumBy(countsByPriceLevel[pl], (x) => x.count)
                        ]
                    )

                    const [lowestPrice, nonSingleQuantity] =
                        getLowestPriceAndNonSingleQuantity(levelAndCount)

                    chartData.push({
                        x: new Date(fetchedAt).getTime(),
                        y: isDynamicMinPriceYAxisQuantity
                            ? nonSingleQuantity
                            : lowestPrice,
                        additionalInfo: {
                            label: `${nonSingleQuantity} tickets at $${lowestPrice}`
                        }
                    })

                    for (let seriesKey of chartBreakdownKeys) {
                        const recordsForZone = recordsByZone[seriesKey]

                        const zoneCountsByPriceLevel = _.groupBy(
                            recordsForZone,
                            (x) => x.price
                        )

                        const zoneLevelAndCount = Object.keys(
                            zoneCountsByPriceLevel
                        ).map((pl) => [
                            parseFloat(pl),
                            _.sumBy(zoneCountsByPriceLevel[pl], (x) => x.count)
                        ])

                        const [zoneLowestPrice, zoneNonSingleQuantity] =
                            getLowestPriceAndNonSingleQuantity(
                                zoneLevelAndCount
                            )
                        chartDataBreakdown[seriesKey].push({
                            x: new Date(fetchedAt).getTime(),
                            y: isDynamicMinPriceYAxisQuantity
                                ? zoneNonSingleQuantity
                                : zoneLowestPrice,
                            additionalInfo: {
                                label: `${zoneNonSingleQuantity} tickets at $${zoneLowestPrice}`
                            }
                        })
                    }
                }
            }

            const summaryChartData = [
                {
                    name: 'All',
                    step: true,
                    data: chartData
                }
            ]

            const byZoneChartData = Object.keys(chartDataBreakdown).map(
                (zone) => {
                    return {
                        name: zone,
                        step: true,
                        data: chartDataBreakdown[zone]
                    }
                }
            )

            if (isDynamicMinPriceChartBreakdown) {
                setDynamicMinPriceChartData(byZoneChartData)
            } else {
                setDynamicMinPriceChartData(summaryChartData)
            }
        },
        []
    )

    useEffect(() => {
        console.log('toggled lopass', isPrimaryPriceLevelsLoPass)

        handlePriceLevels(
            primaryPriceLevelsChartRawData,
            sectionGroupMapping,
            isPrimaryPriceLevelsChartBreakdown,
            setPrimaryPriceLevelsChartData,
            isPrimaryPriceLevelsLoPass
        )
    }, [
        primaryPriceLevelsChartRawData,
        setPrimaryPriceLevelsChartData,
        sectionGroupMapping,
        isPrimaryPriceLevelsChartBreakdown,
        handlePriceLevels,
        isPrimaryPriceLevelsLoPass
    ])

    useEffect(() => {
        handlePriceLevels(
            resalePriceLevelsChartRawData,
            sectionGroupMapping,
            isResalePriceLevelsChartBreakdown,
            setResalePriceLevelsChartData,
            isResalePriceLevelsLoPass
        )
    }, [
        resalePriceLevelsChartRawData,
        setResalePriceLevelsChartData,
        sectionGroupMapping,
        isResalePriceLevelsChartBreakdown,
        handlePriceLevels,
        isResalePriceLevelsLoPass
    ])

    useEffect(() => {
        handlePriceLevelsForDynamicMinChart(
            resalePriceLevelsChartRawData,
            sectionGroupMapping,
            isResaleDynamicMinPriceChartBreakdown,
            setResaleDynamicMinPriceChartData,
            isResaleDynamicMinPriceYAxisQuantity
        )
    }, [
        handlePriceLevelsForDynamicMinChart,
        isResaleDynamicMinPriceChartBreakdown,
        isResaleDynamicMinPriceYAxisQuantity,
        resalePriceLevelsChartRawData,
        sectionGroupMapping
    ])

    if (isBadEventId) {
        return <div style={{ margin: 40 }}>Bad Event Id</div>
    }
    if (eventData.header === undefined || eventData.header.name === undefined)
        return <Spinner />

    const alertsCard = (
        <Card title="Alerts" sectioned>
            <Alerts eventID={eventID} zoningStrategy={selectedStrat} />
        </Card>
    )

    const detailsCard = <DetailsCard eventData={eventData} />

    const vividLinkedDataCard = (
        <Card sectioned title="Vivid Events">
            <VividSection tmId={eventID} eventData={eventData} />
        </Card>
    )

    const primaryChart = (
        <EventPageChart
            eventData={eventData}
            title={'Primary Sales Data'}
            chartData={ogChartData.primary}
        />
    )

    const resaleChart = (
        <EventPageChart
            eventData={eventData}
            title={'Resale Sales Data'}
            chartData={ogChartData.resale}
        />
    )

    const liveChartUiOptions = (
        <div>
            <Checkbox
                checked={isLiveChartBreakdown}
                onChange={() => {
                    setIsLiveChartBreakdown(!isLiveChartBreakdown)
                }}
                label="By Zone"
            />
            <span style={{ marginLeft: 28 }}></span>
            <Checkbox
                checked={isLoPass}
                onChange={setIsLoPass}
                label="lo-pass"
            />
            <span
                className="chart-btn"
                onClick={() => {
                    setZoomToFirstNDays(
                        liveChartComponentRef,
                        liveChartData,
                        10
                    )
                }}
            >
                data start
            </span>
        </div>
    )

    const liveChart =
        isLiveChartBreakdown === undefined || isLiveChartBusy ? (
            <div style={{ padding: 50 }}>Loading Live Data...</div>
        ) : (
            <LineChart
                eventData={eventData}
                title={'Live Data'}
                chartData={liveChartData}
                isLoPass={isLoPass}
                showLegend={isLiveChartBreakdown}
                uiOptions={liveChartUiOptions}
                chartComponentRef={liveChartComponentRef}
            />
        )

    const applyToAllPrimaryPriceLevelChartSeries = (func) => {
        if (
            !primaryPriceLevelsChartComponentRef ||
            !primaryPriceLevelsChartComponentRef.current ||
            !primaryPriceLevelsChartComponentRef.current.chart
        ) {
            return
        }

        let chart = primaryPriceLevelsChartComponentRef.current.chart
        chart.series.forEach((series, i) => {
            setTimeout(() => func(series), (i * 20) / chart.series.length)
        })
    }

    const applyToAllResalePriceLevelChartSeries = (func) => {
        if (
            !resalePriceLevelsChartComponentRef ||
            !resalePriceLevelsChartComponentRef.current ||
            !resalePriceLevelsChartComponentRef.current.chart
        ) {
            return
        }

        let chart = resalePriceLevelsChartComponentRef.current.chart
        chart.series.forEach((series, i) => {
            setTimeout(() => func(series), (i * 20) / chart.series.length)
        })
    }

    const applyToAllResaleDynamicMinPriceChartSeries = (func) => {
        if (
            !resaleDynamicMinPriceChartComponentRef ||
            !resaleDynamicMinPriceChartComponentRef.current ||
            !resaleDynamicMinPriceChartComponentRef.current.chart
        ) {
            return
        }

        let chart = resaleDynamicMinPriceChartComponentRef.current.chart
        chart.series.forEach((series, i) => {
            setTimeout(() => func(series), (i * 20) / chart.series.length)
        })
    }

    const primaryPriceLevelChartUiOptions = (
        <div>
            <div style={{ marginBottom: 8 }}>
                <Checkbox
                    checked={isPrimaryPriceLevelsChartBreakdown}
                    onChange={() => {
                        setIsPrimaryPriceLevelsChartBreakdown(
                            !isPrimaryPriceLevelsChartBreakdown
                        )
                    }}
                    label="By Zone"
                />
                <span style={{ marginLeft: 28 }}></span>

                <Checkbox
                    checked={isPrimaryPriceLevelsLoPass}
                    onChange={setIsPrimaryPriceLevelsLoPass}
                    label="lo-pass"
                />

                <span
                    className="chart-btn"
                    onClick={() => {
                        setZoomToFirstNDays(
                            primaryPriceLevelsChartComponentRef,
                            primaryPriceLevelsChartData,
                            10
                        )
                    }}
                >
                    data start
                </span>
            </div>

            <div>
                <button
                    onClick={() => {
                        applyToAllPrimaryPriceLevelChartSeries((series) => {
                            series.setVisible(true)
                        })
                    }}
                >
                    Select all
                </button>

                <button
                    onClick={() => {
                        applyToAllPrimaryPriceLevelChartSeries((series) => {
                            series.setVisible(false)
                        })
                    }}
                >
                    Deselect all
                </button>

                <span style={{ marginLeft: 28 }}></span>

                <div>
                    {isPrimaryPriceLevelsChartBreakdown &&
                        zones.map((z) => (
                            <>
                                <button
                                    onClick={() => {
                                        applyToAllPrimaryPriceLevelChartSeries(
                                            (series) => {
                                                if (
                                                    series.name.startsWith(
                                                        z + '_'
                                                    )
                                                ) {
                                                    series.setVisible(true)
                                                }
                                            }
                                        )
                                    }}
                                >
                                    Select All {z}
                                </button>

                                <button
                                    onClick={() => {
                                        applyToAllPrimaryPriceLevelChartSeries(
                                            (series) => {
                                                if (
                                                    series.name.startsWith(
                                                        z + '_'
                                                    )
                                                ) {
                                                    series.setVisible(false)
                                                }
                                            }
                                        )
                                    }}
                                >
                                    Deselect All {z}
                                </button>
                            </>
                        ))}
                </div>
            </div>
        </div>
    )

    const resalePriceLevelChartUiOptions = (
        <div>
            <div style={{ marginBottom: 8 }}>
                <Checkbox
                    checked={isResalePriceLevelsChartBreakdown}
                    onChange={() => {
                        setIsResalePriceLevelsChartBreakdown(
                            !isResalePriceLevelsChartBreakdown
                        )
                    }}
                    label="By Zone"
                />

                <span style={{ marginLeft: 28 }}></span>

                <Checkbox
                    checked={isResalePriceLevelsLoPass}
                    onChange={setIsResalePriceLevelsLoPass}
                    label="lo-pass"
                />

                <span
                    className="chart-btn"
                    onClick={() => {
                        setZoomToFirstNDays(
                            resalePriceLevelsChartComponentRef,
                            resalePriceLevelsChartData,
                            10
                        )
                    }}
                >
                    data start
                </span>
            </div>

            <div>
                <button
                    onClick={() => {
                        applyToAllResalePriceLevelChartSeries((series) => {
                            series.setVisible(true)
                        })
                    }}
                >
                    Select all
                </button>

                <button
                    onClick={() => {
                        applyToAllResalePriceLevelChartSeries((series) => {
                            series.setVisible(false)
                        })
                    }}
                >
                    Deselect all
                </button>

                <span style={{ marginLeft: 28 }}></span>

                <div>
                    {isResalePriceLevelsChartBreakdown &&
                        zones.map((z) => (
                            <>
                                <button
                                    onClick={() => {
                                        applyToAllResalePriceLevelChartSeries(
                                            (series) => {
                                                console.log('eval', series.name)
                                                if (
                                                    series.name.startsWith(
                                                        z + '_'
                                                    )
                                                ) {
                                                    series.setVisible(true)
                                                }
                                            }
                                        )
                                    }}
                                >
                                    Select All {z}
                                </button>

                                <button
                                    onClick={() => {
                                        applyToAllResalePriceLevelChartSeries(
                                            (series) => {
                                                if (
                                                    series.name.startsWith(
                                                        z + '_'
                                                    )
                                                ) {
                                                    series.setVisible(false)
                                                }
                                            }
                                        )
                                    }}
                                >
                                    Deselect All {z}
                                </button>
                            </>
                        ))}
                </div>
            </div>
        </div>
    )

    const resaleDynamicMinPriceChartUiOptions = (
        <div>
            <div style={{ marginBottom: 8 }}>
                <Checkbox
                    checked={isResaleDynamicMinPriceChartBreakdown}
                    onChange={() => {
                        setIsResaleDynamicMinPriceChartBreakdown(
                            !isResaleDynamicMinPriceChartBreakdown
                        )
                    }}
                    label="By Zone"
                />
                <span style={{ marginLeft: '24px' }}></span>
                <Checkbox
                    checked={isResaleDynamicMinPriceYAxisQuantity}
                    onChange={() => {
                        setIsResaleDynamicMinPriceYAxisQuantity(
                            !isResaleDynamicMinPriceYAxisQuantity
                        )
                    }}
                    label="Y=Quantity"
                />

                <span
                    className="chart-btn"
                    onClick={() => {
                        setZoomToFirstNDays(
                            resaleDynamicMinPriceChartComponentRef,
                            resaleDynamicMinPriceChartData,
                            10
                        )
                    }}
                >
                    data start
                </span>
            </div>

            {false && (
                <div>
                    <button
                        onClick={() => {
                            applyToAllResaleDynamicMinPriceChartSeries(
                                (series) => {
                                    series.setVisible(true)
                                }
                            )
                        }}
                    >
                        Select all
                    </button>

                    <button
                        onClick={() => {
                            applyToAllResaleDynamicMinPriceChartSeries(
                                (series) => {
                                    series.setVisible(false)
                                }
                            )
                        }}
                    >
                        Deselect all
                    </button>

                    <span style={{ marginLeft: 28 }}></span>

                    <div>
                        {isResaleDynamicMinPriceChartBreakdown &&
                            zones.map((z) => (
                                <>
                                    <button
                                        onClick={() => {
                                            applyToAllResaleDynamicMinPriceChartSeries(
                                                (series) => {
                                                    if (
                                                        series.name.startsWith(
                                                            z + '_'
                                                        )
                                                    ) {
                                                        series.setVisible(true)
                                                    }
                                                }
                                            )
                                        }}
                                    >
                                        Select All {z}
                                    </button>

                                    <button
                                        onClick={() => {
                                            applyToAllResaleDynamicMinPriceChartSeries(
                                                (series) => {
                                                    if (
                                                        series.name.startsWith(
                                                            z + '_'
                                                        )
                                                    ) {
                                                        series.setVisible(false)
                                                    }
                                                }
                                            )
                                        }}
                                    >
                                        Deselect All {z}
                                    </button>
                                </>
                            ))}
                    </div>
                </div>
            )}
        </div>
    )

    const primaryPriceLevelChart = (
        <LineChart
            eventData={eventData}
            title={'Primary Price Levels'}
            chartData={primaryPriceLevelsChartData}
            chartComponentRef={primaryPriceLevelsChartComponentRef}
            showLegend={true}
            isLoPass={isPrimaryPriceLevelsLoPass}
            uiOptions={primaryPriceLevelChartUiOptions}
            height={800}
        />
    )

    const resalePriceLevelChart = (
        <LineChart
            eventData={eventData}
            title={'Resale Price Levels'}
            chartData={resalePriceLevelsChartData}
            chartComponentRef={resalePriceLevelsChartComponentRef}
            showLegend={true}
            isLoPass={isResalePriceLevelsLoPass}
            uiOptions={resalePriceLevelChartUiOptions}
            height={800}
        />
    )

    const resaleDynamicMinPriceChart = (
        <LineChart
            eventData={eventData}
            title={
                isResaleDynamicMinPriceYAxisQuantity
                    ? 'Number of Resale Tickets at Min Price'
                    : 'Min Price of Resale Tickets'
            }
            chartData={resaleDynamicMinPriceChartData}
            chartComponentRef={resaleDynamicMinPriceChartComponentRef}
            showLegend={true}
            uiOptions={resaleDynamicMinPriceChartUiOptions}
            height={800}
        />
    )

    const primaryStatsCard = (
        <PrimaryStatsCard eventID={eventID} eventData={eventData} />
    )

    const sectionGrouper = SectionGrouperCard({
        eventID,
        selectedStrat,
        sectionGroupingStrats,
        sectionGroupMapping,
        isGroupListingEnabled,
        setSelectedStrat,
        setSectionGroupMapping,
        setIsGroupListingEnabled
    })

    const date = moment(eventData.header.event_date_utc).format('MM/DD/YYYY')
    const pageTitle = `Event: ${eventData.header.name || 'no event name'} / ${
        eventData.venue?.name || 'no venue'
    } / ${date}`

    const layoutControlView = (
        <div
            style={{
                position: 'absolute',
                top: 0,
                left: 0,
                padding: 9,
                paddingLeft: 24,
                width: '100%'
            }}
        >
            <div
                className={
                    'layoutButton ' + (activeLayoutIndex === 0 && 'active')
                }
                onClick={() => {
                    setActiveLayoutIndex(0)
                }}
            >
                <BsLayoutSidebarReverse />
            </div>
            <div
                className={
                    'layoutButton ' + (activeLayoutIndex === 1 && 'active')
                }
                onClick={() => {
                    setActiveLayoutIndex(1)
                }}
            >
                <BsViewStacked />
            </div>

            <a
                href={eventData.header.event_url_link}
                rel="noreferrer"
                target="_blank"
            >
                <div className={'layoutButton'} style={{ marginLeft: 8 }}>
                    <img
                        src={'/images/tmLogo.svg'}
                        alt="Ticketmaster Logo"
                        style={{ height: '15px', opacity: 0.8 }}
                    />
                </div>
            </a>

            <a
                href={location.pathname.replace('/event/', '/eventLive/')}
                rel="noreferrer"
                target="_blank"
            >
                <div
                    className={'layoutButton'}
                    style={{ marginLeft: 4, color: 'hsl(220, 0%, 25%)' }}
                >
                    <IoAirplane />
                </div>
            </a>

            <span style={{ marginLeft: '28px', opacity: 0.2 }}>
                {eventData.header.tm_id}
            </span>
        </div>
    )

    let jitVividPanel = <JitVividPanel vividData={vividData} />

    let layout = (
        <Layout>
            <Layout.Section>{primaryChart}</Layout.Section>
        </Layout>
    )

    layout =
        activeLayoutIndex === 0 ? (
            <Layout>
                <Layout.Section>
                    {primaryChart}
                    {resaleChart}
                    {liveChart}
                    {primaryPriceLevelChart}
                    {resalePriceLevelChart}
                    {resaleDynamicMinPriceChart}
                    {vividLinkedDataCard}
                </Layout.Section>

                <Layout.Section secondary>
                    {detailsCard}
                    {primaryStatsCard}
                    {alertsCard}
                    {jitVividPanel}
                    {sectionGrouper}
                </Layout.Section>
            </Layout>
        ) : (
            <Layout>
                <Layout.Section>
                    <Layout>
                        <Layout.Section oneThird>{detailsCard}</Layout.Section>

                        <Layout.Section oneThird>
                            {jitVividPanel}
                            {alertsCard}
                        </Layout.Section>

                        <Layout.Section oneThird>
                            {primaryStatsCard}
                            {sectionGrouper}
                        </Layout.Section>
                    </Layout>
                </Layout.Section>

                <Layout.Section>
                    {primaryChart}
                    {resaleChart}
                    {liveChart}
                    {primaryPriceLevelChart}
                    {resalePriceLevelChart}
                    {resaleDynamicMinPriceChart}
                    {vividLinkedDataCard}
                </Layout.Section>
            </Layout>
        )

    return (
        <div style={{ marginTop: 32 }}>
            <Page title={pageTitle} fullWidth>
                {layoutControlView}
                {layout}
            </Page>
        </div>
    )
}

export default EventPage
