import './EventPage.css'

import React, { useEffect, useState } from 'react'

import { Card } from '@shopify/polaris'
import _ from 'lodash'
import { apiGet } from '../../../Api'

export const PrimaryStatsCard = ({ eventData, eventID }) => {
    const [primaryStatsData, setPrimaryStatsData] = useState([])
    const [sghRows, setSghRows] = useState([])
    const [sghCols, setSghCols] = useState([])
    const [daysTillEvent, setDaysTillEvent] = useState(0)

    useEffect(() => {
        if (eventData.header) {
            const eventDateUtc = Date.parse(eventData.header.event_date_utc)
            const now = new Date()
            const _daysTillEvent = (eventDateUtc - now) / (24 * 3600 * 1000)
            setDaysTillEvent(_daysTillEvent)
        }
    }, [eventData])

    useEffect(() => {
        const run = async () => {
            const eventMoreDataResults = await apiGet(
                '/event/getEventMoreInfo',
                {
                    eventId: eventID
                }
            )
            let kvp = []
            // console.log('eventMoreDataResults', eventMoreDataResults)
            for (let result of eventMoreDataResults) {
                let resultLine = result.result
                for (let value of Object.values(resultLine)) {
                    for (let [k, v] of Object.entries(value)) {
                        if (k.startsWith('dPC_')) {
                            k = k.replace('dPC_', 'E_')
                            v = v * daysTillEvent
                        }
                        if (typeof v !== 'number') {
                            try {
                                v = parseFloat(v)
                            } catch (ex) {}
                        }
                        if (typeof v === 'number') {
                            // if (v % 1 !== 0) { v = v.toFixed(2) }
                            v = v.toFixed(2)
                        }
                        kvp.push({ key: k, value: v })
                    }
                }
            }
            setPrimaryStatsData(kvp)

            const _sghCols = _.uniq(kvp.map((item) => item.key.split('_')[0]))
            const _sghRows = _.uniq(kvp.map((item) => item.key.split('_')[1]))

            setSghRows(_sghRows)
            setSghCols(_sghCols)
        }

        run()
    }, [daysTillEvent, eventID])

    const cellStyle = {
        padding: '4px 20px',
        textAlign: 'right'
    }

    const primaryStatsGrid = (
        <table>
            <tbody>
                <tr>
                    <th></th>
                    {sghCols.map((col) => (
                        <th key={col} style={cellStyle}>
                            {col}
                        </th>
                    ))}
                </tr>

                {sghRows.map((row) => (
                    <tr key={row}>
                        <th style={cellStyle}>{row}</th>

                        {sghCols.map((col) => (
                            <td key={col} style={cellStyle}>
                                {
                                    primaryStatsData.find(
                                        (item) => item.key === `${col}_${row}`
                                    )?.value
                                }
                            </td>
                        ))}
                    </tr>
                ))}
            </tbody>
        </table>
    )

    return primaryStatsData.length === 0 ? (
        ''
    ) : (
        <Card title="Primary Stats" sectioned>
            {primaryStatsGrid}
        </Card>
    )
}
