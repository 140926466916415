import { Card, Checkbox, Layout, Page, Spinner } from '@shopify/polaris'

import { FetchIntervalField } from './FetchIntervalField'
import { ForceInventoryFetch } from './ForceInventoryFetch'
import moment from 'moment'

export const DetailsCard = ({ eventData }) => {
    if (!eventData) {
        return <div></div>
    }

    return (
        <Card title="Details" sectioned>
            <div>
                <FetchIntervalField eventData={eventData} />
                <ForceInventoryFetch eventData={eventData} />
            </div>
            <p>
                <b>Event Status: </b>
                {eventData.header.event_status}
            </p>
            <p>
                <b>Presale Start</b>:{' '}
                {moment(eventData.header.presale_start_date_utc).format(
                    'MM/DD/YYYY hh:mm A'
                )}
            </p>
            <p>
                <b>Presale End</b>:{' '}
                {moment(eventData.header.presale_end_date_utc).format(
                    'MM/DD/YYYY hh:mm A'
                )}
            </p>
            <p>
                <b>Public Sale Start</b>:{' '}
                {moment(eventData.header.public_sale_start_date_utc).format(
                    'MM/DD/YYYY hh:mm A'
                )}
            </p>
            <p>
                <b>Public Sale End</b>:{' '}
                {moment(eventData.header.public_sale_end_date_utc).format(
                    'MM/DD/YYYY hh:mm A'
                )}
            </p>

            <div style={{ marginTop: 12 }}>
                <p>
                    <b>Venue</b>: {eventData.venue?.name}
                </p>
                <p>{eventData.venue?.address}</p>
                <p>
                    {eventData.venue?.city}, {eventData.venue?.state}{' '}
                    {eventData.venue?.postal_code}
                </p>
            </div>
        </Card>
    )
}
