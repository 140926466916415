/** @format */

import { Card, Page, Spinner } from '@shopify/polaris'
import { useEffect, useState } from 'react'

import InLinkDemoVideo from './20230515 - inLinkDemo.mp4'
import { Navigate } from 'react-router-dom'
import { useFirebaseContext } from '../../FirebaseContext'

const InLink = () => {
    const searchParams = new URLSearchParams(window.location.search)
    const { expressFunction } = useFirebaseContext()
    const inLinkLookup = expressFunction('inLinkLookup')
    const url = searchParams.get('url')
    const eventId = searchParams.get('eventId')
    const urlInLinkVersion = searchParams.get('version')
    const decodedUrl = atob(url)
    const inLinkVersion = '2.00'

    const [lookup, setLookup] = useState(null)
    const [action, setAction] = useState(null)

    useEffect(() => {
        if (lookup !== null) {
            inLinkLookup(lookup).then((res) => {
                // console.log("inLinkLookup res", res.data.id);
                if (lookup.method === 'tm') {
                    setAction(
                        <Navigate to={`/ticketMaster/event/${res.data.id}`} />
                    )
                }
            })
        }
    }, [lookup])

    // alert(url);
    // const urlObj = new URL(decodedUrl);
    // const urlParams = new URLSearchParams(urlObj.search);

    // //tickets.com redirect
    // if (
    // 	decodedUrl.includes("mlb.tickets.com") ||
    // 	decodedUrl.includes("mpv.tickets.com")
    // ) {
    // 	const agency = urlParams.get("agency");
    // 	const orgid = urlParams.get("orgid");
    // 	const pid = urlParams.get("pid");

    // 	return (
    // 		<Navigate to={`/tdc/event/?agency=${agency}&orgid=${orgid}&pid=${pid}`} />
    // 	);
    // }

    // //ticketmaster redirect
    // if (decodedUrl.includes("ticketmaster.com")) {
    // 	if (decodedUrl.split("/")[5].length === 16) {
    // 		return <>Found the tmId - now need to locate the event.</>;
    // 	}
    // }

    if (urlInLinkVersion !== inLinkVersion) {
        const host = window.location.origin

        const urlParamBase64 = '${btoa(window.location.toString())}'

        const eventIdQuery = `\${document.querySelector('meta[name="branch:deeplink:$canonical_identifier"]').content.split('/')[1]}`

        const targetUrl =
            `${host}/inLink?` +
            `url=${urlParamBase64}&` +
            `version=${inLinkVersion}&` +
            `eventId=${eventIdQuery}`

        const bookmarklet = `javascript:window.open(\`${targetUrl}\`,'_blank')`

        return (
            <Page fullWidth>
                <Card sectioned>
                    <div style={{ padding: 12 }}>
                        <p>
                            Hey... Maki InLink has been updated to version{' '}
                            {inLinkVersion} on July 30th, 2024, but it seems
                            you're using version {urlInLinkVersion}.
                            <br />
                            Please update your bookmarklet to the following
                            link:
                            <br />
                            <br />
                        </p>
                        <p>
                            <a
                                style={{ fontSize: '1.5rem', fontWeight: 500 }}
                                href={bookmarklet}
                            >
                                Maki InLink
                            </a>
                            <br />
                            <br />
                        </p>
                        <p style={{ opacity: 0.9 }}>
                            <br />
                            <h2>How to install and use:</h2>
                            <video
                                style={{ width: '700px' }}
                                autoPlay={true}
                                controls
                                src={InLinkDemoVideo}
                            />
                        </p>
                    </div>
                </Card>
            </Page>
        )
    }

    if (url === null) {
        const host = window.location.origin

        const urlParamBase64 = '${btoa(window.location.toString())}'
        const bookmarklet = `javascript:window.open(\`${host}/inLink?url=${urlParamBase64}\&version=${inLinkVersion}\`,'_blank')`

        return (
            <Page title="InLink" fullWidth>
                <Card sectioned>
                    <p>
                        This tool helps link from an external site to a maki
                        page. To use drag the bookmarklet to your bookmark bar.
                        <br />
                        <br />
                    </p>
                    <p>
                        <a
                            style={{
                                fontSize: '1.5rem'
                            }}
                            href={bookmarklet}
                        >
                            Maki InLink
                        </a>
                    </p>
                </Card>
            </Page>
        )
    }

    // Ticketmaster or livenation redirect
    if (
        decodedUrl.includes('ticketmaster.com') ||
        decodedUrl.includes('livenation.com') ||
        decodedUrl.includes('ticketmaster.ca') ||
        decodedUrl.includes('ticketmaster.co.uk') ||
        decodedUrl.includes('ticketmaster.com.mx') ||
        false
    ) {
        const tmId = decodedUrl.match(/\/[A-Z0-9]{16}/g)
        const id = decodedUrl.match(/\/[A-Za-z0-9\-_]{13}/g)

        console.log('tmId', tmId)
        console.log('id', id)

        if (lookup === null)
            setLookup({
                method: 'tm',
                tmId: tmId !== null ? tmId[0].replace('/', '') : undefined,
                id: tmId === null ? id[0].replace('/', '') : undefined,
                eventId: eventId
            })
        return action || <Spinner />
        // return <Navigate to={`/tdc/event/${tmId}`} />;
    }

    return (
        <Page title="InLink" fullWidth>
            <Card sectioned>
                <p>
                    This tool is not compatible with the URL you are trying to
                    use. If you think this is an error, please reach out to dev.
                </p>
                <p>{decodedUrl}</p>
            </Card>
        </Page>
    )
}

export default InLink
