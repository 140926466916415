import styles from "./style.module.css";
import Venue from "./Venue";

const VenueGroup = ({ venues, active, onClick }) => {
  let classes = [styles.venuegroup];
  if (active) classes.push(styles.active);

  return (
    <div className={classes.join(" ")} onClick={onClick}>
      {venues.map((v) => (
        <Venue key={v.id} venue={v} active={false} onClick={() => {}} />
      ))}
    </div>
  );
};

export default VenueGroup;
