import { apiGet, apiPost } from '../../../Api'
import { useEffect, useState, useRef, useCallback, Select } from 'react'
import { TextField } from '@shopify/polaris'
import { FiExternalLink } from 'react-icons/fi'
import ProgressLine from '../../../Components/ProgressLine'
import styles from './style.module.css'
import _ from 'lodash'
import moment from 'moment'

const vendorToPriority = {
    vi: 1,
    tm: 2
}

const getVenueGroupPredicate = (group, predicate) =>
    _.chain(group)
        .sortBy((v) => vendorToPriority[v.source] || 99)
        .map(predicate)
        .value()[0]

//address, city, state, postal_code
const getVenueGroupPrettyName = (group) =>
    getVenueGroupPredicate(
        group,
        (v) => `${v.name}, ${v.city} ${v.state} ${v.postal_code}`
    )

const MultiSelect = ({ value, options, onChange, selectStyle }) => {
    const handleChange = (e) => {
        var options = e.target.options
        var value = []
        for (var i = 0, l = options.length; i < l; i++) {
            if (options[i].selected) {
                value.push(options[i].value)
            }
        }
        onChange(value)
    }

    return (
        <select
            multiple="multiple"
            value={value}
            onChange={handleChange}
            style={{ ...selectStyle, opacity: 0.9 }}
        >
            {options.map((option) => (
                <option
                    value={option.value ?? option}
                    key={option.value ?? option}
                >
                    {option.label ?? option}
                </option>
            ))}
        </select>
    )
}

const EventView = ({ event, onClickEvent, isActive }) => {
    const [showLink, setShowLink] = useState(false)

    const getActiveClassName = (id) => (isActive ? styles.active : '')

    const getSourceClassName = () => {
        if (event.source === 'tm') return styles.tmSource
        if (event.source === 'vi') return styles.viSource
        return ''
    }

    const getClassName = () =>
        [styles.event, getActiveClassName(event.id)].join(' ')

    return (
        <div
            key={event.id}
            style={{ position: 'relative' }}
            className={getClassName()}
            onClick={(htmlEvent) => onClickEvent(htmlEvent, event.id)}
            onMouseEnter={() => setShowLink(true)}
            onMouseLeave={() => setShowLink(false)}
        >
            <span
                className={getSourceClassName()}
                style={{ display: 'inline-block', width: 18 }}
            >
                {event.source.substring(0, 1).toUpperCase()}
            </span>

            <span>{event.name}</span>

            {showLink && (
                <span
                    style={{
                        position: 'absolute',
                        right: 0,
                        top: 8,
                        opacity: 0.7
                    }}
                    onClick={(e) => {
                        e.stopPropagation()
                    }}
                >
                    <a
                        href={event.url}
                        rel="noreferrer"
                        target="_blank"
                        style={{ padding: 10 }}
                    >
                        <FiExternalLink />
                    </a>
                </span>
            )}
        </div>
    )
}

const EventGroupView = ({
    isVisible = true,
    timestamp,
    events,
    className,
    onClickGroup,
    onClickEvent,
    showVenueName,
    activeIds
}) => {
    if (!isVisible) {
        return ''
    }
    const isEventActive = (id) => activeIds.includes(id)

    const venueName = events && events.length && events[0].venue_pretty_name

    return (
        <div
            className={className}
            style={{
                position: 'relative'
            }}
            onClick={onClickGroup}
        >
            {showVenueName && (
                <div className={styles.timestamp}>{venueName}</div>
            )}
            <div className={styles.timestamp}>
                {moment(timestamp).format('MMMM Do, YYYY')} at{' '}
                {moment(timestamp).format('h:mma')}
            </div>

            <div>
                {_.chain(events)
                    .sortBy('source')
                    .map((e) => (
                        <EventView
                            key={e.id}
                            event={e}
                            onClickEvent={onClickEvent}
                            isActive={isEventActive(e.id)}
                        />
                    ))
                    .value()}
            </div>
        </div>
    )
}

const EventLinker = () => {
    const headerHeight = 80
    const [notificationText, setNotificationText] = useState('')
    const [displayHelp, setDisplayHelp] = useState(false)
    const [freeSearchText, setFreeSearchText] = useState('')

    const notificationTimeout = useRef(null)

    const [isBusy, setIsBusy] = useState(false)
    const [allEventsForVenue, setAllEventsForVenue] = useState([])
    const [eventsForVenue, setEventsForVenue] = useState([])
    const [processedEventsForVenue, setProcessedEventsForVenue] = useState([])

    const [selectedVenueGroup, setSelectedVenueGroup] = useState(null)

    const [activeIds, setActiveIds] = useState([])

    const [allProcessedVenues, setAllProcessedVenues] = useState([])

    const [maxHeight, setMaxHeight] = useState(
        window.innerHeight - headerHeight
    )

    const [isBatchMode, setIsBatchMode] = useState(false)

    const [progress, setProgress] = useState(2.0)

    const [showObviouslyLinkable, setShowObviouslyLinkable] = useState(true)
    const [showObviouslyUnlinkable, setShowObviouslyUnlinkable] = useState(true)
    const [showAmbiguouslyLinkable, setShowAmbiguouslyLinkable] = useState(true)

    const clearProgress = () => {
        setTimeout(() => setProgress(2), 2000)
    }

    const notifyMessage = (message, code) => {
        console.log('notify message:', message)
        setNotificationText(message)

        if (notificationTimeout.value !== null) {
            clearTimeout(notificationTimeout.value)
        }

        notificationTimeout.value = setTimeout(() => {
            setNotificationText('')
        }, 5000)
    }

    useEffect(() => {
        let canceled = false
        async function fetchData() {
            try {
                notifyMessage('Loading')
                setIsBusy(true)
                const excludedZipCodes = ['00000', '00901']
                let receivedVenues = await apiGet('/linker/venues', {})
                receivedVenues = receivedVenues.filter(
                    (v) => !excludedZipCodes.includes(v.postal_code)
                )

                let groups = await apiGet('/linker/groups')
                let _allVenues = [...receivedVenues]
                // vivid id's would come in as ints but we want all ids to stay as strings throughout
                _allVenues = _allVenues.map((v) => ({
                    ...v,
                    id: (v.id || '').toString()
                }))

                let _allProcessedVenues = []

                for (let group of groups) {
                    let tm_ids = (group.tm_ids_csv || '').split(',')
                    let vi_ids = (group.vivid_ids_csv || '').split(',')
                    let ids = _.concat(tm_ids, vi_ids)
                    ids = ids.filter((id) => id !== '')

                    let venueGroup = _allVenues.filter((v) =>
                        ids.includes(v.id)
                    )

                    let venueGroupContainer = {
                        id: group.id,
                        items: venueGroup
                    }

                    _allProcessedVenues = [
                        ...(_allProcessedVenues || []),
                        venueGroupContainer
                    ]
                }

                if (!canceled) {
                    setAllProcessedVenues(_allProcessedVenues)
                    notifyMessage('Ready')
                    setIsBusy(false)
                }
            } catch (error) {
                if (canceled) return
                console.error(error)
                notifyMessage('Load failed')
                setIsBusy(false)
            }
        }
        fetchData()

        return () => {
            setIsBusy(false)
            canceled = true
        }
        // TODO review: when should this effect trigger??
    }, [])

    useEffect(() => {
        let intervalHandle = setInterval(() => {
            setMaxHeight(window.innerHeight - headerHeight)
        }, 2000)

        return () => {
            clearInterval(intervalHandle)
        }
    }, [])

    const handleSelectedVenueGroupChanged = async (value) => {
        let vgId = value[0]
        setSelectedVenueGroup(vgId)
    }

    const enrichEvent = (event) => {
        let timestamp = event.event_date_utc
        event.pretty_timestamp =
            moment(timestamp).format('MMMM Do, YYYY') +
            ' ' +
            moment(timestamp).format('h:mma')

        event.section_identifier = JSON.stringify([
            timestamp,
            event.venue_pretty_name
        ])

        if (event.source === 'tm') {
            event.url = event.event_url_link
            event.event_url_link = undefined
        } else if (event.source === 'vi') {
            event.url = `https://www.vividseats.com//production/${event.id}`
        }
    }

    useEffect(() => {
        let canceled = false

        const loadAllEventsForVenue = async () => {
            let venueGroup = allProcessedVenues.find(
                (vg) => vg.id === selectedVenueGroup
            )

            if (!venueGroup) {
                return
            }

            notifyMessage('Loading events for venue')
            setIsBusy(true)

            let venueParams = venueGroup.items.map((v) => ({
                id: v.id,
                source: v.source
            }))

            let _allEventsForVenue = await apiGet('/linker/eventsForVenue', {
                venues: venueParams
            })

            _allEventsForVenue = _.sortBy(
                _allEventsForVenue,
                (e) => e.event_date_utc
            ).map((event) => {
                event.id = event.id.toString()

                event.venue = venueGroup
                event.venue_group_id = venueGroup.id
                event.venue_pretty_name = getVenueGroupPrettyName(
                    venueGroup.items
                )

                enrichEvent(event)
                return event
            })

            if (!canceled) {
                console.log('setAllEventsForVenue')
                setAllEventsForVenue(_allEventsForVenue)
                notifyMessage('Ready')
                setIsBusy(false)
            }
        }

        // Load all events for batch mode
        const handleLoadAllEvents = async () => {
            let venueChunks = _.chunk(
                allProcessedVenues,
                Math.min(100, allProcessedVenues.length)
            )
            let relevantEvents = []
            for (let [index, venueChunk] of venueChunks.entries()) {
                if (venueChunks.length > 1) {
                    setProgress(index / venueChunks.length)
                }

                let venueParams = venueChunk.flatMap((g) =>
                    g.items.map((v) => ({
                        id: v.id,
                        source: v.source
                    }))
                )

                let eventsForChunk = await apiPost(
                    '/linker/getEventsForVenue',
                    {
                        venues: venueParams
                    }
                )

                // Join events back to the venue groups that got them fetched.
                // This is only non-trivial because we batched the web request.
                // Otherwise, we would just set event.venue to the 1 venue that got it fetched.
                for (let event of eventsForChunk) {
                    let venue = venueChunk.find((vg) =>
                        eventMatchesVenueGroup(event, vg)
                    )
                    if (venue) {
                        event.venue = venue
                        event.venue_group_id = venue.id
                        event.venue_pretty_name = getVenueGroupPrettyName(
                            venue.items
                        )
                        enrichEvent(event)

                        relevantEvents.push(event)
                    }
                }
            }

            if (!canceled) {
                setAllEventsForVenue(relevantEvents)
            }
        }

        setEventsForVenue([])
        setProcessedEventsForVenue([])

        if (isBatchMode) {
            handleLoadAllEvents()
        } else {
            loadAllEventsForVenue()
        }

        return () => {
            canceled = true
            setIsBusy(false)
        }
    }, [selectedVenueGroup, isBatchMode])

    useEffect(() => {
        setEventsForVenue([])
        setProcessedEventsForVenue([])
    }, [isBatchMode])

    useEffect(() => {
        // Move the events that are already linked to the right
        let canceled = false
        const dbLoadEventGroups = async () => {
            notifyMessage('Loading links from database')

            let venue_id = selectedVenueGroup

            let param = isBatchMode ? {} : { venue_id }

            let _eventGroups = await apiGet('/linker/linkEvents', param)

            // if (progress < 1) { setProgress(1) }

            let newlyProcessedEventGroups = []
            let newlyProcessedIds = []

            for (let eventGroup of _eventGroups) {
                let { tm_ids_csv, vivid_ids_csv } = eventGroup

                let tm_ids = tm_ids_csv.split(',')
                let vi_ids = vivid_ids_csv.split(',').map((x) => x.toString())

                let tmEvents = allEventsForVenue.filter((e) =>
                    // TODO also filter on source?
                    tm_ids.includes(e.id)
                )
                let viEvents = allEventsForVenue.filter((e) =>
                    vi_ids.includes(e.id)
                )

                let newlyProcessedEventGroup = _.concat(tmEvents, viEvents)
                newlyProcessedEventGroups.push(newlyProcessedEventGroup)
                newlyProcessedIds.push(
                    ...newlyProcessedEventGroup.map((g) => g.id)
                )
            }

            let _remainingEventsForVenue = _.filter(
                allEventsForVenue,
                (e) => !newlyProcessedIds.includes(e.id)
            )

            if (!isBatchMode) {
                let _allCustomLinks = await apiGet('/linker/getAllCustomLinks')
                let allTmEventIds = _allCustomLinks
                    .map((lk) => lk.tm_ids_csv.split(','))
                    .flat()
                let allVividEventIds = _allCustomLinks
                    .map((lk) => lk.vivid_ids_csv.split(','))
                    .flat()

                _remainingEventsForVenue = _.filter(
                    _remainingEventsForVenue,
                    (e) =>
                        !allTmEventIds.includes(e.id) &&
                        !allVividEventIds.includes(e.id)
                )
            }

            if (!canceled) {
                setProcessedEventsForVenue(newlyProcessedEventGroups)
                setEventsForVenue(_remainingEventsForVenue)
                notifyMessage('Load done')
                if (progress < 2) {
                    setProgress(1)
                    clearProgress()
                }
            }
        }

        console.log('react to allEventsForVenue')
        dbLoadEventGroups()

        return () => {
            canceled = true
        }
    }, [allEventsForVenue])

    const dbDeleteEventGroups = async (eventGroups) => {
        notifyMessage('Deleting from database')

        let ids = []

        for (let eventGroup of eventGroups) {
            let id = _.chain(eventGroup)
                .map((g) => g.source + '_' + g.id)
                .sortBy()
                .value()
                .join(',')
                .substring(0, 254)

            ids.push(id)
        }

        await apiPost('/linker/deleteLinkEvents', { ids })

        notifyMessage('Delete done')
    }

    const dbCreateEventGroups = async (eventGroups) => {
        if (eventGroups.length === 0) {
            console.log('event groups is empty')
            return
        }

        notifyMessage('Saving links to database')

        console.log('eventGroups', eventGroups)

        let eventGroupChunks = _.chunk(eventGroups, 1000)

        let params = []

        let n = eventGroupChunks.length

        for (let [i, eventGroupChunk] of eventGroupChunks.entries()) {
            for (let eventGroup of eventGroupChunk) {
                // If multiple venues later, then either concat sorted, or leave it empty
                let tm_events = eventGroup.filter((g) => g.source === 'tm')
                let vi_events = eventGroup.filter((g) => g.source === 'vi')

                let venue_ids = eventGroup.map((g) => g.venue.id)
                let allVenuesSame = _.every(
                    venue_ids,
                    (v) => v === venue_ids[0]
                )
                let venue_id = allVenuesSame ? eventGroup[0].venue.id : 'custom'

                console.log(eventGroup)

                let tm_ids_csv = _.chain(tm_events)
                    //.map((g) => g.source + '_' + g.id)
                    .map((g) => g.id)
                    .sortBy()
                    .value()
                    .join(',')

                let vivid_ids_csv = _.chain(vi_events)
                    //.map((g) => g.source + '_' + g.id)
                    .map((g) => g.id)
                    .sortBy()
                    .value()
                    .join(',')

                let id = _.chain(eventGroup)
                    .map((g) => g.source + '_' + g.id)
                    .sortBy()
                    .value()
                    .join(',')
                    .substring(0, 254)

                let param = {
                    id,
                    venue_id,
                    tm_ids_csv,
                    vivid_ids_csv
                }

                params.push(param)
            }

            await apiPost('/linker/createLinkManyEvents', params)
        }

        notifyMessage('Save done')
    }

    const handleFreeSearchTextChange = useCallback((value) => {
        setFreeSearchText(value)
    }, [])

    const createEventGroups = async (newlyProcessedEventGroupsAll) => {
        let acceptedEventGroups = []

        newlyProcessedEventGroupsAll = _.chain(newlyProcessedEventGroupsAll)
            // pick them off from the top of the list for visual effect
            .sortBy((g) => g[0].event_date_utc)
            .value()

        let newlyProcessedEventGroupChunks = _.chunk(
            newlyProcessedEventGroupsAll,
            1000
        )

        let n = newlyProcessedEventGroupChunks.length

        for (let [
            i,
            newlyProcessedEventGroups
        ] of newlyProcessedEventGroupChunks.entries()) {
            if (n > 1) {
                setProgress(i / n)
            }

            await dbCreateEventGroups(newlyProcessedEventGroups)

            acceptedEventGroups.push(...newlyProcessedEventGroups)

            setProcessedEventsForVenue([
                ...processedEventsForVenue,
                ...acceptedEventGroups
            ])

            let newlyProcessedIds = acceptedEventGroups.flatMap((g) =>
                g.map((e) => e.id)
            )

            deactivate(newlyProcessedIds)

            let _remainingEventsForVenue = _.filter(
                eventsForVenue,
                (e) => !newlyProcessedIds.includes(e.id)
            )

            setEventsForVenue(_remainingEventsForVenue)
        }

        if (n > 1) {
            setProgress(1)
            clearProgress()
        }
    }

    const createEventGroup = (newlyProcessedEvents) =>
        createEventGroups([newlyProcessedEvents])

    const handleClickTimestampToCreate = (eventTime, events) => {
        createEventGroup(events)
    }

    const handleClickTimestampToDelete = (g) => {
        let _processedEventsForVenue = _.filter(
            processedEventsForVenue,
            (g2) => g2 !== g
        )

        let _eventsForVenue = [...eventsForVenue, ...g]

        setEventsForVenue(_eventsForVenue)
        setProcessedEventsForVenue(_processedEventsForVenue)

        dbDeleteEventGroups([g])
    }

    const handleDeleteAll = () => {
        dbDeleteEventGroups(processedEventsForVenue)
        let _eventsForVenue = [
            ...eventsForVenue,
            ...processedEventsForVenue.flat()
        ]
        setProcessedEventsForVenue([])
        setEventsForVenue(_eventsForVenue)
    }

    const handleClearSelection = () => {
        setActiveIds([])
    }

    const handleCompileGroup = async () => {
        let _remainingEventsForVenue = _.filter(
            eventsForVenue,
            (e) => !activeIds.includes(e.id)
        )

        let _newlyCompiledEvents = _.filter(eventsForVenue, (e) =>
            activeIds.includes(e.id)
        )

        setEventsForVenue(_remainingEventsForVenue)
        setActiveIds([])
        createEventGroup(_newlyCompiledEvents)
    }

    const deactivate = (ids) => {
        let _activeIds = activeIds.filter((i) => !ids.includes(i))
        setActiveIds(_activeIds)
    }

    const handleClickEvent = (htmlEvent, id) => {
        htmlEvent.stopPropagation()
        let _activeIds
        if (activeIds.includes(id)) {
            _activeIds = activeIds.filter((i) => i !== id)
        } else {
            _activeIds = [...activeIds, id]
        }

        setActiveIds(_activeIds)
    }

    const eventsByTimeAndVenue = _.chain(eventsForVenue)
        .groupBy('section_identifier')
        .value()

    const isEasyMatch = (eventGroup) =>
        eventGroup.length === 2 &&
        eventGroup.filter((e) => e.source === 'tm').length === 1 &&
        eventGroup.filter((e) => e.source === 'vi').length === 1

    const getEventGroupClassName = (eventGroup) => {
        let classes = [styles.eventGroup]
        if (isEasyMatch(eventGroup)) {
            classes.push(styles.easyMatch)
        }

        let sourceCounts = _.countBy(eventGroup, 'source')
        if (!sourceCounts.tm) classes.push(styles.noCrossSource)
        if (!sourceCounts.vi) classes.push(styles.noCrossSource)

        return classes.join(' ')
    }

    let keyMap = {
        f: handleCompileGroup,
        g: handleCompileGroup
    }

    const handleKeyDown = (event) => {
        let handler = keyMap[event.key] ?? (() => {})
        handler()
    }

    const handleAcceptSuggestions = () => {
        let _easyMatches = _.values(eventsByTimeAndVenue).filter(isEasyMatch)

        createEventGroups(_easyMatches)
    }

    const findVenueOfEvent = (event, venueGroup) =>
        venueGroup.items.find(
            (v) => v.id === event.venue_id && v.source === event.source
        )

    const eventMatchesVenueGroup = (event, venueGroup) =>
        Boolean(findVenueOfEvent(event, venueGroup))

    const isEventGroupVisible = (eventGroup) => {
        if (isEasyMatch(eventGroup)) {
            return showObviouslyLinkable
        }

        let sourceCounts = _.countBy(eventGroup, 'source')
        let isObviouslyUnlinkable = !sourceCounts.tm || !sourceCounts.vi
        if (isObviouslyUnlinkable) {
            return showObviouslyUnlinkable
        }

        return showAmbiguouslyLinkable
    }

    const eventsView = _.chain(eventsByTimeAndVenue)
        .entries()
        .map(([k, v]) => [...JSON.parse(k), v])
        .sortBy([0, 1, 2])
        .map(([eventTime, eventVenue, events]) => (
            <EventGroupView
                key={eventTime + eventVenue}
                timestamp={eventTime}
                events={events}
                showVenueName={isBatchMode}
                className={getEventGroupClassName(events)}
                onClickGroup={() =>
                    handleClickTimestampToCreate(eventTime, events)
                }
                onClickEvent={(htmlEvent, eventId) =>
                    handleClickEvent(htmlEvent, eventId)
                }
                activeIds={activeIds}
                isVisible={isEventGroupVisible(events)}
            />
        ))
        .value()

    let eventsViewEventCount = _.chain(eventsByTimeAndVenue)
        .values()
        .filter(isEventGroupVisible)
        .flatten()
        .value().length

    const processedEventsView = _.chain(processedEventsForVenue)
        .sortBy((g) => g.map((e) => e.event_date_utc)[0])
        .filter((g) => g.length > 0)
        .map((g) => (
            <EventGroupView
                key={g.map((e) => e.id).join(',')}
                timestamp={g.map((e) => e.event_date_utc)[0]}
                events={g}
                showVenueName={isBatchMode}
                className={getEventGroupClassName(g)}
                onClickGroup={() => handleClickTimestampToDelete(g)}
                onClickEvent={(htmlEvent, eventId) => {}}
                activeIds={activeIds}
            />
        ))
        .value()

    let processedEventsViewEventsCount = processedEventsForVenue.filter(
        (g) => g.length > 0
    ).length

    const venueMatchesSearchText = (venue) => {
        if (!freeSearchText) {
            return true
        }
        return (getVenueGroupPrettyName(venue.items) || '')
            .toLowerCase()
            .includes(freeSearchText.toLowerCase())
    }

    const buttonSeparatorDistance = 16

    return (
        <div
            style={{ outline: 'none' }}
            tabIndex={0}
            onKeyDown={handleKeyDown}
            className={isBusy ? styles.busy : ''}
        >
            <div className={styles.shortcuts}>
                <div style={{ position: 'relative' }}>
                    <span style={{ opacity: 0.5 }}>Mode</span>
                    <span style={{ display: 'inline-block', width: 6 }}></span>

                    <button
                        className={
                            !isBatchMode
                                ? styles.modeToggleActive
                                : styles.toggleOff
                        }
                        onClick={() => setIsBatchMode(!isBatchMode)}
                        style={{ marginRight: 0 }}
                    >
                        Unit
                    </button>

                    <button
                        className={
                            isBatchMode
                                ? styles.modeToggleActive
                                : styles.toggleOff
                        }
                        onClick={() => setIsBatchMode(!isBatchMode)}
                    >
                        Batch
                    </button>

                    <span
                        style={{
                            display: 'inline-block',
                            width: buttonSeparatorDistance
                        }}
                    ></span>

                    <span style={{ opacity: 0.5 }}>Show</span>
                    <span style={{ display: 'inline-block', width: 6 }}></span>

                    <button
                        onClick={() => {
                            setShowObviouslyLinkable(true)
                            setShowObviouslyUnlinkable(true)
                            setShowAmbiguouslyLinkable(true)
                        }}
                    >
                        All
                    </button>

                    <button
                        onClick={() => {
                            setShowObviouslyLinkable(false)
                            setShowObviouslyUnlinkable(false)
                            setShowAmbiguouslyLinkable(false)
                        }}
                    >
                        None
                    </button>

                    <span
                        style={{
                            display: 'inline-block',
                            width: buttonSeparatorDistance
                        }}
                    ></span>

                    <button
                        onClick={() =>
                            setShowObviouslyLinkable(!showObviouslyLinkable)
                        }
                        className={
                            showObviouslyLinkable
                                ? styles.toggleOn
                                : styles.toggleOff
                        }
                    >
                        Linkable
                    </button>

                    <button
                        onClick={() =>
                            setShowObviouslyUnlinkable(!showObviouslyUnlinkable)
                        }
                        className={
                            showObviouslyUnlinkable
                                ? styles.toggleOn
                                : styles.toggleOff
                        }
                    >
                        Unlinkable
                    </button>
                    <button
                        onClick={() =>
                            setShowAmbiguouslyLinkable(!showAmbiguouslyLinkable)
                        }
                        className={
                            showAmbiguouslyLinkable
                                ? styles.toggleOn
                                : styles.toggleOff
                        }
                    >
                        Others
                    </button>

                    <span
                        style={{
                            display: 'inline-block',
                            width: buttonSeparatorDistance
                        }}
                    ></span>

                    <button onClick={() => handleCompileGroup()}>
                        Compile Group
                    </button>

                    <button onClick={handleClearSelection}>
                        Clear Selection
                    </button>

                    <span
                        style={{
                            display: 'inline-block',
                            width: buttonSeparatorDistance
                        }}
                    ></span>

                    <button onClick={handleAcceptSuggestions}>
                        Accept Suggestions
                    </button>

                    <button onClick={handleDeleteAll}>Unlink All</button>

                    <span
                        style={{
                            display: 'inline-block',
                            width: buttonSeparatorDistance
                        }}
                    ></span>

                    <button onClick={() => setDisplayHelp(!displayHelp)}>
                        Help
                    </button>

                    <span
                        style={{
                            position: 'absolute',
                            right: 40,
                            top: 6,
                            fontSize: 12,
                            fontWeight: 600,
                            color: 'hsl(240, 0%, 45%)'
                        }}
                    >
                        {notificationText}
                    </span>
                </div>
            </div>

            <div className={styles.flexcontainer} style={{ height: '100%' }}>
                <div
                    className={styles.flexchild}
                    style={{ maxHeight: maxHeight, flex: 0.8 }}
                >
                    <div
                        style={{
                            margin: 18,
                            position: 'relative',
                            height: '95%'
                        }}
                    >
                        {displayHelp && (
                            <div className={styles.prose}>
                                <h1>General Help</h1>

                                <p>
                                    The Event Linker allows users to define
                                    Ticketmaster events and Vivid events as
                                    representing the same physical event.
                                </p>
                                <p>
                                    It runs in two possible modes: "unit mode"
                                    and "batch mode"
                                </p>
                                <p>
                                    Unit mode allows users to focus on a
                                    specific venue that has already been
                                    venue-linked, and review the events within
                                    that venue. To get started, click "unit
                                    mode" (which is already enabled when you
                                    first load the page), then click on a venue
                                    in the first column to work within that
                                    venue. To link a pair of events, click on
                                    its name, then click on the name of the
                                    second event, then click "compile group". To
                                    unselect an event, click its name again, or
                                    click "clear selection" to unselect all
                                    events.
                                </p>
                                <p>
                                    Actions are auto-saved into the database, so
                                    give it a second or two to commit your
                                    changes up to the server. You should refresh
                                    the page every once in a while just to make
                                    it reload everything from the database and
                                    confirm that everything is working fine.
                                </p>
                                <p>
                                    The system tries to do some of the work for
                                    you by identifying events that look like
                                    they are identical. Those are called labeled
                                    "Linkable" events. Any events that are
                                    obviously NOT the same as any others are
                                    referred to as "Unlinkable". And finally,
                                    any events that are neither linkable nor
                                    unlinkable, are labeled as "Others.
                                </p>
                                <p>
                                    You can filter the view to show any subset
                                    of linkable/unlinkable/others by clicking on
                                    the green toggles at the top of the screen.
                                    The "show all" and "show none" buttons
                                    enable and disable all the toggles.
                                </p>
                                <p>
                                    Linkable pairs of events can quickly be
                                    accepted by clicking on the top part of the
                                    card (not the event names, but the venue
                                    name). This selects the pair of events and
                                    compiles them as identical.
                                </p>
                                <p>
                                    Linking a pair of events moves it from the
                                    center column to the right column.
                                </p>
                                <p>
                                    To remove a linkage, simply click on the top
                                    of its card on the right column, which will
                                    move the respective events back to the
                                    middle column.
                                </p>
                                <p>
                                    To further study a specific event, hover
                                    your mouse over it, which will present a
                                    link button to the right of its name, which
                                    will send you to its ticketmaster page or
                                    vivid page.
                                </p>
                                <p>
                                    Since most suggested "linkable" events are
                                    usually correct, users may want to quickly
                                    accept all the suggestions with a specific
                                    venue or across all venues. To accept all
                                    suggested links, just click "Accept All".
                                    The reverse of this action is "Unlink all".
                                </p>
                                <p>
                                    When in Unit Mode, the "Accept All" and
                                    "Unlink All" actions apply to "all" the
                                    events within the selected venue.
                                </p>
                                <p>
                                    Batch mode allows you to work on all events
                                    across all venues. Click "Batch mode" to
                                    enable it, and remember that the dataset is
                                    large enough that you should be patient for
                                    the system to apply and save any operations
                                    you perform while in batch node. Give it
                                    5-10 seconds to save before closing out the
                                    tab.
                                </p>
                                <p></p>

                                <div>&nbsp;</div>
                                <button onClick={() => setDisplayHelp(false)}>
                                    Close Help
                                </button>
                            </div>
                        )}

                        {!displayHelp && (
                            <div>
                                {isBatchMode && (
                                    <div>
                                        <div>You're in batch mode</div>
                                        <div style={{ height: 12 }}>&nbsp;</div>

                                        <div>
                                            Please be patient in batch mode
                                            because the amount of data getting
                                            processed can be large. Give each
                                            operation a couple of seconds to
                                            fully complete.
                                        </div>
                                        <div style={{ height: 12 }}>&nbsp;</div>

                                        <div>
                                            You probably want to show only
                                            linkables, review the middle column,
                                            and then accept all suggestions.
                                        </div>
                                        <div style={{ height: 12 }}>&nbsp;</div>

                                        <div>
                                            {/*progress <= 1.0 && ( <span>Loading...</span>)*/}
                                        </div>
                                        <div>
                                            {progress <= 1.0 && (
                                                <ProgressLine
                                                    visualParts={[
                                                        {
                                                            percentage:
                                                                progress * 100 +
                                                                '%',
                                                            color: 'hsl(210, 50%, 50%)'
                                                        }
                                                    ]}
                                                />
                                            )}
                                        </div>
                                    </div>
                                )}

                                {!isBatchMode && (
                                    <div>
                                        <div>
                                            <TextField
                                                value={freeSearchText}
                                                onChange={
                                                    handleFreeSearchTextChange
                                                }
                                                placeholder="Quick Filter"
                                                autoComplete="off"
                                            />
                                        </div>
                                        <p>&nbsp;</p>

                                        <div>
                                            <MultiSelect
                                                multiple="multiple"
                                                options={_.chain(
                                                    allProcessedVenues
                                                )
                                                    .filter(
                                                        (v) =>
                                                            v.items.length > 0
                                                    )
                                                    .filter(
                                                        venueMatchesSearchText
                                                    )
                                                    .sortBy((g) =>
                                                        getVenueGroupPrettyName(
                                                            g.items
                                                        )
                                                    )
                                                    .map((g) => ({
                                                        value: g.id,
                                                        label: getVenueGroupPrettyName(
                                                            g.items
                                                        )
                                                    }))
                                                    .value()}
                                                value={
                                                    [selectedVenueGroup] || []
                                                }
                                                onChange={
                                                    handleSelectedVenueGroupChanged
                                                }
                                                selectStyle={{
                                                    height: maxHeight - 200
                                                }}
                                            />
                                        </div>
                                    </div>
                                )}
                            </div>
                        )}
                    </div>
                </div>
                <div
                    className={`${styles.flexchild} ${styles.unprocessed}`}
                    style={{ maxHeight: maxHeight }}
                >
                    <div
                        style={{
                            marginTop: 12,
                            marginBottom: 6,
                            marginLeft: 4,
                            fontStyle: 'italic',
                            fontWeight: 700,
                            color: 'hsl(190, 30%, 50%)'
                        }}
                    >
                        {eventsViewEventCount > 0 &&
                            'Number of unlinked events: ' +
                                eventsViewEventCount.toLocaleString()}
                    </div>
                    <div>{eventsView}</div>
                </div>

                <div
                    className={`${styles.flexchild} ${styles.processed}`}
                    style={{ outline: 'none', maxHeight: maxHeight }}
                >
                    <div
                        style={{
                            marginTop: 12,
                            marginBottom: 6,
                            marginLeft: 4,
                            fontStyle: 'italic',
                            fontWeight: 700,
                            color: 'hsl(190, 30%, 50%)'
                        }}
                    >
                        {processedEventsViewEventsCount > 0 &&
                            'Number of links: ' +
                                processedEventsViewEventsCount.toLocaleString()}
                    </div>
                    <div>{processedEventsView}</div>
                </div>
            </div>
        </div>
    )
}

export default EventLinker
