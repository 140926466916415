import _ from "lodash";
import { Card, Page } from "@shopify/polaris";
import { useEffect, useState, useRef } from "react";
import { apiGet } from "../../../Api";
import { XAxis, YAxis, BarChart, Bar, Tooltip } from "recharts";
import moment from "moment";

// TODO resolve server
const wsEndpoint = `ws://${window.location.hostname}:7006`;

const LiveTmStatusSection = () => {
  const [workerStatus, setWorkerStatus] = useState();
  const [latestRecords, setLatestRecords] = useState([]);
  const [rawDumpData, setRawDumpData] = useState({});
  const [chart1Data, setchart1Data] = useState({});

  const [socketData, setSocketData] = useState([]);
  const [socketMessages, setSocketMessages] = useState([]);

  const [chart2Data, setchart2Data] = useState([]);

  const latestActivityCount = 20;

  let eventsWithMostMovementSql = (lookback, limit) => `
        select tli.event_id, tme.tm_id, sum(change) as movement, tme.event_url_link
        from tm_live_inventory tli
        join tm_event tme on tli.event_id = tme.id
        where fetched_at > now() - interval ${lookback}
        group by tli.event_id, tme.tm_id, tme.event_url_link
        order by abs(movement) desc 
        limit ${limit};
  `;

  const rawDumpers = {
    "Events with the most seat movement in the past 5 minutes": {
      sql: eventsWithMostMovementSql("5 minute", 10),
    },
    "Events with the most seat movement in the past 1 hour": {
      sql: eventsWithMostMovementSql("1 hour", 20),
    },
    "Events with the most seat movement in the past 1 day": {
      sql: eventsWithMostMovementSql("24 hour", 30),
    },
    "Events with the most seat movement in the past 5 days": {
      sql: eventsWithMostMovementSql("120 hour", 40),
    },
  };

  useEffect(() => {
    let intervalHandle = null;
    let busy = false;
    async function fetchData() {
      async function doUpdate() {
        if (busy) return;
        busy = true;
        let ans = await apiGet("/tmLive/query", {
          sql: `
          
          select * from
          (
          select
          floor(UNIX_TIMESTAMP()/60)  - floor(UNIX_TIMESTAMP(fetched_at)/60) as lookback
          ,substr(convert_tz(time_slice(fetched_at, interval 1 minute), 'GMT', 'America/New_York'), 12) as dt
          ,sum(abs(change)) as movement
          from tm_live_inventory 
          group by lookback, dt
          order by lookback asc
          limit 120
          ) t1
          order by lookback desc

        `,
        });

        setchart1Data(ans);
        busy = false;
      }
      doUpdate();
      intervalHandle = setInterval(doUpdate, 10000);
    }
    fetchData().catch(console.error);
    return () => {
      clearInterval(intervalHandle);
    };
  }, []);

  useEffect(() => {
    let intervalHandle = null;
    let busy = false;
    async function fetchData() {
      async function doUpdate() {
        if (busy) return;
        busy = true;

        for (let [key, dumper] of Object.entries(rawDumpers)) {
          console.log(key);
          console.log(dumper.sql);
          let ans = await apiGet("/tmLive/query", { sql: dumper.sql });
          rawDumpData[key] = ans;
        }

        setRawDumpData(rawDumpData);
        busy = false;
      }

      doUpdate();
      intervalHandle = setInterval(doUpdate, 30000);
    }

    fetchData().catch(console.error);

    return () => {
      clearInterval(intervalHandle);
    };
  }, []);

  const connectWs = () => {
    const protocol = window.location.protocol.includes("https") ? "wss" : "ws";

    const wsUrl =
      window.location.hostname === "localhost"
        ? `${protocol}://${window.location.hostname}:7001`
        : `${protocol}://${window.location.hostname}:${window.location.port}`;
    console.log("connecting to ws url", wsUrl);
    ws.current = new WebSocket(wsUrl);

    ws.current.onclose = function () {
      console.log("ws on close");
      if (dontRestartWs.current) return;
      console.log("ws reconnecting");
      connectWs();
    };

    ws.current.onmessage = function (event) {
      //console.log("onmessage received");
      const data = JSON.parse(event.data);
      //console.log("received", data);
    };

    ws.current.onopen = () => {
      console.log("ws on open. subscribing");
      ws.current.send(JSON.stringify({ type: "subscribe" }));
    };

    return () => {
      console.log("closing ws effect");
      dontRestartWs.current = true;
      ws.current.close();
    };
  };

  const ws = useRef(null);
  const dontRestartWs = useRef(false);

  useEffect(() => connectWs(), []);

  function padEnd(x, n) {
    const yy = x.toString();
    return yy.padEnd(n);
  }

  return (
    <>
      <h1 style={{ margin: "1em 0em" }}>
        Overall seats moved by the minute in the past 2 hours
      </h1>
      <BarChart
        width={1100}
        height={200}
        barCategoryGap={"5%"}
        data={chart1Data}
      >
        <XAxis dataKey="dt" interval={18} /*angle={-15} textAnchor="end"*/ />
        <Bar
          isAnimationActive={false}
          dataKey="movement"
          fill="hsl(220, 40%, 60%)"
        />
        <YAxis />
        <Tooltip cursor={{ fill: "hsla(0, 0%, 50%, 0.06)" }} />
      </BarChart>

      <div>&nbsp;</div>

      <Card title="Basic Queries" sectioned>
        <div>
          {Object.entries(rawDumpData).map(([name, results]) => (
            <div key={name} style={{ margin: "1em 0em" }}>
              <div style={{ fontStyle: "italic", fontWeight: 700 }}>{name}</div>

              <table
                className="tabledump"
                style={{ marginTop: "0.5em", marginBottom: "0.5em" }}
              >
                <thead>
                  <tr key={Math.random()}>
                    {results.length
                      ? Object.keys(results[0]).map((r, i) => (
                          <th key={JSON.stringify(r)}>{r}</th>
                        ))
                      : ""}
                  </tr>
                </thead>

                <tbody>
                  {results.length &&
                    results.map((r, i) => (
                      <tr key={i}>
                        {Object.values(r).map((c, j) => (
                          <td key={JSON.stringify(c)}>{c}</td>
                        ))}
                      </tr>
                    ))}
                </tbody>
              </table>

              <div>&nbsp;</div>
            </div>
          ))}
        </div>

        {Object.keys(rawDumpData).length !== Object.keys(rawDumpers).length ? (
        <span>LOADING</span>
        ) : (
          <span></span>
        )}
      </Card>
    </>
  );
};

const LiveInventoryPage = () => {
  return (
    <Page
      title="Live Inventory Page"
      subtitle="Monitor real-time activity on TicketMaster"
      fullWidth
    >
      <LiveTmStatusSection />
    </Page>
  );
};

export default LiveInventoryPage;
