/** @format */

import { Card, DataTable, Link, Page } from "@shopify/polaris";
import { useState } from "react";
import { useEffect } from "react";
import { useFirebaseContext } from "../../../FirebaseContext";

function capitalize(str) {
	if (str.length == 0) return str;
	return str[0].toUpperCase() + str.substr(1);
}

function titleCase(str) {
	return str.split(" ").map(capitalize).join(" ");
}

const AutomatePage = () => {
	const { expressFunction } = useFirebaseContext();
	const tm_getAutomateList = expressFunction("tm_getAutomateList");

	const [automateList, setAutomateList] = useState([]);

	useEffect(() => {
		if (automateList.length === 0) {
			tm_getAutomateList().then((response) => {
				const { data = [] } = response;
				setAutomateList(data);
			});
		}
	}, []);

	return (
		<Page title="Automate" subtitle="Automate your searches" fullWidth>
			<Card>
				<DataTable
					columnContentTypes={["text", "text", "text"]}
					headings={["Name", "Public", "Type"]}
					rows={automateList.map((item) => {
						return [
							<Link url={`/ticketMaster/search/${item.id}`}>
								{titleCase(item.name)}
							</Link>,
							item.public ? "Yes" : "No",
							capitalize(item.automate_type),
						];
					})}
				/>
			</Card>
		</Page>
	);
};

export default AutomatePage;
