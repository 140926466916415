import axios from "axios";

const devOrigin = "http://127.0.0.1:3000";
const devEndpoint = "http://127.0.0.1:7001/api";
const realEndpoint = `${window.location.origin}/api`;

function resolveEndpoint() {
  return window.location.origin === devOrigin ? devEndpoint : realEndpoint;
}

const endpoint = resolveEndpoint();

// TODO headers
// { headers: { Authorization: `Bearer ${authToken}`, },
export const apiGet = async (path, params) => {
  let ans = await axios.get(`${endpoint}${path}`, { params: params });
  return ans.data
};

export const apiPost = async (path, params) => {
  let ans = await axios.post(`${endpoint}${path}`, params);
  return ans.data
}