/** @format */
import { Card } from '@shopify/polaris'
import React, { useState } from 'react'
import _ from 'lodash'

/**
 * Vivid Seats panel for the event page, downloaded just-in-time by the client
 */
export const JitVividPanel = ({ vividData }) => {
    const vividDataCard = vividData && vividData.id && (
        <Card title="Vivid" sectioned>
            <p>
                <a
                    href={`https://vividseats.com/${vividData.webPath}`}
                    rel="noreferrer"
                    target="_blank"
                >
                    Vivid Link
                </a>
            </p>
            <div>
                <p>Average Price: ${vividData.avgPrice}</p>
                <p>Max Price: ${vividData.maxPrice}</p>
                <p>Median Price: ${vividData.medianPrice}</p>
                <p>Min Price Price: ${vividData.minPrice}</p>
                <p>Ticket Count: {vividData.ticketCount}</p>
                <p>Presale1Date: {vividData.presale1Date}</p>
                <p>utcDate: {vividData.utcDate}</p>
            </div>
        </Card>
    )

    return vividData && vividData.id && vividDataCard
}
