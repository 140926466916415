/** @format */

import { Card, Checkbox } from '@shopify/polaris'
import { useEffect, useRef, useState } from 'react'

import Highcharts from 'highcharts/highstock'
import HighchartsReact from 'highcharts-react-official'
import _ from 'lodash'
import moment from 'moment'

const loPassThreshold = 1_200

const LineChart = ({
    eventData,
    title,
    chartData,
    yAxisLabel,
    uiOptions,
    chartComponentRef,
    isLoPass,
    showLegend = false,
    height = 650
}) => {
    const [seriesData, setSeriesData] = useState([])
    const [isOverflow, setIsOverflow] = useState(false)
    const [muteIsOverflow, setMuteIsOverflow] = useState(false)

    useEffect(() => {
        if (chartData.length > 200) {
            setIsOverflow(true)
        } else {
            setSeriesData(chartData)
        }
    }, [chartData])

    useEffect(() => {
        if (muteIsOverflow) {
            setSeriesData(chartData)
        }
    }, [chartData, muteIsOverflow])

    const options = {
        title: {
            text: title
        },
        tooltip: {
            split: false,
            formatter: function (a) {
                //console.log(this)
                const tzOffset = new Date().getTimezoneOffset()
                const tzOffsetSign = tzOffset > 0 ? '-' : '+'
                const tz = 'GMT' + tzOffsetSign + Math.abs(tzOffset) / 60

                let label = `<strong>${this.series.name}</strong><br>X = ${new Date(this.x).toLocaleString() + ' ' + tz
                    }<br>Y = ${this.y}`
                if (this.point.additionalInfo && this.point.additionalInfo.label) {
                    label += '<br>Details: ' + this.point.additionalInfo.label
                }
                return label
            }
        },
        navigator: {
            enabled: true
        },
        scrollbar: {
            enabled: false
        },
        rangeSelector: {
            buttons: [
                {
                    type: 'month',
                    count: 1,
                    text: '1m'
                },
                {
                    type: 'month',
                    count: 3,
                    text: '3m'
                },
                {
                    type: 'month',
                    count: 6,
                    text: '6m'
                },
                {
                    type: 'all',
                    text: 'All'
                }
            ]
        },
        plotOptions: {
            series: {
                animation: false,
                enableMouseTracking: true,
                states: {
                    hover: {
                        enabled: true,
                        brightness: 0.5
                    },
                    inactive: {
                        opacity: 0.2
                    }
                }
            }
        },
        legend: { enabled: showLegend, maxHeight: 118 },
        chart: {
            height: height,
            animation: false
        },
        xAxis: {
            type: 'datetime',
            ordinal: false
        },
        yAxis: {
            title: {
                text: yAxisLabel
            },
            min: 0,
            max: isLoPass ? loPassThreshold : null,
            gridLineColor: 'hsl(0, 0%, 90%)'
        },
        series: seriesData,
        accessibility: { enabled: false }
    }

    useEffect(() => {
        if (!eventData.header || !chartComponentRef || !chartComponentRef.current || !chartComponentRef.current.chart) {
            return
        }

        const chart = chartComponentRef.current.chart

        let left = chart.xAxis[0].getExtremes().dataMin

        if (eventData.header.public_sale_start_date_utc) {
            left = new Date(eventData.header.public_sale_start_date_utc).getTime()
        }

        if (eventData.header.presale_start_date_utc) {
            left = new Date(eventData.header.presale_start_date_utc).getTime()
        }

        let right = moment(eventData.header.event_date_utc).toDate().getTime()

        function setExtremes() {
            if (chart.xAxis.length) {
                chart.xAxis[0].setExtremes(left, right)
            }
        }

        // Seems like the chart sometimes needs a bit of time to render before we can the extremes
        setExtremes()
        setTimeout(setExtremes, 1000)
        setTimeout(setExtremes, 3000)
    }, [seriesData, eventData, chartComponentRef])


    return (
        (isOverflow && (!muteIsOverflow)) ?
            <Card sectioned>
                <div>{title}: chart data overflow ({chartData.length} time-series)</div>
                <div style={{ marginTop: 8 }}><button onClick={() => { setMuteIsOverflow(true) }}>Render anyway</button></div>
            </Card>
            :
            <Card sectioned>
                <div style={{ position: 'relative', padding: '18px 12px' }}>
                    <div
                        style={{
                            position: 'relative',
                            marginBottom: 8,
                            opacity: 0.88
                        }}
                        className="wtt-chart-label"
                    >
                        {uiOptions}
                    </div>
                    <HighchartsReact
                        ref={chartComponentRef}
                        highcharts={Highcharts}
                        constructorType={'stockChart'}
                        options={options}
                    />
                </div>
            </Card>
    )
}

export default LineChart
