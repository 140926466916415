/** @format */

import { Card, Page } from "@shopify/polaris";
import { useEffect, useState } from "react";
import { useFirebaseContext } from "../../../FirebaseContext";

const InventoryCrawlerStatusSection = () => {
	const { expressFunction } = useFirebaseContext();

	const ticketMasterInventoryCrawlerStatus = expressFunction(
		"ticketMasterInventoryCrawlerStatus"
	);

	const resetFailedEvents = expressFunction("resetFailedEvents");
	const resetAllEventsInventory = expressFunction("resetAllEventsInventory");

	const [crawlerStatus, setCrawlerStatus] = useState({});
	const [blockAllOtherRequests, setBlockAllOtherRequests] = useState(false);

	useEffect(() => {
		console.log("crawlerStatus", crawlerStatus);
		ticketMasterInventoryCrawlerStatus({}).then((response) => {
			const { data } = response;

			setCrawlerStatus(data);
			// console.log("data", data);
		});
	}, []);

	const [resetFailedEventsRequested, setResetFailedEventsRequested] =
		useState(false);
	const performResetFailedEvents = () => {
		setResetFailedEventsRequested(true);
		resetFailedEvents({}).then((response) => {
			window.location.reload();
		});
	};

	return (
		<Card
			title="Inventory Crawler Status"
			sectioned
			actions={[
				{
					content: "Reset Failed Events",
					disabled: blockAllOtherRequests,
					onAction: () => {
						setBlockAllOtherRequests(true);
						performResetFailedEvents();
					},
				},
				{
					content: "Reset All Events Inventory",
					disabled: blockAllOtherRequests,
					onAction: () => {
						setBlockAllOtherRequests(true);
						resetAllEventsInventory({}).then((response) => {
							window.location.reload();
						});
					},
				},
			]}
		>
			{crawlerStatus.data && (
				<>
					<p>
						<b>Number of Events Being Watched</b>:{" "}
						{crawlerStatus.data.numberEventsBeingWatched}
					</p>
					<p>
						<b>Number of Events Waiting</b>:{" "}
						{crawlerStatus.data.numberOfEventsWaitingForINVFetch}
					</p>
					<p>
						<b>Number of Events That Failed</b>:{" "}
						{crawlerStatus.data.numberOfEventsThatFailed}
					</p>
					<hr />
					<p>
						<b>Number of Events Per 15 Minutes</b>:{" "}
						{crawlerStatus.data.numberOfEventsPer15min}
					</p>
					<p>
						<b>Number of Events Per 30 Minutes</b>:{" "}
						{crawlerStatus.data.numberOfEventsPer30min}
					</p>
					<p>
						<b>Number of Events Per 60 Minutes</b>:{" "}
						{crawlerStatus.data.numberOfEventsPer60min}
					</p>
					<sub>*** This is successful events. Not attempts.</sub>
				</>
			)}
		</Card>
	);
};

const CrawlerStatusPage = () => {
	return (
		<Page
			title="Crawler Status Page"
			subtitle="This is the Crawler Status Page"
			fullWidth
		>
			<InventoryCrawlerStatusSection />
		</Page>
	);
};

export default CrawlerStatusPage;
