import './Alerts.css'

import { FormLayout, TextField } from '@shopify/polaris'
import { Select, hslToRgb } from '@shopify/polaris'
import { apiGet, apiPost } from '../../../Api'

import { BiSolidBell } from 'react-icons/bi' //<BiSolidBell />
import { BiSolidBellOff } from 'react-icons/bi' //<BiSolidBellOff />
import { BiSolidBellRing } from 'react-icons/bi' //<BiSolidBellRing />
import CodeMirror from '@uiw/react-codemirror'
import { FaDatabase } from 'react-icons/fa6'
import { FaPlusSquare } from 'react-icons/fa'
import { FaQuestion } from 'react-icons/fa'
import { IoIosLink } from 'react-icons/io'
import { RxCross2 } from 'react-icons/rx'
import _ from 'lodash'
import { autocompletion } from '@codemirror/autocomplete'
import ms from 'ms'
import { useFirebaseContext } from '../../../FirebaseContext'
import yaml from 'js-yaml'

/*
    http://localhost:3000/ticketMaster/event/G5dZZ9RTraewk
*/

function propertiesToArray(obj) {
    const isObject = (val) =>
        val && typeof val === 'object' && !Array.isArray(val)

    const addDelimiter = (a, b) => (a ? `${a}.${b}` : b)

    const paths = (obj = {}, head = '') => {
        return Object.entries(obj).reduce((product, [key, value]) => {
            let fullPath = addDelimiter(head, key)
            return isObject(value)
                ? product.concat(paths(value, fullPath))
                : product.concat(fullPath)
        }, [])
    }

    return paths(obj)
}

const { useEffect, useState, useCallback } = require('react')

const AlertEdit = ({
    id,
    groupId,
    setGroupId,
    expression,
    setExpression,
    onDelete,
    status,
    setStatus,
    user,
    eventID,
    eventContext,
    editMode,
    onActivate,
    onBlur,
    recipient,
    setRecipient
}) => {
    const [expressionInput, setExpressionInput] = useState(expression)
    const [expressionOutput, setExpressioOutput] = useState('')
    const [isOkButtonDisabled, setOkButtonDisabled] = useState(false)

    const noResultMsg = 'none'
    const blue = 'hsl(216, 80%, 50%)'
    const orange = 'hsl(30, 99%, 50%)'
    const green = 'hsl(120, 50%, 50%)'
    const red = 'hsl(0, 99%, 50%)'
    const gray = 'hsl(0, 0%, 70%)'
    const black = 'hsl(0, 0%, 25%)'
    const resultColor =
        expressionOutput === noResultMsg ? 'hsl(0, 99%, 55%)' : blue

    const statusToColor = () => {
        if (status === 'pending') {
            return black
        }

        if (status === 'triggered') {
            return red
        }

        if (status === 'disabled') {
            return gray
        }

        return gray
    }

    //const formulaColor = editMode ? blue : 'hsl(0, 0%, 20%)'
    const formulaColor = editMode ? blue : statusToColor()

    const bell = () => {
        if (status === 'pending') {
            return <BiSolidBell />
        }

        if (status === 'triggered') {
            return <BiSolidBellRing />
        }

        if (status === 'disabled') {
            return <BiSolidBellOff />
        }

        return <BiSolidBellOff />
    }

    const incrementStatus = () => {
        if (status === 'disabled') {
            console.log('setting to pending')
            setStatus('pending')
        } else {
            console.log('setting to disabled')
            setStatus('disabled')
        }
    }

    const coloredBell = () => {
        return (
            <span
                className="clickable"
                onClick={(e) => {
                    e.stopPropagation()
                    incrementStatus()
                }}
                style={{
                    opacity: 0.999,
                    fontSize: 14,
                    top: 2.5,
                    position: 'relative',
                    marginRight: 8,
                    color: statusToColor()
                }}
            >
                {bell()}
            </span>
        )
    }

    const bellLink = () => {
        return groupId ? (
            <IoIosLink
                style={{
                    position: 'relative',
                    right: 3,
                    top: 3,
                    marginRight: 6
                }}
            />
        ) : (
            ''
        )
    }

    // If an update comes in from the database
    useEffect(() => {
        setExpressionInput(expression)
    }, [expression])

    useEffect(() => {
        let canceled = false

        const updateExpressionOutput = async () => {
            let response = await apiPost(
                '/alerts/evaluateExpressionForContext',
                {
                    expression: expressionInput,
                    context: eventContext
                }
            )

            if (response.error) {
                if (!canceled) {
                    setExpressioOutput(noResultMsg)
                    setOkButtonDisabled(true)
                }
            } else {
                let { result } = response
                let resultStr = JSON.stringify(result)
                if (!canceled) {
                    setOkButtonDisabled(false)
                    setExpressioOutput(resultStr)
                }
            }
        }

        updateExpressionOutput()

        return () => {
            canceled = true
        }
        // expressionInput can change locally.... expression can change from the db
    }, [eventContext, expressionInput])

    const readOnlyView = (
        <div
            style={{ marginTop: 0 }}
            onClick={(e) => {
                if (status == 'triggered') {
                    e.stopPropagation()
                    incrementStatus()
                } else {
                    //setEditMode(!editMode)
                    onActivate()
                }
            }}
        >
            <div className="buttons">
                <span
                    onClick={(e) => {
                        e.stopPropagation()
                        onDelete()
                    }}
                    className="button deleteButton"
                >
                    <RxCross2 />
                </span>
            </div>
            <span>
                {coloredBell()}
                {bellLink()}
                <span
                    className="mono clickable"
                    style={{
                        color: formulaColor,
                        fontWeight: 700,
                        fontSize: 12
                    }}
                >
                    {expression || '(empty expression)'}
                </span>
            </span>
        </div>
    )

    function myCompletions(context) {
        let word = context.matchBefore(/.*/)
        if (word.from === word.to && !context.explicit) return null

        const mapToVariables = (type, vz) =>
            vz.map((k) => ({ label: k, type: type }))

        let variablesContextKeys = mapToVariables(
            'variable',
            propertiesToArray(eventContext)
        )

        let functionsContextKeys = mapToVariables('function', [
            'countWherePriceBelow',
            'countWherePriceBelowOrEqual',
            'countWherePriceAbove',
            'countWherePriceAboveOrEqual',
            'countWherePriceBetween',
            'countInZoneWherePriceBelow',
            'countInZoneWherePriceBelowOrEqual',
            'countInZoneWherePriceAbove',
            'countInZoneWherePriceAboveOrEqual',
            'countInZoneWherePriceBetween'
        ])

        let contextKeys = _.concat(variablesContextKeys, functionsContextKeys)

        return {
            from: word.from,
            options: [...contextKeys]
        }
    }

    const editView = (
        <div>
            <div>{readOnlyView}</div>

            <div
                style={{ marginTop: 28, marginBottom: 0 }}
                onKeyUp={(e) => {
                    // TODO: maybe handle "Enter" key, but only when not performing an autocomplete selection? how to distinguish?
                    // if (e.code === 'Enter') { onBlur() }
                }}
            >
                <FormLayout>
                    <CodeMirror
                        onClick={(e) => {
                            e.stopPropagation()
                        }}
                        value={expression}
                        onChange={(value) => {
                            setExpression(value)
                        }}
                        autoFocus={true}
                        basicSetup={{
                            lineNumbers: false,
                            foldGutter: false,
                            autocompletion: true,
                            highlightActiveLine: false
                        }}
                        extensions={[
                            autocompletion({
                                activateOnTyping: true,
                                override: [myCompletions]
                            })
                        ]}
                    />
                </FormLayout>

                <div className="p1">
                    <span
                        style={{
                            opacity: 0.7,
                            position: 'relative',
                            top: 0.2,
                            fontSize: 12
                        }}
                    >
                        Result:{' '}
                    </span>

                    <span style={{ position: 'absolute', right: 0 }}>
                        {groupId ? (
                            <button
                                style={{
                                    padding: '4px 12px',
                                    position: 'relative',
                                    top: -5,
                                    right: -0
                                }}
                                onClick={() => {
                                    setGroupId(null)
                                }}
                            >
                                break out
                            </button>
                        ) : (
                            ''
                        )}

                        <button
                            style={{
                                padding: '4px 12px',
                                position: 'relative',
                                top: -5,
                                right: -0
                            }}
                            disabled={isOkButtonDisabled}
                            onClick={() => {
                                onBlur()
                            }}
                        >
                            {isOkButtonDisabled ? 'invalid expression' : 'ok'}
                        </button>
                    </span>
                    <span
                        style={{
                            color: resultColor,
                            fontWeight: 800,
                            fontSize: 12
                        }}
                        className="mono"
                    >
                        {expressionOutput}
                    </span>
                </div>
                <div>
                    <TextField
                        placeholder="recipient"
                        value={recipient}
                        onChange={setRecipient}
                        style={{ padding: 0 }}
                    />
                </div>
            </div>
        </div>
    )

    const mainView = editMode ? editView : readOnlyView

    return (
        <div key={alert.id} className="alert">
            {mainView}
        </div>
    )
}

//////////////// //////////////// //////////////// //////////////// //////////////// ////////////////

const processIncomingAlert = (alert) => {
    alert.expression = JSON.parse(alert.content).expression
    alert.recipient = JSON.parse(alert.content).recipient
    return alert
}

const processOutgoingAlert = (alert) => {
    alert.content = JSON.stringify({
        expression: alert.expression,
        recipient: alert.recipient
    })
    return alert
}

const Alerts = ({ eventID, zoningStrategy }) => {
    const { user } = useFirebaseContext()

    const [alerts, setAlerts] = useState([])
    const [activeAlertIndex, setActiveAlertIndex] = useState(-1)
    const [eventContext, setEventContext] = useState({})
    const [displayHelp, setDisplayHelp] = useState(false)
    const [displayContext, setDisplayContext] = useState(true)
    const [alertsHistoryRecords, setAlertsHistoryRecords] = useState([])

    useEffect(() => {
        let fetchAlertsHistory = async () => {
            let records = await apiGet('/alerts/getHistory', {
                email: user.email,
                eventID: eventID
            })

            for (let record of records) {
                record.contentObject = JSON.parse(record.content)
                record.expression = record.contentObject.expression
                record.alertType = record.contentObject.alertType
            }

            records = _.reverse(_.sortBy(records, (r) => r.timestamp))

            setAlertsHistoryRecords(records)
        }

        fetchAlertsHistory()
    }, [])

    useEffect(() => {
        let timeoutHandle = null
        let keepAlertsUpdated = async () => {
            // console.log('updating alerts')

            let alertRecords = await apiGet('/alerts/get', {
                email: user.email,
                eventID: eventID
            })

            alertRecords = alertRecords.map(processIncomingAlert)

            setAlerts(alertRecords)
            timeoutHandle = setTimeout(() => {
                keepAlertsUpdated()
            }, ms('60 seconds'))
        }

        keepAlertsUpdated()

        return () => {
            if (timeoutHandle) {
                clearTimeout(timeoutHandle)
            }
        }
    }, [eventID, user.email])

    useEffect(() => {
        let timeoutHandle = null
        let keepContextUpdated = async () => {
            let _eventContext = await apiGet(
                //'/alerts/getEventContextFromCache',
                '/alerts/getEventContext',
                {
                    eventId: eventID
                }
            )
            setEventContext(_eventContext)
            console.log('eventContext', eventContext)
            timeoutHandle = setTimeout(keepContextUpdated, ms('1 min'))
        }

        keepContextUpdated()

        return () => {
            if (timeoutHandle) {
                clearTimeout(timeoutHandle)
            }
        }
    }, [zoningStrategy])

    const handleCreateAlert = () => {
        setAlerts([
            ...alerts,
            {
                content: { expression: '' },
                status: 'pending'
                //  event_id, id, user
            }
        ])
        setActiveAlertIndex(alerts.length)
    }

    const _handleSaveAll = useCallback(
        (alerts, stopEditing = true) => {
            const doSaveAll = async () => {
                let _alerts = alerts.map((a) => {
                    let _a = { ...a }
                    _a = processOutgoingAlert(_a)
                    return _a
                })

                console.log('saving', _alerts)
                console.log(
                    'saving',
                    _alerts.map((a) => a.status)
                )

                let savedAlerts = await apiPost(
                    '/alerts/setAlertsForUserAndEvent',
                    {
                        user: user.email,
                        eventID: eventID,
                        alerts: _alerts
                    }
                )

                savedAlerts = savedAlerts.map(processIncomingAlert)

                console.log('returned saved', savedAlerts)

                if (stopEditing) {
                    setAlerts(savedAlerts)
                    setActiveAlertIndex(-1)
                }
            }

            doSaveAll()
        },
        [alerts, user, eventID]
    )

    const handleSaveAll = useCallback(_.debounce(_handleSaveAll, 500), [
        user,
        eventID
    ])

    const alertViews = alerts.map((alert, i) => {
        alert.id = alert.id || 'unsaved_' + Math.random()
        return (
            <AlertEdit
                key={alert.id}
                id={alert.id}
                groupId={alert.alerts_group_id}
                setGroupId={(gid) => {
                    if (gid === null) {
                        let newAlerts = [...alerts]
                        newAlerts[i].alerts_group_id = gid
                        setAlerts(newAlerts)
                        handleSaveAll(newAlerts, false)
                    }
                }}
                expression={alert.expression}
                setExpression={(value) => {
                    let newAlerts = [...alerts]
                    newAlerts[i].expression = value
                    setAlerts(newAlerts)
                    handleSaveAll(newAlerts, false)
                }}
                status={alert.status}
                setStatus={(value) => {
                    let newAlerts = [...alerts]
                    newAlerts[i].status = value
                    setAlerts(newAlerts)
                    handleSaveAll(newAlerts, true)
                }}
                onDelete={() => {
                    let newAlerts = alerts.filter((a) => a.id !== alert.id)
                    setAlerts(newAlerts)
                    handleSaveAll(newAlerts, true)
                }}
                user={user}
                eventID={eventID}
                eventContext={eventContext}
                editMode={activeAlertIndex === i}
                recipient={alert.recipient}
                setRecipient={(r) => {
                    let newAlerts = [...alerts]
                    newAlerts[i].recipient = r
                    setAlerts(newAlerts)
                    handleSaveAll(newAlerts, false)
                }}
                onActivate={() => {
                    console.log('activating', i)
                    if (activeAlertIndex === i) {
                        setActiveAlertIndex(-1)
                        return
                    }
                    setActiveAlertIndex(i)
                }}
                onBlur={() => {
                    setTimeout(() => {
                        setActiveAlertIndex(-1)
                    }, 100)
                }}
            />
        )
    })

    const codeStyle = {
        fontFamily: 'monospace',
        fontSize: 13,
        fontWeight: 600,
        color: 'hsl(218, 90%, 50%)'
    }

    const help = (
        <div className="prose">
            <h3>Function Reference</h3>
            <p>
                <span className="mono" style={codeStyle}>
                    countWherePriceBelow(threshold)
                </span>
                : returns the number of tickets with a price strictly less than
                the threshold
            </p>
            <p>
                <span className="mono" style={codeStyle}>
                    countWherePriceBelowOrEqual(threshold)
                </span>
                : returns the number of tickets with a price less than or equal
                to the threshold
            </p>
            <p>
                <span className="mono" style={codeStyle}>
                    countWherePriceAbove(threshold)
                </span>
                : returns the number of tickets with a price strictly greater
                than the threshold
            </p>
            <p>
                <span className="mono" style={codeStyle}>
                    countWherePriceAboveOrEqual(threshold)
                </span>
                : returns the number of tickets with a price greater than or
                equal to the threshold
            </p>
            <p>
                <span className="mono" style={codeStyle}>
                    countWherePriceBetween(lowThreshold, highThreshold)
                </span>
                : returns the number of tickets with a price between
                lowThreshold and highThreshold, inclusive
            </p>

            <p>
                <span className="mono" style={codeStyle}>
                    countInZoneWherePriceBelow(zone, threshold)
                </span>
                : returns the number of tickets in the given zone with a price
                strictly less than the threshold
            </p>
            <p>
                <span className="mono" style={codeStyle}>
                    countInZoneWherePriceBelowOrEqual(zone, threshold)
                </span>
                : returns the number of tickets in the given zone with a price
                less than or equal to the threshold
            </p>
            <p>
                <span className="mono" style={codeStyle}>
                    countInZoneWherePriceAbove(zone, threshold)
                </span>
                : returns the number of tickets in the given zone with a price
                strictly greater than the threshold
            </p>
            <p>
                <span className="mono" style={codeStyle}>
                    countInZoneWherePriceAboveOrEqual(zone, threshold)
                </span>
                : returns the number of tickets in the given zone with a price
                greater than or equal to the threshold
            </p>
            <p>
                <span className="mono" style={codeStyle}>
                    countInZoneWherePriceBetween(zone, lowThreshold,
                    highThreshold)
                </span>
                : returns the number of tickets in the given zone with a price
                between lowThreshold and highThreshold, inclusive
            </p>

            <hr style={{ marginBottom: 24, marginTop: 24 }} />

            <h3>General Help</h3>
            <p>Hit the "+" button on the top-right the create an alert.</p>
            <p>
                Type in a expression as a function of variables in the event
                context. When the event variables cause the expression to
                evaluate to true, the alert will be triggered. Click on the bell
                icon to enable or disable the alert. Hit the "x" to the right of
                an alert to get rid of it. Your changes are saved automatically.
            </p>
            <h3>How to compose expressions</h3>
            <p>
                Expressions are written in a simple and intuitive syntax. Create
                an alert and try the following to get a feel for it:
            </p>
            <p>
                <span className="mono" style={codeStyle}>
                    1 + 1{' '}
                </span>
                should evaluate to 2
            </p>
            <p>
                Note that the above expression evaluates to a number, and it's
                helpful to let the system evaluate parts of your expression as
                you're assembling it. If the expression is invalid due to either
                bad syntax or bad data, the result will show "none" in red. Try
                typing gibberish into the expression. This will come in handy
                when you start using structured context data.
            </p>
            <p>
                Ultimately, alert expressions need to return a boolean. For
                example:
            </p>

            <p>
                <span className="mono" style={codeStyle}>
                    1 + 1 &lt; 5{' '}
                </span>
                is true
            </p>

            <p>
                <span className="mono" style={codeStyle}>
                    1 + 1 == 3{' '}
                </span>
                is false
            </p>

            <p>
                <span className="mono" style={codeStyle}>
                    3 in [1, 2, 3]{' '}
                </span>
                is true
            </p>

            <p>
                Expressions can be arbitrarily complex. For example, we can give
                the expressions access to an Excel-like "if" function, and other
                such capabilities:
            </p>
            <p>
                <span className="mono" style={codeStyle}>
                    if(3 &gt; 5, 99, max(3,5,2) in [5,6,7]){' '}
                </span>
                means if 3 is greater than 5, then take the first value,
                otherwise take the second value. max(3, 5, 2) is 5, which is
                indeed in the list [5, 6, 7]. So the whole expression evaluates
                to true.
            </p>
            <p>
                More details what can be done{' '}
                <a href="https://github.com/silentmatt/expr-eval">here</a>
            </p>

            <h3>How to use event data</h3>
            <p>
                Expressions have access to event data provided through a
                "context" object. Enable displaying context by clicking the
                database icon on the top-right, which will show you a
                hierarchical listing of variables you can retrieve, in addition
                to the value they're holding at the moment. For example, try
                entering the following expression:
            </p>
            <p>
                <span className="mono" style={codeStyle}>
                    chart{' '}
                </span>
                returns a sub-branch of the context object containing the
                variables related to the chart.
            </p>
            <p>
                <span className="mono" style={codeStyle}>
                    chart.primary{' '}
                </span>
                returns a number that corresponds to the latest value on the
                primary chart.
            </p>
            <p>
                <span className="mono" style={codeStyle}>
                    live.totalCount &lt;= 200{' '}
                </span>
                is a valid boolean expression that will send an alert when the
                total count hits 200.
            </p>
            <h3>Going Forward</h3>
            <p>
                This framework should be powerful and flexible enough to handle
                future needs for both data and logic.
            </p>
            <p>
                Future data: we can provide any kind of structured data from any
                data source, through a simple and extensible "data providers"
                framework in the backend.
            </p>
            <p>
                Future logic: any arbitrarily complex logic that can't be
                expressed with simple boolean operators can be implemented by
                the tech team as functions and provided for expressions to
                consume. Finally, if the current expression language ultimately
                reveals itself inadequate, it's very easy to swap it out for a
                more powerful language while keeping most of the application
                code intact.
            </p>

            <p>
                <button
                    onClick={() => {
                        setDisplayHelp(false)
                    }}
                >
                    Close Help
                </button>
            </p>
        </div>
    )

    const showDetails = alerts.length > 0

    const alertsHistoryView = alertsHistoryRecords.map((h) => (
        <div key={h.id}>
            {h.triggered_timestamp} {h.expression}
        </div>
    ))

    return (
        <div style={{ marginTop: -8, position: 'relative' }}>
            <span
                className="opa8 hoverable clickable"
                style={{
                    position: 'absolute',
                    display: 'inline-block',
                    color: 'hsl(218, 99%, 45%)',
                    top: -32,
                    right: 4,
                    fontSize: 20
                }}
                onClick={() => handleCreateAlert()}
            >
                <FaPlusSquare />
            </span>

            <span
                className="opa4 hoverable clickable"
                style={{
                    position: 'absolute',
                    display: 'inline-block',
                    top: -30,
                    right: 36,
                    fontSize: 15,
                    opacity: displayContext ? 1.0 : undefined
                }}
                onClick={() => setDisplayContext(!displayContext)}
            >
                <FaDatabase />
            </span>

            <span
                className="opa2 hoverable clickable"
                style={{
                    position: 'absolute',
                    display: 'inline-block',
                    top: -30,
                    right: 62,
                    fontSize: 15,
                    opacity: displayHelp ? 1.0 : undefined
                }}
                onClick={() => setDisplayHelp(!displayHelp)}
            >
                <FaQuestion />
            </span>

            <div>
                {displayHelp ? help : ''}

                {alerts.length === 0 ? (
                    <div style={{ opacity: 0.4, fontStyle: 'italic' }}>
                        There are no alerts for this event
                    </div>
                ) : (
                    <div>
                        <div>{alertViews}</div>
                    </div>
                )}

                {displayContext ? (
                    <div>
                        <div
                            style={{
                                fontWeight: 600,
                                paddingTop: 16,
                                opacity: 0.8
                            }}
                        >
                            Current Context:
                        </div>
                        <div
                            className="contextJson"
                            style={{ maxHeight: 350, overflowY: 'scroll' }}
                        >
                            {/*JSON.stringify(eventContext, null, 2)*/}
                            {yaml.dump(eventContext)}
                        </div>
                    </div>
                ) : (
                    ''
                )}
            </div>
            <div
                style={{
                    fontWeight: '600',
                    marginBottom: '18px'
                }}
            >
                Alerts Trigger Log:
            </div>

            <div
                style={{
                    padding: '12px',
                    fontFamily: 'monospace',
                    fontSize: 12,
                    border: '1px solid hsl(0, 0%, 80%)',
                    borderRadius: '4px',
                    backgroundColor: 'hsl(214, 65%, 96%)',
                    fontWeight: 500,
                    lineHeight: '2.0em',
                    color: 'hsl(218, 0%, 30%)',
                    maxHeight: 250,
                    overflowY: 'scroll'
                }}
            >
                {alertsHistoryView}
            </div>
        </div>
    )
}

export default Alerts
