import moment from 'moment'

/* Sums the two time-series by timestamp.
 * The arrays are [[timestamp, value], ...]
 **/
export const consolidateArrays = (array1, array2) => {
    const consolidatedObj = {}

    const sumValues = (array) => {
        array.forEach(([timestamp, value]) => {
            consolidatedObj[timestamp] =
                (consolidatedObj[timestamp] || 0) + value
        })
    }

    sumValues(array1)
    sumValues(array2)

    const consolidatedArray = []
    Object.keys(consolidatedObj).forEach((timestamp) => {
        consolidatedArray.push([
            parseInt(timestamp),
            consolidatedObj[timestamp]
        ])
    })

    return consolidatedArray
}

export const dateOf = (x) => {
    return moment(x).startOf('day').format()
}

