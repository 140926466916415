/** @format */

import { Page, Card, IndexTable } from "@shopify/polaris";
import { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { useFirebaseContext } from "../../../FirebaseContext";
import moment from "moment";
import InventoryTable from "./InventoryTable";

const BulkBuyer = () => {
	// get the event id from the route params
	const { eventID } = useParams();
	const [eventData, setData] = useState();
	const [accountBalance, setAccountBalance] = useState(0);
	const { expressFunction } = useFirebaseContext();
	const api_tools_te = expressFunction("api_tools_te");

	useEffect(() => {
		if (eventID !== undefined) {
			api_tools_te({ action: "loadEvent", eventID: eventID })
				.then((response) => {
					// console.log(response.data)
					return response.data;
				})
				.then((response) => {
					// console.log(response.inventoryDetails)
					response.inventoryDetails.ticket_groups =
						response.inventoryDetails.ticket_groups.sort(
							(a, b) => a.wholesale_price - b.wholesale_price
						);
					response.inventoryDetails.ticket_groups =
						response.inventoryDetails.ticket_groups.map((row) => {
							return { ...row, wtta_addToCart: false };
						});
					setAccountBalance(
						parseFloat(response.accountBalance.accounts[0].balance)
					);
					setData(response);
					console.log(response);
				});
		}
	}, []);

	const host = window.location.origin;

	const urlParamBase64 = "${btoa(window.location.toString())}";
	const bookmarklet = `javascript:window.open(\`${host}/inLink?url=${urlParamBase64}\`,'_blank')`;

	if (eventID === undefined) {
		return (
			<Page title="Bulk Buyer" fullWidth>
				<p>
					Please use the following bookmarklet to use this tool.
					<br />
					<br />
					<a
						href={bookmarklet}
						onClick={(e) => {
							e.preventDefault();
						}}
						title={"Drag this link to your bookmarks bar to use this tool."}
					>
						Maki Tool
					</a>
				</p>
			</Page>
		);
	}

	if (eventData === undefined) {
		return (
			<Page title="Bulk Buyer" fullWidth>
				<p>Loading...</p>
			</Page>
		);
	}

	return (
		<Page
			title={`Bulk Buyer`}
			subtitle={`Account Balance: $${accountBalance.toLocaleString("en-US")}`}
			fullWidth
		>
			<Card title="Event Details"></Card>
			<InventoryTable inventory={eventData.inventoryDetails} />
		</Page>
	);
};

export default BulkBuyer;
