/** @format */

import Highcharts from 'highcharts/highstock'
import moment from 'moment'

import HighchartsReact from 'highcharts-react-official'
import { useEffect, useState, useRef } from 'react'
import { Card, Checkbox } from '@shopify/polaris'

const ColumnChart = ({
    eventData,
    title,
    chartData,
    yAxisLabel,
    xAxisLabel
}) => {
    const [seriesData, setSeriesData] = useState([])
    const chartComponentRef = useRef(null)

    useEffect(() => {
        setSeriesData(chartData)
    }, [chartData])

    const options = {
        plotOptions: {
            column: {
                centerInCategory: true,
                borderRadius: 2,
                pointPadding: 0,
                borderWidth: 2,
                groupPadding: 0,
                shadow: false,
                stacking: 'normal',
                animation: {
                    duration: 110
                }
            }
        },
        title: {
            text: title
        },
        navigator: {
            enabled: false
        },
        scrollbar: {
            enabled: false
        },

        chart: {
            type: 'column',
            height: 450,
            animation: {
                duration: 110
            }
        },
        legend: { enabled: seriesData.length > 1 },
        xAxis: {
            title: {
                text: xAxisLabel
            }
        },
        yAxis: {
            title: {
                text: yAxisLabel
            },
            min: 0,
            gridLineColor: 'hsl(0, 0%, 90%)'
        },
        series: seriesData,
        accessibility: { enabled: false }
    }

    return (
        <Card sectioned>
            <div style={{ position: 'relative', padding: '18px 12px' }}>
                <div
                    style={{
                        position: 'relative',
                        marginBottom: 8,
                        opacity: 0.88
                    }}
                    className="wtt-chart-label"
                ></div>
                <HighchartsReact
                    ref={chartComponentRef}
                    highcharts={Highcharts}
                    options={options}
                />
            </div>
        </Card>
    )
}

export default ColumnChart
