import _ from 'lodash'
import { consolidateArrays } from '../EventPage/utils'
import moment from 'moment'

export function processOgChartData(inventoryData, sectionGroupMapping) {
    const _ogChartData = {}

    const primaryStandard = {}
    const primaryPlatinum = {}
    const primaryConsolidatedBySection = {}

    const resaleStandard = {}
    const resalePlatinum = {}
    const resaleConsolidatedBySection = {}

    //console.log('debug inventory_data', inventory_data)

    inventoryData.forEach((inventory) => {
        const {
            fetched_at,
            is_official_platinum,
            inventory_types,
            section
        } = inventory
        const count = parseInt(inventory.count)

        let sectionGroup = sectionGroupMapping[section] ?? '_'

        if (inventory_types === 'primary') {
            if (primaryConsolidatedBySection[sectionGroup] === undefined) {
                primaryConsolidatedBySection[sectionGroup] = {}
            }
            primaryConsolidatedBySection[sectionGroup][fetched_at] =
                (primaryConsolidatedBySection[sectionGroup][fetched_at] ||
                    0) + count

            if (is_official_platinum) {
                primaryPlatinum[fetched_at] =
                    (primaryPlatinum[fetched_at] || 0) + count
            } else {
                primaryStandard[fetched_at] =
                    (primaryStandard[fetched_at] || 0) + count
            }
        } else if (inventory_types === 'resale') {
            if (resaleConsolidatedBySection[sectionGroup] === undefined) {
                resaleConsolidatedBySection[sectionGroup] = {}
            }

            resaleConsolidatedBySection[sectionGroup][fetched_at] =
                (resaleConsolidatedBySection[sectionGroup][fetched_at] ||
                    0) + count

            if (is_official_platinum) {
                resalePlatinum[fetched_at] =
                    (resalePlatinum[fetched_at] || 0) + count
            } else {
                resaleStandard[fetched_at] =
                    (resaleStandard[fetched_at] || 0) + count
            }
        }
    })

    const convertToArray = (value) => {
        const newArray = []
        Object.keys(value).forEach((key) => {
            newArray.push([moment(key).valueOf(), value[key]])
        })
        return newArray
    }

    _ogChartData.primary = {
        standard: convertToArray(primaryStandard),
        platinum: convertToArray(primaryPlatinum),
        bySection: _.mapValues(primaryConsolidatedBySection, convertToArray)
    }
    _ogChartData.primary.consolidated = consolidateArrays(
        _ogChartData.primary.standard,
        _ogChartData.primary.platinum
    ).sort((a, b) => a[0] - b[0])

    _ogChartData.resale = {
        standard: convertToArray(resaleStandard),
        platinum: convertToArray(resalePlatinum),
        bySection: _.mapValues(resaleConsolidatedBySection, convertToArray)
    }

    _ogChartData.resale.consolidated = consolidateArrays(
        _ogChartData.resale.standard,
        _ogChartData.resale.platinum
    ).sort((a, b) => a[0] - b[0])

    return _ogChartData
}