import { apiGet, apiPost } from '../../Api'
import { useFirebaseContext } from '../../FirebaseContext'
import { useState, useEffect, useMemo, useCallback } from 'react'
import { AgGridReact } from 'ag-grid-react'
import moment from 'moment'
import { Icon } from '@shopify/polaris'
import { ExternalMinor } from '@shopify/polaris-icons'
import { Button, Frame, Modal, TextContainer } from '@shopify/polaris'
import { TextField } from '@shopify/polaris'

import 'ag-grid-community/styles/ag-grid.css'
import 'ag-grid-community/styles/ag-theme-alpine.css'
import './ag-grid-override-post.css'

const EventsGrid = ({setSelectedEvent}) => {
    const [isLoading, setIsLoading] = useState(true)
    const [events, setEvents] = useState([])

    useEffect(() => {
        const doFetch = async () => {
            let results = await apiGet('/event/getEventsDump')
            setEvents(results.tm_events)
            setIsLoading(false)
        }

        doFetch()
    }, [])

    const defaultColDef = useMemo(() => {
        return {
            editable: true,
            sortable: true,
            filter: true,
            resizable: true
        }
    }, [])

    const [columnDefs, setColumnDefs] = useState([
        { field: 'id' },
        { field: 'name' },
        { field: 'event_date_utc' },
        { field: 'event_url_link' },
        { field: 'venue_id' },
        { field: 'tm_id' },
        { field: 'seo_name' },
        { field: 'genre_id' },
        { field: 'created_at' },
        { field: 'updated_at' },
        { field: 'tm_segment_id' },
    ])

    /*
    var gridOptions = {
        onGridSizeChanged: () => {
            gridOptions.api.sizeColumnsToFit()
        }
    }
    */

    const [selectedEventIds, setSelectedEventIds] = useState([])

    const { user } = useFirebaseContext()

    return (
        <div style={{ height: '100%' }}>
            { isLoading ? <div style={{fontSize: 14, marginTop: 50, textAlign: 'center'}}>Loading...</div> :
            <div
                style={{ marginTop: 0, height: '100%', width: '100%' }}
                className="ag-theme-alpine-dark"
            >
                <AgGridReact
                    rowData={events}
                    columnDefs={columnDefs}
                    defaultColDef={defaultColDef}
                    rowSelection="single"
                    onSelectionChanged={(e) => {
                        const rows = e.api.getSelectedRows()
                        if (rows.length === 1) {
                            setSelectedEvent(rows[0])
                        } else {
                            setSelectedEvent(null)
                        }
                    }}
                ></AgGridReact>
            </div> }
        </div>
    )
}

export default EventsGrid
